import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import actions from "../actions";


class InfoContainer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="info-page">
        <div className="info-container">
          <h4 
            style={{marginTop: "30px", textAlign: "center", fontWeight: "bold", fontSize: "24px"}}
          >
            Contactless Order & Pay
          </h4>
          <p>Barpay's web based QR code order and payments makes for a seamless guest experience. Customers simply scan a QR code, order, and pay right from their phone without ever needing to download an app. Perfect for bars, restaurants, hotels, coffee shops and more!</p>
          <a 
            href={"https://www.barpay.com"}
            target={"_blank"}
          >
            <button className="btn btn-primary info-btn">
              More Info <i className="fa fa-external-link"></i>
            </button>
          </a>
        </div>
      </div>
    )
  }
}

export default connect(
  function (state) {
    return {
      digitalMenus: state.digitalMenus
    };
  },
  function (dispatch) {
    return {
      digitalMenuActions: bindActionCreators(actions.digitalMenus, dispatch)
    };
  }
)(InfoContainer);