import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MenuScheduleModal from "./MenuScheduleModal";
import ItemReorderModal from "./ItemReorderModal";
import ItemEditor from "./ItemEditor";
import actions from "../actions";

class MenuEditingHeaderMenu extends Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
    this.onReorderClick = this.onReorderClick.bind(this);
    this.onItemEditClick = this.onItemEditClick.bind(this);
  }

  onClick(event) {
    let { menuActions } = this.props;
    event.stopPropagation();
    menuActions.toggleScheduleModal();
  }

  onReorderClick(event) {
    let { menuActions } = this.props;
    event.stopPropagation();
    menuActions.toggleReorderModal();
  }

  onItemEditClick(event) {
    let { menuActions } = this.props;
    event.stopPropagation();
    menuActions.toggleItemEditingModal();
  }

  render() {
    return (
      <div className="menu-editing-header">
        <span>Menu</span>
        <a onClick={this.onClick}>Schedule Settings</a>
        <MenuScheduleModal />
        <a onClick={this.onReorderClick}>Reorder Menu Items</a>
        <ItemReorderModal />
        <button onClick={this.onItemEditClick} className="menu-button">
          + New Item
        </button>
        <ItemEditor />
      </div>
    );
  }
}
export default connect(
  function (state) {
    return { menus: state.menus };
  },
  function (dispatch) {
    return {
      menuActions: bindActionCreators(actions.menus, dispatch),
    };
  }
)(MenuEditingHeaderMenu);
