import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import actions from "../actions";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "30%",
    right: "auto",
    bottom: "auto",
    border: "none",
    borderRadius: "10px",
    transform: "translate(-30%, -20%)",
  },
  overlay: {
    backgroundColor: "rgba(50, 62, 72, 0.5)",
  },
};

class BasicProductModal extends Component {
  constructor(props) {
    super(props);
    this.confirmBasic = this.confirmBasic.bind(this);
  }

  confirmBasic() {
    let { digitalMenuActions, digitalMenus, product, isMonthly, update, updateAction, venueName } = this.props;

    if (update) {
      let { data } = digitalMenus;
      let name = venueName ? venueName : ""
      const updateData = {
        venueId: data.venue_data.id,
        useDefault: false, 
        payment: null,
        updateAction: updateAction,
        product: 1,
        monthly: isMonthly,
        venueName: name
      }

      digitalMenuActions.updateProductPaymentComplete(updateData,
        function (venueId) {
          window.location.assign(`${window.location.origin}/venues/${venueId}/digital_menus`)
        }.bind(this)
      );
    } else {
      digitalMenuActions.productPaymentComplete(product, isMonthly, undefined,  
        function (venueId) {
          window.location.assign(`${window.location.origin}/venues/${venueId}/digital_menus`)
        }.bind(this)
      );
    }

  }


  render() {
    let { isModalOpen, modalToggle, update } = this.props;
    return (
      <Modal
        isOpen={isModalOpen}
        onRequestClose={modalToggle}
        style={customStyles}
        contentLabel="Product Checkout Modal"
        ariaHideApp={false}
        shouldCloseOnOverlayClick={false}
      >
        <div className="schedule-modal">
          <div 
            className="schedule-modal-header"
            style={{display: "flex", justifyContent: "space-between" }}
          >
            <h3 style={{display: "inline-block"}}>Confirm Product</h3>
            <button 
              className="schedule-close-button" 
              onClick={modalToggle}
              style={{display: "flex", position: "static"}}
            >
              <h3 style={{display: "inline-block"}}>X</h3>
            </button>
          </div>
          <div className="row">
            <div className="column">
              <h4 style={{margin: "100px 0 100px 0", textAlign: "center", fontSize: "24px"}}>Basic plan includes ads. Upgrade to Standard or Premium from the dashboard at any time to remove ads.</h4>
              { update && (
                <p style={{ textAlign: "center", fontSize: "12px" }}>Current plan will be active until next billing cycle. You will not be charged on the next billing cycle.</p>
              )}
              <div className="schedule-modal-save">
                <button onClick={this.confirmBasic}>Confirm Basic Plan</button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
};


export default connect (
  function (state) {
    return {
      digitalMenus: state.digitalMenus,
    };
  },
  function (dispatch) {
    return {
      digitalMenuActions: bindActionCreators(actions.digitalMenus, dispatch)
    };
  }
)(BasicProductModal);