import activityActions from "./activities";

const enableVenueDigitalMenu = function (data) {
  return function (dispatch) {
    fetch("digital_menus/add_digital_menu_service.json", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((result) => {
      if (result.status >= 400) {
        console.log("Error: ", result)
      } else {
        dispatch(loadDigitalMenus());
      }
    }).catch((error) => {
      console.error("Error: ", error);
    })
  }
}

const loadDigitalMenus = function (background = false) {
  return function (dispatch) {
    if (!background) dispatch({ type: "DIGITAL_MENUS_LOADING" });
  
    fetch("digital_menus/get_venue_digital_menus.json", { method: "GET" })
      .then((response) => response.json())
      .then(function (data) {
        dispatch({ type: "DIGITAL_MENUS_LOADED", data: data });
      })
      .catch(function () {
        dispatch({ type: "DIGITAL_MENUS_FAILED" });
      });
  }
}

const toggleDigitalMenuModal = function () {
  return { type: 'TOGGLE_DIGITAL_MENU_MODAL' };
};

const togglePaymentModal = function (planSelectOpen) {
  let check = planSelectOpen ? planSelectOpen : false;
  return { type: 'TOGGLE_PAYMENT_MODAL', data: check };
};

const togglePromptUpgradeModal = function (upgradeType) {
  return { type: 'TOGGLE_PROMPT_UPGRADE_MODAL', upgradeType: upgradeType };
};

const setCurrentMenu = function (menu) {
  return { type: 'SET_CURRENT_MENU', currentMenu: menu };
};

const clearCurrentMenu = function () {
  return { type: 'CLEAR_CURRENT_MENU' };
};

const updateDigitalMenu  = function (data, callback) {
  return function (dispatch, getState) {
    let method = "";
    let venue = data.venueId
    let url = ""

    if (data.currentMenu.id) {
      method = "PUT";
      url = `/venues/${venue}/digital_menus/update_menu.json`
    } else {
      method = "POST";
      url = `/venues/${venue}/digital_menus/add_menu.json`
    }

    fetch(url, {
      method: method,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((result) => {
      if (result.status >= 400) {
        dispatch({
          type: "DIGITAL_MENU_UPDATE_ERROR",
          data: "There was an error contacting the server",
        });
      } else {
        dispatch({ type: "DIGITAL_MENU_UPDATED" });
        callback();
        dispatch(loadDigitalMenus());
      }
    }).catch((error) => {
      console.error("Error:", error);
    })
  }
};

const deleteDigitalMenu  = function (menu) {
  return function (dispatch, getState) {
    const url = `/venues/${getState().menus.venue}/digital_menus/delete_menu.json`

    fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(menu),
    }).then((result) => {
      if (result.status > 400) {
        dispatch({
          type: "DIGITAL_MENU_DELETE_ERROR",
          data: "There was an error contacting the server",
        });
      } else {
        dispatch({ type: "DIGITAL_MENU_DELETED" });
        dispatch(loadDigitalMenus());
      }
    }).catch((error) => {
      console.error("Error:", error);
    })
  }
};

const changeOrder = function (venueId, id, direction) {
  return function (dispatch) {
    const url = `/venues/${venueId}/digital_menus/update_menu_order.json`
    const data = {id: id, direction: direction}
    fetch(url, { 
      method: "PUT", 
      body: JSON.stringify(data) 
    }).then((result) => {
      if (result.status >= 400) {
        dispatch({
          type: "DIGITAL_MENU_UPDATE_ERROR",
          data: "There was an error contacting the server",
        });
      } else {
        return result.json()
      }
    }).then((data) => {
      dispatch({
        type: "UPDATE_DIGITAL_MENUS_ONLY",
        data: data,
      });
    }).catch((error) => {
      console.error("Error:", error);
    })
  }
}

const resetMenuOrder = function (venueId) {
  return function (dispatch) {
    const url = `/venues/${venueId}/digital_menus/reset_menu_order.json`
    fetch(url, { 
      method: "PUT"
    }).then((result) => {
      if (result.status >= 400) {
        dispatch({
          type: "DIGITAL_MENU_UPDATE_ERROR",
          data: "There was an error contacting the server",
        });
      } else {
        return result.json()
      }
    }).then((data) => {
      dispatch({
        type: "UPDATE_DIGITAL_MENUS_ONLY",
        data: data,
      });
    }).catch((error) => {
      console.error("Error:", error);
    })
  }
}

const generatePdfs = function (venueId, callback) {
  const url = `/venues/${venueId}/digital_menus/generate_print_ready_pdf.json`
  return function (dispatch) {
    fetch(url, { 
      method: "PUT"
    }).then((result) => {
      if (result.status >= 400) {
        console.log("Error: ", result)
        dispatch({ 
          type: "SET_PDF_ERROR", 
          data: "There was an error creating your PDFs. Please make sure you can see both your QR Code and your venue logo on the current page before trying to generate files again. If the problem persists, please contact Barpay support." 
        });
      } else {
        dispatch({ type: "SET_PDF_ERROR", data: "" });
        callback()
      }
    }).catch((error) => {
      console.error("Error:", error);
      dispatch({ 
        type: "SET_PDF_ERROR", 
        data: "There was an error creating your PDFs. Please make sure you can see both your QR Code and your venue logo on the current page before trying to generate files again. If the problem persists, please contact Barpay support." 
      });
    })
  }
}

const pollPdfs = function () {
  return async function () {
    const response = await fetch("digital_menus/print_ready_pdf_poll.json", { method: "GET" })
    const data = await response.json()

    if (data.status) {
      return true
    } else {
      return false
    }
  }
}

const generateQr = function (venueId, callback) {
  const url = `/venues/${venueId}/digital_menus/generate_qr_code.json`
  return function () {
    fetch(url, { 
      method: "PUT"
    }).then((result) => {
      if (result.status >= 400) {
        console.log("Error: ", result)
        callback()
      } else {
        callback()
      }
    }).catch((error) => {
      console.error("Error:", error);
      callback()
    })
  }
}

const pollQr = function () {
  return async function () {
    const response = await fetch("digital_menus/qr_code_poll.json", { method: "GET" })
    const data = await response.json()
    if (data.status) {
      return true
    } else {
      return false
    }
  }
}

const pdfPaymentSetup = function(venueId) {
  const url = `/venues/${venueId}/digital_menus/setup_pdf_checkout.json`
  return function (dispatch, callback) {
    fetch(url, { method: "GET" })
    .then((response) => response.json())
    .then(function (data) {
      dispatch({ type: "PAYMENT_SETUP", data: data });
    })
    .catch((error) => {
      console.error("Error:", error);
    })
  }
}

const pdfPaymentComplete = function (venueId, useDefault, payment, callback) {
  const url = `/venues/${venueId}/digital_menus/complete_pdf_checkout.json`
  return function (dispatch) {
    fetch(url, { 
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ default: useDefault, pi: payment })
    }).then((result) => {
      if (result.status >= 400) {
        console.log("Error: ", result)
      } else {
        dispatch(loadDigitalMenus())
        callback()
      }
    }).catch((error) => {
      console.error("Error:", error);
    })
  }
}

const enterprisePaymentSetup = function(venueId) {
  const url = `/venues/${venueId}/digital_menus/setup_enterprise_checkout.json`
  return function (dispatch) {
    fetch(url, { method: "GET" })
    .then((response) => response.json())
    .then(function (data) {
      dispatch({ type: "PAYMENT_SETUP", data: data });
    })
    .catch((error) => {
      console.error("Error:", error);
    })
  }
}

const enterprisePaymentComplete = function (venueId, useDefault, payment, callback) {
  const url = `/venues/${venueId}/digital_menus/complete_enterprise_checkout.json`
  return function (dispatch) {
    fetch(url, { 
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ default: useDefault, si: payment })
    }).then((result) => {
      if (result.status >= 400) {
        console.log("Error: ", result)
      } else {
        dispatch(loadDigitalMenus())
        callback()
      }
    }).catch((error) => {
      console.error("Error:", error);
    })
  }
}

const clearPaymentSetup = function () {
  return { type: 'CLEAR_PAYMENT_SETUP' };
};

const addDigitalMenuVenue  = function (data, callback) {
  return function (dispatch, getState) {
    let url = `/digital_menus/add_digital_menu_venue.json`
    fetch(url, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
    .then((response) => response.json())
    .then(function (data) {
      callback(data.id)
    })
    .catch((error) => {
      console.error("Error:", error);
    })
  }
};

const setCurrentProduct = function (product) {
  return { type: 'SET_CURRENT_PRODUCT', currentProduct: product };
};

const productPaymentSetup = function(product, monthly) {
  const url = `/digital_menus/setup_product_checkout.json`
  return function (dispatch) {
    fetch(url, { 
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        product: product,
        monthly: monthly
      }),
    })
    .then((response) => response.json())
    .then(function (data) {
      dispatch({ type: "PAYMENT_SETUP", data: data["si"], key: data["stripe_public_key"]  });
    })
    .catch((error) => {
      console.error("Error:", error);
    })
  }
}

const productPaymentComplete = function (product, isMonthly, payment, callback) {
  const url = `/digital_menus/complete_product_checkout.json`
  return function (dispatch) {
    fetch(url, { 
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ product: product, monthly: isMonthly, si: payment })
    })
    .then((response) => response.json())
    .then(function (data) {
      dispatch({ type: "SET_VENUE_ID", venueId: data["id"] });
      callback(data["id"])
    })
    .catch((error) => {
      console.error("Error:", error);
    })
  }
}

const updatePaymentSetup = function(venueId) {
  const url = `/venues/${venueId}/digital_menus/setup_update_checkout.json`
  return function (dispatch) {
    fetch(url, { method: "GET" })
    .then((response) => response.json())
    .then(function (data) {
      dispatch({ type: "PAYMENT_SETUP", data: data });
    })
    .catch((error) => {
      console.error("Error:", error);
    })
  }
}

const updateProductPaymentSetup = function(venueId, product, monthly, addVenue) {
  const url = `/venues/${venueId}/digital_menus/setup_update_product_checkout.json`
  return function (dispatch) {
    fetch(url, { 
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        product: product,
        monthly: monthly,
        addVenue: addVenue
      }),
    })
    .then((response) => response.json())
    .then(function (data) {
      dispatch({ type: "PAYMENT_SETUP", data: data });
    })
    .catch((error) => {
      console.error("Error:", error);
    })
  }
}

const updateProductPaymentComplete = function (data, callback) {
  const { venueId } = data;
  const url = `/venues/${venueId}/digital_menus/complete_update_product_checkout.json`
  return function (dispatch) {
    fetch(url, { 
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data })
    })
    .then((response) => response.json())
    .then(function (data) {
      callback(data.id);
    })
    .catch((error) => {
      console.error("Error:", error);
    })
  }
}

const updatePaymentComplete = function (venueId, payment, callback) {
  const url = `/venues/${venueId}/digital_menus/complete_update_checkout.json`
  return function () {
    fetch(url, { 
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ setup: payment })
    }).then((result) => {
      if (result.status >= 400) {
        console.log("Error: ", result)
      } else {
        callback()
      }
    }).catch((error) => {
      console.error("Error:", error);
    })
  }
}

const getStripeInfo = function () {
  return function (dispatch) {
    fetch("digital_menus/get_stripe_info.json", { method: "GET" })
      .then((response) => response.json())
      .then(function (data) {
        dispatch({ type: "STRIPE_INFO_LOADED", data: data });
      })
      .catch((error) => {
        console.error("Error:", error);
      })
  }
}

const clearStripeInfo = function () {
  return { type: 'CLEAR_STRIPE_INFO' };
};

const getCustomerEmails = function (callback) {
  return function (dispatch) {
    dispatch({ type: "CLEAR_CUSTOMER_EMAILS" });
    fetch("digital_menus/get_customer_emails.json", { method: "PUT" })
    .then((result) => {
      if (result.status >= 400) {
        console.log("Error: ", result)
      } else {
        callback()
      }
    }).catch((error) => {
      console.error("Error:", error);
    })
  }
}

const pollCustomerEmails = function () {
  return async function (dispatch) {
    const response = await fetch("digital_menus/customer_emails_poll.json", { method: "GET" })
    const data = await response.json()

    if (data.status) {
      dispatch({ type: "CUSTOMER_EMAILS_LOADED", url: data.url });
      return true
    } else {
      return false
    }
  }
}

export default {
  enableVenueDigitalMenu,
  loadDigitalMenus,
  toggleDigitalMenuModal,
  setCurrentMenu,
  clearCurrentMenu,
  updateDigitalMenu,
  deleteDigitalMenu,
  changeOrder,
  resetMenuOrder,
  generatePdfs,
  generateQr,
  pdfPaymentSetup,
  pdfPaymentComplete,
  clearPaymentSetup,
  addDigitalMenuVenue,
  setCurrentProduct,
  productPaymentSetup,
  productPaymentComplete,
  enterprisePaymentSetup,
  enterprisePaymentComplete,
  updatePaymentSetup,
  updatePaymentComplete,
  getStripeInfo,
  clearStripeInfo,
  updateProductPaymentSetup,
  updateProductPaymentComplete,
  togglePaymentModal,
  togglePromptUpgradeModal,
  getCustomerEmails,
  pollCustomerEmails,
  pollPdfs,
  pollQr
};