import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import actions from "../actions";
import QrImage from "./QrImage"
import DigitalMenusContainer from "./DigitalMenusContainer";
import Loader from "../menus/Loader";
import VenueInfo from "./VenueInfo";
import DisplayPdfContainer from "./DisplayPdfContainer";
import VenueActionsContainer from "./VenueActionsContainer";

class VenueDigitalMenusContainer extends Component {
  constructor(props) {
    super(props);
    this.enableService = this.enableService.bind(this);
  }

  enableService() {
    let { digitalMenuActions } = this.props;
    digitalMenuActions.enableVenueDigitalMenu();
  }

  render() {
    let { digitalMenus } = this.props;
    let product = 1
    let digitalMenusContent = <div>You have no menus</div>;
    let venueDigitalMenusContent = <div></div>;

    if (digitalMenus && digitalMenus.data) {
      if(digitalMenus.data["dm_activate"]) {
        this.enableService()
      }
      product = digitalMenus.data.product
    }
    
    if (this.props.digitalMenus.loading) {
      digitalMenusContent = <div style={{ marginLeft: '50%', marginTop: '25%' }}> <Loader /></div>
    } else {
      const venueData = this.props.digitalMenus.data
      if (venueData !== undefined && venueData.venue_dm) {
        venueDigitalMenusContent  = (
          <div className="dm-venue-container">
            <QrImage url={venueData.venue_dm.qr_code.url} /> 
            <VenueInfo venue={venueData.venue_data} url={venueData.url} vdm={venueData.venue_dm} />
            <VenueActionsContainer />
          </div>
        )
      } else {
        venueDigitalMenusContent  = (
          <div className="dm-venue-container">
            <button className="btn btn-primary dm-add-service-button" onClick={this.enableService}>Enable Digital Menu Service</button>
          </div>
        )
      }
      digitalMenusContent = <DigitalMenusContainer product={product}/>
    }

    return (
      <div className="digital-menus-container">
        {venueDigitalMenusContent}
        {digitalMenusContent}
      </div>
    )
  }
}

export default connect(
  function (state) {
    return {
      digitalMenus: state.digitalMenus
    };
  },
  function (dispatch) {
    return {
      digitalMenuActions: bindActionCreators(actions.digitalMenus, dispatch)
    };
  }
)(VenueDigitalMenusContainer);