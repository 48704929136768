import moment from 'moment';

let initialState = {
  data: [],
  fastpasses: [],
  filter: "open",
  query: "",
  loading: true,
  open: null,
  sum: 0,
  closing: false,
  date: moment(),
  enableTabComplete: false,
  disablePrinter: false
};

import _ from 'lodash';
// import update from 'react/lib/update' // CAUSING AN ERROR
export default function todos(state = initialState, action) {
  switch (action.type) {
    case 'TABS_SUM':
      return {...state, sum: action.sum};
    case 'TABS_LOADING':
      return {...state, data: action.data, loading: true, closing: false};
    case 'TABS_LOADED':
      return {
        ...state, 
        data: action.data["data"], 
        loading: false, 
        closing: false, 
        enableTabComplete: action.data["enable_tab_complete"],
        disablePrinter: action.data["disable_printer"]
      };
    case 'FASTPASSES_LOADED':
      return {...state, fastpasses: action.data};
    case 'TABS_FAILED':
      return {...state,loading: false};
    case 'TABS_FILTER':
      return {...state, filter: action.filter};
    case 'TABS_SEARCH':
      return {...state, query: action.query};
    case 'TAB_OPEN':
      return {...state, open: action.id}
    case 'TABS_CLOSING':
      return {...state, closing: true};
    case 'TABS_DATE':
      return {...state, date: action.newDate}
    case 'TABS_ADD':
      return {...state, data: [action.tab, ...state.data]};
    case 'TABS_UPDATED':
      return {...state, data: state.data.map((tab, index) => {
        if (tab.id == action.tab.id) {
          return Object.assign({}, tab, action.tab);
        }
        return tab;
      })};
      return state;
    default:
      return state
  }
}