import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import actions from "../actions";
import { confirmAlert } from "react-confirm-alert";
import ProductSelection from "./ProductSelection";

class ProductSelectContainer extends Component {
  constructor(props) {
    super(props);
    this.toggleCheckoutSuccess = this.toggleCheckoutSuccess.bind(this);
    this.state = {
      checkoutSuccess: false,
      product: 1
    };
  }

  toggleCheckoutSuccess(product) {
    this.setState({ ...this.state, checkoutSuccess: true, product: product });
  }

  componentDidMount() {
    const { data } = this.props.digitalMenus

    if (data) {
      this.setState({ ...this.state, product: data.product });
    }
  }

  componentWillUnmount() {
  }

  render() {
    return (
      <div className="schedule-modal item-modal select-product">
        <div className="row">
          <div className="column align-center">
            <h2 style={{margin: "0 0 50px 0"}}>Choose your plan to get started...</h2>
            <ProductSelection 
              initialProduct={ this.state.product } 
              initialFrequency={true}
              checkoutSuccess={(product) => this.toggleCheckoutSuccess(product)} 
              update={false}
              addVenue={false}
            />
          </div>
        </div>
      </div>
    )
  }
};


export default connect (
  function (state) {
    return {
      digitalMenus: state.digitalMenus,
    };
  },
  function (dispatch) {
    return {
      digitalMenuActions: bindActionCreators(actions.digitalMenus, dispatch)
    };
  }
)(ProductSelectContainer);