import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import actions from "../actions";
import { Elements } from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from "./CheckoutForm";

class ProductCheckout extends Component {
  constructor(props) {
    super(props);
    this.paymentComplete = this.paymentComplete.bind(this);
    this.state = {
      paymentIntent: undefined
    }
  }

  componentDidMount() {
    let { digitalMenuActions, product, isMonthly } = this.props;
    digitalMenuActions.productPaymentSetup(product, isMonthly);
  }

  componentWillUnmount() {
    this.props.digitalMenuActions.clearPaymentSetup()
  }

  paymentComplete(payment) {
    let { digitalMenuActions, product, isMonthly } = this.props;
    digitalMenuActions.productPaymentComplete(product, isMonthly, payment, 
      function (venueId) {
        window.location.assign(`${window.location.origin}/venues/${venueId}/digital_menus`)
      }.bind(this)
    );
  }

  render() {
    let { paymentSetupInfo, stripePublicKey } = this.props.digitalMenus;
    let options = undefined
    let amount = undefined

    if (paymentSetupInfo && paymentSetupInfo["client_secret"]) {
      options = { 
        clientSecret: paymentSetupInfo["client_secret"],
        appearance: { theme: 'stripe' }
      };
      if (paymentSetupInfo["client_secret"]) {
        amount = (paymentSetupInfo["metadata"]["amount"] / 100).toFixed(2)
      }
    }

    return (
      <div>
        {
          options && stripePublicKey && (
            <Elements stripe={loadStripe(stripePublicKey)} options={options}>
              <CheckoutForm 
                amount={amount}
                type={"setup"} 
                paymentComplete={(payment) => this.paymentComplete(payment)}
              />
            </Elements>
          )
        }
      </div>
    )
  }}

export default connect(
  function (state) {
    return {
      digitalMenus: state.digitalMenus
    };
  },
  function (dispatch) {
    return {
      digitalMenuActions: bindActionCreators(actions.digitalMenus, dispatch)
    };
  }
)(ProductCheckout);