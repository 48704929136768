$(document).on("turbolinks:load", function () {
  $("#start-timepicker").datetimepicker({
    icons: {
      previous: 'fa fa-arrow-left',
      next: 'fa fa-arrow-right',
      time: "fa fa-clock-o",
      date: "fa fa-calendar",
      up: "fa fa-arrow-up",
      down: "fa fa-arrow-down",
    },
    format: "YYYY-MM-DD hh:mm A",
  });

  $("#end-timepicker").datetimepicker({
    icons: {
      previous: 'fa fa-arrow-left',
      next: 'fa fa-arrow-right',
      time: "fa fa-clock-o",
      date: "fa fa-calendar",
      up: "fa fa-arrow-up",
      down: "fa fa-arrow-down",
    },
    format: "YYYY-MM-DD hh:mm A",
  });

  $(" #set_time_range").on("click", function () {
    $(".smaller-loader").show();
    window.location.replace(
      "/reporting/" +
      $(this).attr("data-target") +
      "?venue_id=" +
      $(this).attr("data-venue") +
      "&shift_start=" +
      $("#start-timepicker").data("date") +
      "&shift_end=" +
      $("#end-timepicker").data("date") + 
      "&combine=" +
      $(this).attr("data-combine")
    );
  });

  if ($(".help-icon").length) {
    poll(
      function () {
        var retVal = false;
        $.ajax({
          type: "GET",
          url: "download?venue_id=" + $("#venue_id").val(),
          dataType: "json",
          async: false,
          success: function (data) {
            if (data.file_url !== null) {
              var disbursementsTable = buildDisbursementsTable(
                data.disbursements
              );
              var download_link =
                '&nbsp;&nbsp;<a download class="large-link" href=' +
                data.file_url +
                ">Download Detailed Report</a>";
              $("#disbursement-progress").hide();
              $("#disbursements_report_date").after(download_link);
              $("#combined_disbursements_report_date").after(download_link);
              $("form").after(disbursementsTable);
              retVal = true;
            } else {
              retVal = false;
            }
          },
        });
        return retVal;
      },
      900000,
      2000
    )
      .then(function () { })
      .catch(function (e) {
        console.log("error: " + e);
      });
  }

  $('[data-toggle="tooltip"]').tooltip();
});

function buildDisbursementsTable(disbursements) {
  var table = '<table id="disbursements-table"><thead><tr>';
  var headers = [];
  var optionalHeader = disbursements[0][3] !== "Taxes";

  if (optionalHeader) {
    headers = [
      "Date",
      "Transactions",
      "Gross Sales",
      disbursements[0][3],
      "Taxes",
      "Tips",
      "Discounts",
      "Barpay Fees",
      "Refunds",
      "Disputes",
      "Dispute Fees",
      "Deposit Total",
    ];
  } else {
    headers = [
      "Date",
      "Transactions",
      "Gross Sales",
      "Taxes",
      "Tips",
      "Discounts",
      "Barpay Fees",
      "Refunds",
      "Disputes",
      "Dispute Fees",
      "Deposit Total",
    ];
  }
  disbursements.shift();
  var disbursementObjects = disbursements.map(function (record) {
    if (optionalHeader) {
      return {
        name: record[0],
        opened: record[1],
        gross: record[2],
        optional_fees: record[3],
        taxes: record[4],
        tips: record[5],
        discounts: record[6],
        processed: record[7],
        is_fastpass: record[8],
        fees: record[9],
        refunds: record[10],
        disputes: record[11],
        dispute_fees: record[12],
        disbursement_date: record[13],
        total: record[14],
        batch: record[15],
        tx_id: record[16],
      };
    } else {
      return {
        name: record[0],
        opened: record[1],
        gross: record[2],
        taxes: record[3],
        tips: record[4],
        discounts: record[5],
        processed: record[6],
        is_fastpass: record[7],
        fees: record[8],
        refunds: record[9],
        disputes: record[10],
        dispute_fees: record[11],
        disbursement_date: record[12],
        total: record[13],
        batch: record[14],
        tx_id: record[15],
      };

    }
  });

  groupedDisbursements = groupBy(disbursementObjects, function (disbursement) {
    return disbursement.disbursement_date;
  });

  for (var i = 0; i < headers.length; i++) {
    table += "<th>" + headers[i] + "</th>";
  }

  table += "</tr></thead><tbody>";
  var mapIter = groupedDisbursements.entries();
  var ind = mapIter.next();
  while (!ind.done) {
    var k = ind.value[0];
    var parts = k.split("-");
    var mydate = new Date(parts[0], parts[1] - 1, parts[2]);
    table += "<tr>";
    var summed = sum(groupedDisbursements.get(k));
    var tx = summed.transaction === undefined ? 1 : summed.transaction;
    table += "<td>" + mydate.toLocaleDateString("en-US") + "</td>";
    table += "<td>" + tx + "</td>";
    table += "<td>" + accounting.formatMoney(summed.gross) + "</td>";
    if (optionalHeader) table += "<td>" + accounting.formatMoney(summed.optional_fees) + "</td>";
    table += "<td>" + accounting.formatMoney(summed.taxes) + "</td>";
    table += "<td>" + accounting.formatMoney(summed.tips) + "</td>";
    table += "<td>" + accounting.formatMoney(summed.discounts) + "</td>";
    table += "<td>" + accounting.formatMoney(summed.fees) + "</td>";
    table += "<td>" + accounting.formatMoney(summed.refunds) + "</td>";
    table += "<td>" + accounting.formatMoney(summed.disputes) + "</td>";
    table += "<td>" + accounting.formatMoney(summed.dispute_fees) + "</td>";
    table += "<td>" + accounting.formatMoney(summed.total) + "</td>";
    table += "</tr>";
    ind = mapIter.next();
  }
  table += "<tr>";
  var summed = sum(disbursementObjects);
  var tx = summed.transaction === undefined ? 1 : summed.transaction;
  table += "<td>Totals</td>";
  table += "<td>" + tx + "</td>";
  table += "<td>" + accounting.formatMoney(summed.gross) + "</td>";
  if (optionalHeader) table += "<td>" + accounting.formatMoney(summed.optional_fees) + "</td>";
  table += "<td>" + accounting.formatMoney(summed.taxes) + "</td>";
  table += "<td>" + accounting.formatMoney(summed.tips) + "</td>";
  table += "<td>" + accounting.formatMoney(summed.discounts) + "</td>";
  table += "<td>" + accounting.formatMoney(summed.fees) + "</td>";
  table += "<td>" + accounting.formatMoney(summed.refunds) + "</td>";
  table += "<td>" + accounting.formatMoney(summed.disputes) + "</td>";
  table += "<td>" + accounting.formatMoney(summed.dispute_fees) + "</td>";
  table += "<td>" + accounting.formatMoney(summed.total) + "</td>";
  table += "</tr>";
  table += "</tbody></table>";

  return $(table);
}

function sum(disbursements) {
  return disbursements.reduce(
    function (prev, current, index) {
      return {
        transaction: index + 1,
        gross: Number.isFinite(prev.gross)
          ? prev.gross + Number(current.gross.replace(/[^0-9.-]+/g, ""))
          : Number(prev.gross.replace(/[^0-9.-]+/g, "")) +
          Number(current.gross.replace(/[^0-9.-]+/g, "")),
        optional_fees: current.optional_fees !== undefined && current.optional_fees !== null ? Number.isFinite(prev.optional_fees)
          ? prev.optional_fees + Number(current.optional_fees.replace(/[^0-9.-]+/g, ""))
          : Number(prev.optional_fees.replace(/[^0-9.-]+/g, "")) +
          Number(current.optional_fees.replace(/[^0-9.-]+/g, "")) : 0,
        taxes: Number.isFinite(prev.taxes)
          ? prev.taxes + Number(current.taxes.replace(/[^0-9.-]+/g, ""))
          : Number(prev.taxes.replace(/[^0-9.-]+/g, "")) +
          Number(current.taxes.replace(/[^0-9.-]+/g, "")),
        tips: Number.isFinite(prev.tips)
          ? prev.tips + Number(current.tips.replace(/[^0-9.-]+/g, ""))
          : Number(prev.tips.replace(/[^0-9.-]+/g, "")) +
          Number(current.tips.replace(/[^0-9.-]+/g, "")),
        discounts: Number.isFinite(prev.discounts)
          ? prev.discounts + Number(current.discounts.replace(/[^0-9.-]+/g, ""))
          : Number(prev.discounts.replace(/[^0-9.-]+/g, "")) +
          Number(current.discounts.replace(/[^0-9.-]+/g, "")),
        fees: Number.isFinite(prev.fees)
          ? prev.fees + Number(current.fees.replace(/[^0-9.-]+/g, ""))
          : Number(prev.fees.replace(/[^0-9.-]+/g, "")) +
          Number(current.fees.replace(/[^0-9.-]+/g, "")),
        refunds: Number.isFinite(prev.refunds)
          ? prev.refunds + Number(current.refunds.replace(/[^0-9.-]+/g, ""))
          : Number(prev.refunds.replace(/[^0-9.-]+/g, "")) +
          Number(current.refunds.replace(/[^0-9.-]+/g, "")),
        disputes: Number.isFinite(prev.disputes)
          ? prev.disputes + Number(current.disputes.replace(/[^0-9.-]+/g, ""))
          : Number(prev.disputes.replace(/[^0-9.-]+/g, "")) +
          Number(current.disputes.replace(/[^0-9.-]+/g, "")),
        dispute_fees: Number.isFinite(prev.dispute_fees)
          ? prev.dispute_fees +
          Number(current.dispute_fees.replace(/[^0-9.-]+/g, ""))
          : Number(prev.dispute_fees.replace(/[^0-9.-]+/g, "")) +
          Number(current.dispute_fees.replace(/[^0-9.-]+/g, "")),
        total: Number.isFinite(prev.total)
          ? prev.total + Number(current.total.replace(/[^0-9.-]+/g, ""))
          : Number(prev.total.replace(/[^0-9.-]+/g, "")) +
          Number(current.total.replace(/[^0-9.-]+/g, "")),
      };
    },
    {
      transaction: 0,
      gross: 0,
      optional_fees: 0,
      taxes: 0,
      tips: 0,
      discounts: 0,
      fees: 0,
      refunds: 0,
      disputes: 0,
      dispute_fees: 0,
      total: 0,
    }
  );
}

function groupBy(list, keyGetter) {
  var map = new Map();
  list.forEach(function (item) {
    var key = keyGetter(item);
    var collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return new Map([...map.entries()].sort());
}

// The polling function
function poll(fn, timeout, interval) {
  var endTime = Number(new Date()) + (timeout || 2000);
  interval = interval || 100;
  $("disbursement-progress").show();

  var checkCondition = function (resolve, reject) {
    // If the condition is met, we're done!
    var result = fn();
    if (result) {
      resolve(result);
    }
    // If the condition isn't met but the timeout hasn't elapsed, go again
    else if (Number(new Date()) < endTime) {
      $.ajax({
        type: "GET",
        url: "download_progress?venue_id=" + $("#venue_id").val(),
        dataType: "json",
        async: false,
        success: function (data) {
          $("#disbursement-progress div").attr(
            "style",
            "width: " + data.latest_progress * 160 + "%" // have to do 140 because braintree doesn't report exact size and it tends to be lower than reality
          );
        },
      });

      setTimeout(checkCondition, interval, resolve, reject);
    }
    // Didn't match and too much time, reject!
    else {
      reject(new Error("timed out for " + fn + ": " + arguments));
    }
  };

  return new Promise(checkCondition);
}
