import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import actions from "../actions";
import { Elements } from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from "./CheckoutForm";
import { Collapse } from "react-bootstrap";
import DefaultCheckbox from "./DefaultCheckbox";

class EnterpriseCheckout extends Component {
  constructor(props) {
    super(props);
    this.paymentComplete = this.paymentComplete.bind(this);
    this.toggleDefault = this.toggleDefault.bind(this);
    this.state = {
      useDefault: true
    }
  }

  componentDidMount() {
    let { digitalMenuActions } = this.props;
    let { id } = this.props.digitalMenus.data.venue_data;
    digitalMenuActions.getStripeInfo()
    digitalMenuActions.enterprisePaymentSetup(id);
  }

  componentWillUnmount() {
    this.props.digitalMenuActions.clearStripeInfo()
    this.props.digitalMenuActions.clearPaymentSetup()
  }

  toggleDefault() {
    let check = this.state.useDefault;
    this.setState({...this.state, useDefault: !check});
  }

  paymentComplete(payment) {
    let { digitalMenuActions, digitalMenus } = this.props;
    let { data, paymentSetupInfo, stripeInfo } = digitalMenus;
    let defaultCheck = false;

    if (stripeInfo.default && this.state.useDefault) {
      payment = paymentSetupInfo
      defaultCheck = true
    }

    digitalMenuActions.enterprisePaymentComplete(data.venue_data.id, defaultCheck, payment, 
      function () {
        this.props.modalToggle();
      }.bind(this)
    );
  }

  render() {
    let { paymentSetupInfo, data, stripeInfo } = this.props.digitalMenus;
    let { useDefault } = this.state;
    let showForm = !(stripeInfo && stripeInfo.default && useDefault)
    let options = undefined
    let amount = undefined

    if (paymentSetupInfo && paymentSetupInfo["client_secret"]) {
      options = { 
        clientSecret: paymentSetupInfo["client_secret"],
        appearance: { theme: 'stripe' }
      };
      if (paymentSetupInfo["metadata"]) {
        amount = (parseFloat(paymentSetupInfo["metadata"]["amount"]) / 100).toFixed(2)
      }
    }
    
    return (
      <div className="dm-checkout">
        <div style={{width: "100%"}}>
          <p 
            style={{
              margin: "30px 0 30px 0", 
              textAlign: "left", 
              fontWeight: "bold", 
              fontSize: "16px"
            }}
          >
            For $12 per month you'll have the ability to manage Digital Menus across multiple venues from one account.
          </p>
        </div>
        { data && stripeInfo && stripeInfo.default && (
            <DefaultCheckbox 
              defaultPayment={stripeInfo.default}
              useDefault={useDefault}
              toggleDefault={this.toggleDefault}
              amount={amount}
              paymentComplete={(payment) => this.paymentComplete(payment)}
            />
          )
        }
        <Collapse in={showForm} > 
          <div>
          {
            options && data && (
              <Elements stripe={loadStripe(data.stripe_key)} options={options}>
                <CheckoutForm
                  type={"setup"} 
                  amount={amount} 
                  paymentComplete={(payment) => this.paymentComplete(payment)}
                />
              </Elements>
            )
          }
          </div>
        </Collapse>
      </div>
    )
  }}

  export default connect(
    function (state) {
      return {
        digitalMenus: state.digitalMenus
      };
    },
    function (dispatch) {
      return {
        digitalMenuActions: bindActionCreators(actions.digitalMenus, dispatch)
      };
    }
  )(EnterpriseCheckout);