import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Modal from "react-modal";
import actions from "../actions";
import SubCategoryEditorHeader from "./SubCategoryEditorHeader";
import CategoryEditorHeader from "./CategoryEditorHeader";
import ItemReorderEditor from "./ItemReorderEditor";

const customStyles = {
  content: {
    top: "30%",
    right: "auto",
    bottom: "auto",
    border: "none",
    borderRadius: "10px",
    transform: "translate(-30%, -30%)",
  },
  overlay: {
    backgroundColor: "rgba(50, 62, 72, 0.5)",
  },
};

class ItemReorderModal extends Component {
  constructor(props) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
  }

  closeModal() {
    this.props.menuActions.toggleReorderModal();
  }

  render() {
    const options = [];
    let saveButton = <button onClick={this.closeModal}>Close</button>;
    const sortedCategories = this.props.categories.sort(
            function (a, b) {
              if (a.menu_id === b.menu_id) {
                return a.position > b.position ? 1 : -1;
              } else {
                return a.menu_id > b.menu_id ? 1 : -1;
              }
            }
          );

    for (const [categoryIndex, categoryValue] of sortedCategories.entries()) {
      options.push(
        <CategoryEditorHeader
          span={3}
          key={`category-header-${categoryIndex}`}
          name={`${categoryValue.name}`}
        />
      );

      const subs = this.props.subcategories.filter((item) => item.menu_category_id === categoryValue.id);
      const sortedSubcategories = subs.sort(
              function (a, b) {
                return a.position > b.position ? 1 : -1;
              }
            );
      for (const [subIndex, subValue] of sortedSubcategories.entries()) {
        let menuItems = this.props.items.filter((item) => item.subcategory_id === subValue.id);

        if (menuItems.length > 0) {
          options.push(
            <SubCategoryEditorHeader
              span={3}
              key={`header-${subValue.id}`}
              name={`${subValue.name}`}
            />
          );

          const sortedMenuItems = menuItems.sort(
                  function (a, b) {
                    return a.position > b.position ? 1 : -1;
                  }
                );

          for (const [itemIndex, itemValue] of sortedMenuItems.entries()) {
            options.push(
              <ItemReorderEditor
                key={`item-${itemValue.id}`}
                name={itemValue.name}
                id={itemValue.id}
              />
            );
          }
        }
      }
    }

    return (
      <Modal
        isOpen={this.props.menus.reorderModalOpen}
        onRequestClose={this.closeModal}
        onAfterOpen={this.onAfterOpen}
        style={customStyles}
        contentLabel="Reorder Items Modal"
        ariaHideApp={false}
        shouldCloseOnOverlayClick={false}
      >
        <div className="schedule-modal">
          <div className="schedule-modal-header">
            <span>Invisible Span</span>
            <h3>Reorder Menu Items</h3>
            <button className="schedule-close-button" onClick={this.closeModal}>
              <h3>X</h3>
            </button>
          </div>
          <p>
            Control how items appear on your menu
          </p>
          <div className={"category-manager subcategory"}>
            <table>
              <tbody>{options}</tbody>
            </table>
          </div>
          <div className="schedule-modal-save">
            {saveButton}
          </div>
        </div>
      </Modal>
    );
  }
}
export default connect(
  function (state) {
    return {
      menus: state.menus,
      categories: state.menus.categories,
      subcategories: state.menus.subcategories,
      items: state.menus.items,
    };
  },
  function (dispatch) {
    return {
      menuActions: bindActionCreators(actions.menus, dispatch),
    };
  }
)(ItemReorderModal);
