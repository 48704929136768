import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import actions from "../actions";
import Loader from "../menus/Loader";
import DoItForMeModal from "./DoItForMeModal";

class DisplayPdfContainer extends Component {
  constructor(props) {
    super(props);
    this.startGeneratePdfs = this.startGeneratePdfs.bind(this);
    this.modalToggle = this.modalToggle.bind(this);
    this.pollPdfs = this.pollPdfs.bind(this);
    this.state = {
      isModalOpen: false,
      loading: false
    }
  }

  pollPdfs() {
    let { digitalMenuActions } = this.props;
    let endTime = Number(new Date()) + (900000);
    let interval = 2000;
    const clearState = () => {
      this.setState({ ...this.state, loading: false })
    }
  
    const checkCondition = async function (resolve, reject) {
      let check = await digitalMenuActions.pollPdfs();
      if (check) {
        clearState()
        digitalMenuActions.loadDigitalMenus()
        resolve();
      } else if (Number(new Date()) < endTime) {
        setTimeout(checkCondition, interval, resolve, reject);
      } else {
        reject(new Error("timed out: " + arguments));
      }
    };
    return new Promise(checkCondition);
  }
  
  startGeneratePdfs() {
    let { digitalMenuActions } = this.props;
    let { id } = this.props.digitalMenus.data.venue_data;
    this.setState({ ...this.state, loading: true });
    digitalMenuActions.generatePdfs(id,
      function () {
        this.pollPdfs()
      }.bind(this)
    );
  }

  modalToggle () {
    let check = this.state.isModalOpen
    this.setState({ isModalOpen: !check })
  }

  getButton() {
    let { venue_dm, venue_data } = this.props.digitalMenus.data;
    let pdfUrl = venue_dm.print_ready_pdf.url
    let imageUrl = venue_data.barpay_web_logo_image.url
    let qrUrl = venue_dm.qr_code.url
    let { pdfError } = this.props.digitalMenus;
    let isLoading = this.state.loading;
    let urlError = (!qrUrl || !imageUrl)

    if(isLoading) {
      return (
        <div className="pdf-generate-button">
          <div style={{marginBottom: "15px"}}>Generating files, please wait...</div>
          <Loader />
        </div>
      )
    } else if(pdfUrl) {
      return (
        <div>
          { urlError ? (
            <div className="pdf-generate-button">
              <div style={{margin: "15px"}}>Warning: You must have a Logo and a QR Code to generate PDF!</div>
              <button disabled="true" className="btn btn-primary add-menu-button" onClick={this.startGeneratePdfs}>Regenerate Files</button>
            </div>
          ) : (
            <div className="pdf-generate-button">
              <button className="btn btn-primary add-menu-button" onClick={this.startGeneratePdfs}>Regenerate Files</button>
            </div>
          )}
        </div>
      ) 
    } else {
      return (
        <div className="pdf-generate-button">
          { pdfError && pdfError.length > 1 ? (
            <div style={{margin: "15px"}}>ERROR: {pdfError}</div>
          ) : (
            <div style={{marginBottom: "15px"}}>Files not found...</div>
          )}
          { urlError ? (
            <div className="pdf-generate-button">
              <div style={{margin: "15px"}}>Warning: You must have a Logo and a QR Code to generate PDF!</div>
              <button disabled="true" className="btn btn-primary add-menu-button" onClick={this.startGeneratePdfs}>Regenerate Files</button>
            </div>
          ) : (
            <div className="pdf-generate-button">
              <button className="btn btn-primary add-menu-button" onClick={this.startGeneratePdfs}>Generate Files</button>
            </div>
          )}
        </div>
      )
    } 
  }

  render() {
    let { venue_dm, venue_data } = this.props.digitalMenus.data;
    let button = <div></div>;
    let preview = <div></div>;

    if (venue_dm.print_ready_pdf.url) {
      button = this.getButton()
      preview = <div className="print-ready-viewer-container">
                  <iframe 
                    className="print-ready-viewer"
                    name={venue_data.name}
                    src={venue_dm.print_ready_pdf.url}
                    title="webviewer"
                  ></iframe>
                  <a 
                    className="dm-tab-view-link"
                    href={venue_dm.print_ready_pdf.url}
                    target={"_blank"}
                  >
                    View in new tab <i className="fa fa-file-pdf-o"></i>
                  </a>
                </div>
    } else if (venue_dm.do_it_for_me) {
      button = this.getButton()
    } else {
      button = this.getButton()
    }

    return (
      <div className="dm-print-ready-container">
        {preview}
        {button}
        <DoItForMeModal isModalOpen={this.state.isModalOpen} modalToggle={this.modalToggle} />
      </div>
    ) 
  }

}


export default connect(
  function (state) {
    return {
      digitalMenus: state.digitalMenus
    };
  },
  function (dispatch) {
    return {
      digitalMenuActions: bindActionCreators(actions.digitalMenus, dispatch)
    };
  }
)(DisplayPdfContainer);