import { Component } from "react";
import pluralize from "pluralize";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import actions from "../actions";
import CategoryManagementModal from "./CategoryManagementModal";
import SubCategoryManagementModal from "./SubCategoryManagementModal";

class MenuDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = { value: "default" };
    this.handleChange = this.handleChange.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedCategory !== prevProps.selectedCategory) {
      if (this.props.type === "Category" && this.props.selectedCategory === 0) {
        this.setState({ value: "default" });
      }
      if (this.props.type !== "Category" && this.props.selectedCategory === 0) {
        this.setState({ value: "default" });
      }
    }
  }

  onClick(event) {
    let { menuActions } = this.props;
    event.stopPropagation();
    if (this.props.type === "Category") {
      menuActions.toggleCategoryManagementModal();
    } else {
      menuActions.toggleSubCategoryManagementModal();
    }
  }

  handleChange(event) {
    let { menuActions } = this.props;
    let categoryId = event.target.options[
      event.target.selectedIndex
    ].getAttribute("data-id");

    this.setState({ value: event.target.value }, function () {
      if (this.props.type === "Category") {
        var valueToSend =
          this.state.value === "default" ? "" : this.state.value;
        menuActions.setMenuCategory(valueToSend, parseInt(categoryId, 10));
      } else {
        var valueToSend =
          this.state.value === "default" ? "" : this.state.value;
        menuActions.setMenuSubCategory(valueToSend);
      }
    });
  }

  modalToRender() {
    if (this.props.type === "Category") {
      return <CategoryManagementModal type={this.props.type} />;
    } else {
      return <SubCategoryManagementModal type={this.props.type} />;
    }
  }

  subcategoriesFilter(items, category_id) {
    return _.filter(items, function (item) {
      return item.menu_category_id === category_id;
    });
  }

  categoriesFilter(items, menuName) {
    return _.filter(
      items,
      function (item) {
        const menu = _.find(this.props.menus.menus, function (menu) {
          return menuName.toLowerCase() === menu.name.toLowerCase();
        });

        return item.menu_id === menu.id;
      }.bind(this)
    );
  }

  render() {
    const options = [];
    options.push(
      <option key={0} value="default" data-id={0}>
        Select {this.props.type}
      </option>
    );

    let items = this.props.type === "Category" ? [] : this.props.subcategories;

    if (this.props.type === "Category") {
      if (this.props.foodFilter) {
        items = items.concat(
          this.categoriesFilter(this.props.categories, "food")
        );
      }
      if (this.props.drinksFilter) {
        items = items.concat(
          this.categoriesFilter(this.props.categories, "drinks")
        );
      }
      if (this.props.extrasFilter) {
        items = items.concat(
          this.categoriesFilter(this.props.categories, "extras")
        );
      }
    }

    if (this.props.type !== "Category" && this.props.selectedCategory !== 0) {
      items = this.subcategoriesFilter(items, this.props.selectedCategory);
    } else if (this.props.type !== "Category") {
      let categories = [];
      if (this.props.foodFilter) {
        categories = categories.concat(
          this.categoriesFilter(this.props.categories, "food")
        );
      }
      if (this.props.drinksFilter) {
        categories = categories.concat(
          this.categoriesFilter(this.props.categories, "drinks")
        );
      }
      if (this.props.extrasFilter) {
        categories = categories.concat(
          this.categoriesFilter(this.props.categories, "extras")
        );
      }

      items = _.filter(
        items,
        function (item) {
          return this.find((x) => x.id === item.menu_category_id) !== undefined;
        }.bind(categories)
      );
    }

    for (const [index, value] of items.entries()) {
      options.push(
        <option key={index + 1} value={value.name} data-id={value.id}>
          {value.name}
        </option>
      );
    }

    return (
      <div className="menu-dropdown">
        <label>
          <select value={this.state.value} onChange={this.handleChange}>
            {options}
          </select>
        </label>
        <a onClick={this.onClick}>Manage {pluralize(this.props.type)}</a>
        {this.modalToRender()}
      </div>
    );
  }
}

export default connect(
  function (state) {
    return {
      menus: state.menus,
      categories: state.menus.categories,
      subcategories: state.menus.subcategories,
      selectedCategory: state.menus.filterMenuCategoryId,
      foodFilter: state.menus.foodFilter,
      drinksFilter: state.menus.drinksFilter,
      extrasFilter: state.menus.extrasFilter,
    };
  },
  function (dispatch) {
    return {
      menuActions: bindActionCreators(actions.menus, dispatch),
    };
  }
)(MenuDropdown);
