import {Component} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import actions from '../actions';
import {closePromoCode} from '../actions/promotion_codes.js';
import PromotionCode from './PromotionCode.js';
import PromotionCodeAddButtonContainer from './PromotionCodeAddButton.js';
import PromotionCodeAddContainer from './PromotionCodeAdd.js';

class PromotionCodeList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editable: false
    }
  }

  editPromoCodes() {
    if (this.state.editable){
      this.props.dispatch(closePromoCode());
    }

    this.setState({editable: !this.state.editable});
  }

  render() {
    return <div>
      <table className="promotion-code-list">
        <thead>
          <tr>
            <th colSpan="10">Current Campaigns</th>
          </tr>
          <tr>
            <th>Name</th>
            <th>Code</th>
            <th>Discount</th>
            <th>Limit</th>
            <th>Expires</th>
            <th>Venue</th>
            <th># Redeemed</th>
            <th>Type</th>
            <th><a id="editPromoCodes" onClick={this.editPromoCodes.bind(this)}>{this.state.editable ? 'Done' : 'Edit'}</a></th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {this.props.promoCodes.map((promoCode, index) => {
            return <PromotionCode promoCode={promoCode} key={index} editable={this.state.editable}/>
          })}
          <PromotionCodeAddButtonContainer editable={this.state.editable}/>
        </tbody>
      </table>
      <PromotionCodeAddContainer venues={this.props.venues} limits={this.props.limits}/>
    </div>
  }
}

function mapStateToProps(state){  
  return {
    promoCodes: state.promotionCodes.promotion_codes,
    venues: state.promotionCodes.venues,
    limits: state.promotionCodes.limits
  };
}

const PromotionCodeListContainer = connect(
  mapStateToProps
)(PromotionCodeList)

export default PromotionCodeListContainer
