import { Component } from "react";

class Loader extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="items-loader">
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
      </div>
    );
  }
}
export default Loader;
