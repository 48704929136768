import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import actions from "../actions";
import Modal from "react-modal";
import UpdatePaymentPanel from "./UpdatePaymentPanel";
import UpdatePlanPanel from "./UpdatePlanPanel";



const customStyles = {
  content: {
    width: "75%",
    top: "30%",
    right: "auto",
    bottom: "auto",
    border: "none",
    borderRadius: "10px",
    transform: "translate(-50%, 0%)",
  },
  overlay: {
    backgroundColor: "rgba(50, 62, 72, 0.5)",
  },
};

class UpdateModal extends Component {
  constructor(props) {
    super(props);
    this.setPlanOpen = this.setPlanOpen.bind(this);
    this.clearPlanOpen = this.clearPlanOpen.bind(this);
    this.setPaymentOpen = this.setPaymentOpen.bind(this);
    this.clearPaymentOpen = this.clearPaymentOpen.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.state = {
      paymentOpen: false,
      planOpen: false
    }
  }

  closeModal() {
    let { digitalMenuActions } = this.props;
    this.setState({ ...this.state, planOpen: false, paymentOpen: false });
    digitalMenuActions.togglePaymentModal(false);
  }

  setPlanOpen() {
    this.setState({ ...this.state, planOpen: true, paymentOpen: false });
  }

  clearPlanOpen() {
    this.setState({ ...this.state, planOpen: false });
  }

  setPaymentOpen() {
    this.setState({ ...this.state, planOpen: false, paymentOpen: true });
  }

  clearPaymentOpen() {
    this.setState({ ...this.state, paymentOpen: false });
  }

  render() {
    let { digitalMenus } = this.props;
    let { paymentOpen, planOpen } = this.state;

    let planOpenCheck = digitalMenus.planSelectOpen ? true : planOpen

    if (paymentOpen || planOpenCheck) {
      customStyles.content.top = "15%"
    } else {
      customStyles.content.top = "30%"
    }
    
    return (
      <Modal
        isOpen={digitalMenus.paymentModalOpen}
        onRequestClose={this.closeModal}
        style={customStyles}
        contentLabel="Add Venue Modal"
        ariaHideApp={false}
        shouldCloseOnOverlayClick={false}
      >
        <div className="schedule-modal update-payment-modal">
          <div 
            className="schedule-modal-header"
            style={{display: "flex", justifyContent: "space-between" }}
          >
            <h3 style={{display: "inline-block"}}>Update Plan/Payment</h3>
            <button 
              className="schedule-close-button" 
              onClick={this.closeModal}
              style={{display: "flex", position: "static"}}
            >
              <h3 style={{display: "inline-block"}}>X</h3>
            </button>
          </div>
          <div className="update-panels-container">
            <UpdatePlanPanel 
              open={planOpenCheck} 
              setOpen={this.setPlanOpen} 
              clearOpen={this.clearPlanOpen}
            />
            <UpdatePaymentPanel 
              open={this.state.paymentOpen} 
              setOpen={this.setPaymentOpen} 
              clearOpen={this.clearPaymentOpen}
            />
            <span className="dm-venue-info-link" >
              <h4 style={{fontSize: "14px", fontWeight: "bold", marginRight: "10px" }}>Have questions? Need support? Contact us at </h4>
              <a
                href = "mailto: support@barpay.com"
                target="_blank"
              >support@barpay.com</a>
            </span>
          </div>
        </div>
      </Modal>
    );
  }
};


export default connect(
  function (state) {
    return {
      digitalMenus: state.digitalMenus
    };
  },
  function (dispatch) {
    return {
      digitalMenuActions: bindActionCreators(actions.digitalMenus, dispatch)
    };
  }
)(UpdateModal);