import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Modal from "react-modal";
import CategoryManager from "./CategoryManager";
import actions from "../actions";

const customStyles = {
  content: {
    top: "30%",
    right: "auto",
    bottom: "auto",
    border: "none",
    borderRadius: "10px",
    transform: "translate(-30%, -20%)",
  },
  overlay: {
    backgroundColor: "rgba(50, 62, 72, 0.5)",
  },
};

class SubCategoryMangementModal extends Component {
  constructor(props) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
  }

  closeModal() {
    let { menuActions } = this.props;
    menuActions.toggleSubCategoryManagementModal();
  }

  render() {
    return (
      <Modal
        isOpen={this.props.menus.subCategoryModalOpen}
        onRequestClose={this.closeModal}
        style={customStyles}
        contentLabel="Sub Category Manager Modal"
        ariaHideApp={false}
        shouldCloseOnOverlayClick={false}
      >
        <div className="schedule-modal">
          <div className="schedule-modal-header">
            <span>Invisible Span</span>
            <h3>{this.props.type} Management</h3>
            <button className="schedule-close-button" onClick={this.closeModal}>
              <h3>X</h3>
            </button>
          </div>
          <p>
            Please use the following screen to reorder, rename, remove, or add
            new sub categories
          </p>
          <CategoryManager type={"subcategory"} />
        </div>
      </Modal>
    );
  }
}

export default connect(
  function (state) {
    return { menus: state.menus };
  },
  function (dispatch) {
    return {
      menuActions: bindActionCreators(actions.menus, dispatch),
    };
  }
)(SubCategoryMangementModal);
