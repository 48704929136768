import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import actions from "../actions";

class ItemSearch extends Component {
  constructor(props) {
    super(props);
    this.state = { value: "Search" };
    this.handleChange = this.handleChange.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  onClick(event) {
    if (event.target.value === "Search") {
      this.setState({ value: "" }, function () {
        this.props.menuActions.setSearch(this.state.value);
      });
    }
  }

  onBlur(event) {
    if (event.target.value === "") {
      this.setState({ value: "Search" }, function () {
        this.props.menuActions.setSearch("");
      });
    }
  }

  handleChange(event) {
    this.setState({ value: event.target.value }, function () {
      this.props.menuActions.setSearch(this.state.value);
    });
  }

  render() {
    return (
      <div className="item-search">
          <input
            type="text"
            value={this.state.value}
            onChange={this.handleChange}
            onClick={this.onClick}
            onBlur={this.onBlur}
          />{" "}
      </div>
    );
  }
}

export default connect(
  function (state) {
    return {};
  },
  function (dispatch) {
    return {
      menuActions: bindActionCreators(actions.menus, dispatch),
    };
  }
)(ItemSearch);
