import {Component} from 'react'
import moment from 'moment'

class UserFastPass extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    let {data} = this.props;
    return <div className="panel panel-default">
      <table className="table cursor">
        <tbody>
        <tr>
          <td><h1 className="tabs-name">{data.customer_name}</h1></td>
          <td><h1 className="tabs-name">{data.quantity}</h1></td>
          <td><h1 className="tabs-smaller-total total">${parseFloat(data.total).formatMoney()}</h1></td>
          <td><h1 className="tabs-time">{moment(data.purchased_at).format('LT')}</h1></td>
          <td><h1 className="tabs-time">{moment(data.redeemed_at).format('LT')}</h1></td>
        </tr>
        </tbody>
      </table>
    </div>;
  }
}

export default UserFastPass