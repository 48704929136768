import { Component } from "react";

class SubCategoryEditorHeader extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <tr className="subcategory-header-row">
        <td colSpan={this.props.span || 7}>
          <p>{this.props.name}</p>
        </td>
      </tr>
    );
  }
}
export default SubCategoryEditorHeader;
