import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import actions from "../actions";
import Modal from "react-modal";
import UpdateProductCheckout from "./UpdateProductCheckout";

const customStyles = {
  content: {
    width: "75%",
    top: "30%",
    right: "auto",
    bottom: "auto",
    border: "none",
    borderRadius: "10px",
    transform: "translate(-50%, 0%)",
  },
  overlay: {
    backgroundColor: "rgba(50, 62, 72, 0.5)",
  },
};

class UpdateProductModal extends Component {
  constructor(props) {
    super(props);
  }


  render() {
    let {isModalOpen, modalToggle, checkoutSuccess, product, isMonthly, updateAction, venueName } = this.props;
    return (
      <Modal
        isOpen={isModalOpen}
        onRequestClose={modalToggle}
        style={customStyles}
        contentLabel="Product Checkout Modal"
        ariaHideApp={false}
        shouldCloseOnOverlayClick={false}
      >
        <div className="schedule-modal">
          <div 
            className="schedule-modal-header"
            style={{display: "flex", justifyContent: "space-between" }}
          >
            <h3 style={{display: "inline-block"}}>Checkout</h3>
            <button 
              className="schedule-close-button" 
              onClick={modalToggle}
              style={{display: "flex", position: "static"}}
            >
              <h3 style={{display: "inline-block"}}>X</h3>
            </button>
          </div>
          <div>
            <UpdateProductCheckout 
              modalToggle={modalToggle} 
              checkoutSuccess={checkoutSuccess} 
              product={product}
              isMonthly={isMonthly}
              updateAction={updateAction}
              venueName={venueName}
            />
          </div>
        </div>
      </Modal>
    );
  }
};


export default connect(
  function (state) {
    return {
      digitalMenus: state.digitalMenus
    };
  },
  function (dispatch) {
    return {
      digitalMenuActions: bindActionCreators(actions.digitalMenus, dispatch)
    };
  }
)(UpdateProductModal);