import request from "superagent";

const ADD_PROMO_CODE = "ADD_PROMO_CODE";
const EDIT_PROMO_CODE = "EDIT_PROMO_CODE";
const CLOSE_PROMO_CODE = "CLOSE_PROMO_CODE";
const SAVE_PROMO_CODE = "SAVE_PROMO_CODE";
const UPDATE_REPORT_START_DATE = "UPDATE_REPORT_START_DATE";
const UPDATE_REPORT_END_DATE = "UPDATE_REPORT_END_DATE";
const DELETE_PROMO_CODE = "DELETE_PROMO_CODE";

const loadPromotionCodes = function () {
  return function (dispatch, getState) {
    fetch("/settings/promotion_codes.json", { method: "GET" })
      .then((response) => response.json())
      .then(function (data) {
        dispatch({ type: "PROMOTION_CODES_LOADED", data: data });
      });
  };
};

const loadVenues = function (startTime, endTime) {
  return function (dispatch, getState) {
    const url = startTime
      ? "/settings/promotion_codes/report.json?startTime=" +
        startTime.format() +
        "&endTime=" +
        endTime.format()
      : "/settings/promotion_codes/report.json";
    fetch(url, { method: "GET" })
      .then((response) => response.json())
      .then(function (data) {
        dispatch({ type: "VENUES_LOADED", data: data });
      });
  };
};

const addPromoCode = function () {
  return {
    type: ADD_PROMO_CODE,
  };
};

const editPromoCode = function (data) {
  return {
    type: EDIT_PROMO_CODE,
    data: data,
  };
};

const deletePromoCode = function (id) {
  return function (dispatch, getState) {
    if (id) {
      request
        .delete("/settings/promotion_codes/" + id)
        .set("Content-Type", "application/json")
        .then((response) => {
          dispatch(loadPromotionCodes());
        })
        .then((response) => {
          dispatch(closePromoCode());
        });
    }
  };
};

const makePayment = function (venue, amountToPay) {
  return function (dispatch, getState) {
    request
      .put("/settings/promotion_codes/make-payment")
      .set("Content-Type", "application/json")
      .send(JSON.stringify({ venue: venue, amountToPay: amountToPay }))
      .then((response) => {
        dispatch(loadVenues());
      });
  };
};

const savePromoCode = function (data) {
  return function (dispatch, getState) {
    if (data.id) {
      request
        .put("/settings/promotion_codes/" + data.id)
        .set("Content-Type", "application/json")
        .send(JSON.stringify(data))
        .then((response) => {
          dispatch(loadPromotionCodes());
        })
        .then((response) => {
          dispatch(closePromoCode());
        });
    } else {
      request
        .post("/settings/promotion_codes/")
        .set("Content-Type", "application/json")
        .send(JSON.stringify(data))
        .then((response) => {
          dispatch(loadPromotionCodes());
        })
        .then((response) => {
          dispatch(closePromoCode());
        });
    }
  };
};

const closePromoCode = function () {
  return {
    type: CLOSE_PROMO_CODE,
  };
};

const updateReportStartDate = function (data) {
  return {
    type: UPDATE_REPORT_START_DATE,
    data: data,
  };
};

const updateReportEndDate = function (data) {
  return {
    type: UPDATE_REPORT_END_DATE,
    data: data,
  };
};

export default { addPromoCode };
export {
  loadPromotionCodes,
  loadVenues,
  addPromoCode,
  editPromoCode,
  deletePromoCode,
  makePayment,
  savePromoCode,
  closePromoCode,
  updateReportEndDate,
  updateReportStartDate,
};
