import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import actions from "../actions";
import CategoryEditor from "./CategoryEditor";
import SubCategoryEditorHeader from "./SubCategoryEditorHeader";

class CategoryManager extends Component {
  constructor(props) {
    super(props);
    let selectedSubCategory =
      this.props.categories.length > 0 ? this.props.categories[0].name : "";
    this.state = {
      newCategory:
        "Enter " +
        this.props.type.charAt(0).toUpperCase() +
        this.props.type.slice(1) +
        " name",
      selected_menu: "Food",
      newCategoryError: "",
      selected_subcategory: selectedSubCategory,
    };

    this.addCategory = this.addCategory.bind(this);
    this.categoryInput = this.categoryInput.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onInputClick = this.onInputClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubCategoryChange = this.handleSubCategoryChange.bind(this);
  }

  onInputClick(event) {
    if (
      event.target.value ===
      "Enter " +
        this.props.type.charAt(0).toUpperCase() +
        this.props.type.slice(1) +
        " name"
    ) {
      this.setState({ newCategory: "" });
    }
  }

  categoryInput(event) {
    this.setState({ newCategory: event.target.value });
  }

  onBlur(event) {
    if (event.target.value === "") {
      this.setState({
        newCategory:
          "Enter " +
          this.props.type.charAt(0).toUpperCase() +
          this.props.type.slice(1) +
          " name",
      });
    }
  }

  addCategory() {
    if (!this.state.newCategory.includes("Enter ")) {
      if (this.props.type === "category") {
        this.props.menuActions.addCategory(
          this.props.type,
          this.state.newCategory,
          this.state.selected_menu,
          function () {
            this.setState({
              selected_menu: "Food",
              newCategory:
                "Enter " +
                this.props.type.charAt(0).toUpperCase() +
                this.props.type.slice(1) +
                " name",
            });
          }.bind(this)
        );
      } else {
        this.props.menuActions.addCategory(
          this.props.type,
          this.state.newCategory,
          this.state.selected_subcategory,
          function () {
            this.setState({
              selected_subcategory: this.props.categories[0].name,
              newCategory:
                "Enter " +
                this.props.type.charAt(0).toUpperCase() +
                this.props.type.slice(1) +
                " name",
            });
          }.bind(this)
        );
      }
    } else {
      this.setState({ newCategoryError: "You must enter a category name" });
    }
  }

  handleChange(event) {
    this.setState({ selected_menu: event.target.value });
  }

  handleSubCategoryChange(event) {
    this.setState({ selected_subcategory: event.target.value });
  }

  category(id) {
    return _.find(this.props.categories, function (item) {
      return item.id === id;
    });
  }

  menu(id) {
    return _.find(this.props.menus, function (item) {
      return item.id === id;
    });
  }

  render() {
    const options = [];
    const items =
      this.props.type === "category"
        ? this.props.categories.sort(function (a, b) {
            if (a.menu_id === b.menu_id) {
              return a.position > b.position ? 1 : -1;
            } else {
              return a.menu_id > b.menu_id ? 1 : -1;
            }
          })
        : this.props.subcategories.sort(
            function (a, b) {
              const aCategory = this.find((x) => x.id === a.menu_category_id);
              const bCategory = this.find((x) => x.id === b.menu_category_id);

              if (aCategory.menu_id === bCategory.menu_id) {
                if (a.menu_category_id === b.menu_category_id) {
                  return a.position > b.position ? 1 : -1;
                } else {
                  return aCategory.position > bCategory.position ? 1 : -1;
                }
              } else {
                return aCategory.menu_id > bCategory.menu_id ? 1 : -1;
              }
            }.bind(this.props.categories)
          );

    let previousCategory = "";
    let previousMenu = "";
    for (const [index, value] of items.entries()) {
      if (this.props.type === "category") {
        let menu = this.menu(value.menu_id);
        if (previousMenu != menu.name) {
          options.push(
            <SubCategoryEditorHeader
              key={`header-${index}`}
              name={`${menu.name}`}
            />
          );
        }
        previousMenu = menu.name;
        options.push(
          <CategoryEditor
            key={index}
            name={value.name}
            id={value.id}
            type={this.props.type}
          />
        );
      } else {
        let category = this.category(value.menu_category_id);
        let menu = this.menu(category.menu_id);
        if (previousCategory != category.name) {
          options.push(
            <SubCategoryEditorHeader
              key={`header-${index}`}
              name={`${menu.name} - ${category.name}`}
            />
          );
        }
        previousCategory = category.name;
        options.push(
          <CategoryEditor
            key={index}
            name={value.name}
            id={value.id}
            type={this.props.type}
          />
        );
      }
    }

    var menus = [];
    for (const [index, value] of this.props.menus.entries()) {
      let name = value.name;
      if (name === "Extras") name = "Mods/Extras";
      menus.push(
        <option key={index + 1} value={value.name}>
          {name}
        </option>
      );
    }

    var subcategories_options = [];
    var subcategory_select = undefined;
    if (this.props.type !== "category") {
      for (const [index, value] of this.props.categories.entries()) {
        subcategories_options.push(
          <option key={index + 1} value={value.name}>
            {value.name}
          </option>
        );
      }
      subcategory_select = (
        <select
          value={this.state.selected_subcategory}
          onChange={this.handleSubCategoryChange}
        >
          {subcategories_options}
        </select>
      );
    }

    var category_select = undefined;
    if (this.props.type === "category") {
      category_select = (
        <select value={this.state.selected_menu} onChange={this.handleChange}>
          {menus}
        </select>
      );
    }

    return (
      <div className={"category-manager " + this.props.type}>
        <table>
          <tbody>{options}</tbody>
        </table>
        <input
          id="name"
          name="name"
          onChange={this.categoryInput}
          onClick={this.onInputClick}
          onBlur={this.onBlur}
          type="text"
          value={this.state.newCategory}
        />
        {category_select}
        {subcategory_select}
        <button onClick={this.addCategory}>
          Add New{" "}
          {this.props.type.charAt(0).toUpperCase() + this.props.type.slice(1)}
        </button>
        {this.props.menuAddError !== undefined && this.props.menuAddError && (
          <div className="error">{"You cannot add the same category"}</div>
        )}
        {this.state.newCategoryError !== undefined &&
          this.state.newCategoryError !== "" && (
            <div className="error">{this.state.newCategoryError}</div>
          )}
      </div>
    );
  }
}

export default connect(
  function (state) {
    return {
      menus: state.menus.menus,
      categories: state.menus.categories,
      subcategories: state.menus.subcategories,
      menuAddError: state.menus.menuAddError,
    };
  },
  function (dispatch) {
    return {
      menuActions: bindActionCreators(actions.menus, dispatch),
    };
  }
)(CategoryManager);
