import React, { Component } from "react";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import actions from "../actions";

import DatePicker from "react-datepicker";
import consumer from "../../cable";
import PrinterStatusContainer from "./PrinterStatusContainer";
import PrinterGraphs from "./PrinterGraphs";
import "react-datepicker/dist/react-datepicker.css";
import "../../../stylesheets/tabs.scss"

class OwnerBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      printers: [],
      showHistories: false,
    };
  }

  componentWillUnmount() {
    this.state.tabSub.unsubscribe();
  }

  async componentDidMount() {
    const that = this;
    let { tabActions } = this.props;
    let printers = await tabActions.printerStatus();

    let tabSub = consumer.subscriptions.create(
      { channel: "TabChannel", venue: window.venue.id },
      {
        received(data) {
          if(printers) {
            const printersUpdate = printers.map((p) => {
              if(p["printer_type"] === data["printer_type"]) {
                p.status = data["status"] === "online"
              }
              return p
            })
            that.setState({ printers: printersUpdate });
          }
        },
      }
    );

    this.setState({
      tabSub: tabSub,
      printers: printers
    });
  }

  submit = () => {
    let { tabActions } = this.props;

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="confirm-close-tabs">
            <h2>Are you sure you want to close all user tabs?</h2>
            <button onClick={onClose}>No</button>
            <button
              onClick={() => {
                tabActions.closeAll();
                onClose();
              }}
            >
              Yes
            </button>
          </div>
        );
      },
    });
  };

  toggleHistory = () => {
    this.setState({
      showHistories: !this.state.showHistories,
    });
  };

  render() {
    let { tabActions, tabs } = this.props;
    let selected_date = null

    if (tabs.date) {
      selected_date = tabs.date["_d"]
    }

    let printerStatus = (
      <div className="col-md-4 text-center tabs-column"></div>
    );

    if (window.venue.printer_healthchecks_enabled && this.state.printers) {
      printerStatus = < PrinterStatusContainer 
                          printers={this.state.printers} 
                          toggleHistory={this.toggleHistory} 
                          showHistories={this.state.showHistories}
                          disablePrinter={tabs.disablePrinter}
                        />
    }

    let qrOrders = <div></div>;
    let tabData = _.filter(tabs.data, (tab) => {
      const orders = _.filter(tab.placed_orders, (order) => {
        return (order.notification_status !== "completed") && order.print_qr;
      });

      return orders.length > 0;
    });

    if (tabData !== undefined && tabData.length > 0) {
      qrOrders = 
      <div className="row">
        <h4 style={{display: "inline-block", marginLeft: "1em", marginRight: "1em", fontWeight: "bold"}}>There are {tabData.length} orders available for text message notifications</h4>
        <button
          className="btn btn-primary"
          onClick={() => window.location.assign(`${window.location.origin}/venues/${window.venue.id}/qrorders`)}
        >Go to <i className="fa fa-bell"></i></button>
      </div>;
    }

    return (
      <div id="page-wrapper" className="tabs-overview-wrapper">
        <div className="container-fluid">
          <div className="tabs-overview-row row">
            <div
              className="col-md-4 text-center tabs-column"
              style={{ paddingLeft: 0 }}
            > 
            { selected_date && 
              <DatePicker
                popperPlacement="bottom"
                popperClassName="date-picker-popper"
                selected={selected_date}
                onChange={(value) => tabActions.dateChange(value)}
                className="tabs-datepicker"
              />
            }
            </div>
            {printerStatus}
            <div className="col-md-4 text-center tabs-column">
              <button
                className="btn tabs-refresh-button"
                onClick={() => tabActions.reload()}
              >
                REFRESH
              </button>
              <button
                className="btn tabs-close-all-button"
                onClick={this.submit}
                disabled={tabs.closing}
              >
                {tabs.closing ? "CLOSING..." : "CLOSE ALL TABS"}
              </button>
            </div>
          </div>
          
        </div>
        {qrOrders}
      </div>
    );
  }
}
export default connect(
  function (state) {
    return { tabs: state.tabs };
  },
  function (dispatch) {
    return {
      tabActions: bindActionCreators(actions.tabs, dispatch),
    };
  }
)(OwnerBar);
