import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import actions from "../actions";

class MenuToggleButton extends Component {
  constructor(props) {
    super(props);
    this.state = { selected: true };
    this.onClick = this.onClick.bind(this);
  }

  onClick(event) {
    this.setState({ selected: !this.state.selected }, function () {
      switch (this.props.search) {
        case "Food":
          this.props.menuActions.toggleFoodFilter(this.state.selected);
          break;
        case "Drinks":
          this.props.menuActions.toggleDrinksFilter(this.state.selected);
          break;
        case "Extras":
          this.props.menuActions.toggleExtrasFilter(this.state.selected);
          break;
      }
    });
  }

  render() {
    return (
      <div
        className={
          "menu-toggle-button menu-toggle-button-" + this.state.selected
        }
        onClick={this.onClick}
      >
        {this.props.title}
      </div>
    );
  }
}

export default connect(
  function (state) {
    return {};
  },
  function (dispatch) {
    return {
      menuActions: bindActionCreators(actions.menus, dispatch),
    };
  }
)(MenuToggleButton);
