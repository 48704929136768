import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import actions from "../actions";
import ProductSelectContainer from "./ProductSelectContainer";

class NoVenuesContainer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="no-venue-container">
        <ProductSelectContainer />
      </div>
    )
  }
}

export default connect(
  function (state) {
    return {
      digitalMenus: state.digitalMenus
    };
  },
  function (dispatch) {
    return {
      digitalMenuActions: bindActionCreators(actions.digitalMenus, dispatch)
    };
  }
)(NoVenuesContainer);