import React from 'react';
import {PaymentElement, useElements, useStripe} from '@stripe/react-stripe-js'

const CheckoutForm = (props) => {
  const [status, setStatus] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setLoading(true);
    
    if (props.update) {
      stripe
      .confirmSetup({
        elements,
        redirect: 'if_required',
        confirmParams: {return_url: window.location.href},
      })
      .then((res) => {
        if (res.error) {
          console.error(res.error);
          setLoading(false);
          setStatus(res.error.message);
        } else {
          setStatus(`${res.setupIntent.status}, please wait...`);
          props.paymentComplete(res.setupIntent)
        }
      });
    } else if (props.type === "setup") {
      stripe
      .confirmSetup({
        elements,
        redirect: 'if_required',
        confirmParams: {return_url: window.location.href},
      })
      .then((res) => {
        if (res.error) {
          console.error(res.error);
          setLoading(false);
          setStatus(res.error.message);
        } else {
          setStatus(`${res.setupIntent.status}, please wait...`);
          props.paymentComplete(res.setupIntent)
        }
      });
    } else {
      stripe
        .confirmPayment({
          elements,
          redirect: 'if_required',
          confirmParams: {return_url: window.location.href},
        })
        .then((res) => {
          if (res.error) {
            console.error(res.error);
            setLoading(false);
            setStatus(res.error.message);
          } else {
            setStatus(`${res.paymentIntent.status}, please wait...`);
            props.paymentComplete(res.paymentIntent)
          }
        });
    }
  };

  const getDescription = () => {
    let description = "Pay"

    if (props.update) {
      description = "Update"
    } else if (props.amount) {
      description = (`Pay $${props.amount}`)
    }

    return description
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <button className='btn btn-primary stripe-submit-button' type="submit" disabled={!stripe || loading}>
        { loading ? 'Processing...' : getDescription() }
      </button>
      {status && <p>{status}</p>}
    </form>
  );
};

export default CheckoutForm