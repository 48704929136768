import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import actions from "../actions";
import AddVenueModal from "./AddVenueModal";
import UpdateModal from "./UpdateModal";
import EmailModal from "./EmailModal";
import PromptUpgradeModal from "./PromptUpgradeModal";
import DoItForMeModal from "./DoItForMeModal";

class VenueActionsContainer extends Component {
  constructor(props) {
    super(props);
    this.editVenue = this.editVenue.bind(this);
    this.addVenueModalToggle = this.addVenueModalToggle.bind(this);
    this.emailModalToggle = this.emailModalToggle.bind(this);
    this.paymentModalToggle = this.paymentModalToggle.bind(this);
    this.pdfModalToggle = this.pdfModalToggle.bind(this);
    this.promptModalToggle = this.promptModalToggle.bind(this);
    this.state = {
      addVenueModal: false,
      emailModal: false,
      pdfModal: false
    }
  }

  promptModalToggle () {
    let { digitalMenuActions } = this.props;
    digitalMenuActions.togglePromptUpgradeModal("emails")
  }

  editVenue (venueId, vdmId) {
    window.location.assign(`${window.location.origin}/venues/${venueId}/venue_digital_menus/${vdmId}/edit`)
  }

  addVenueModalToggle () {
    let check = this.state.addVenueModal
    this.setState({ addVenueModal: !check, emailModal: false, pdfModal: false})
  }

  emailModalToggle () {
    let check = this.state.emailModal
    this.setState({ emailModal: !check, addVenueModal: false, pdfModal: false })
  }

  pdfModalToggle () {
    let check = this.state.pdfModal
    this.setState({ pdfModal: !check, addVenueModal: false,  emailModal: false })
  }

  paymentModalToggle () {
    let { digitalMenuActions } = this.props;
    this.setState({ addVenueModal: false, emailModal: false, pdfModal: false})
    digitalMenuActions.togglePaymentModal();
  }

  render() {
    const venueData = this.props.digitalMenus.data
    const venue = venueData["venue_data"]
    const vdm = venueData["venue_dm"]
    const enterprise  = venueData["enterprise"]
    const orderAndPay  = venueData["order_and_pay"] || false
    const product = venueData["product"]
    const emailCheck = orderAndPay || product > 2
    const doItForMe = vdm["do_it_for_me"] || false

    return (
      <div className="panel panel-default dm-venue-actions-container">
        <h4 style={{marginTop: "30px", textAlign: "center", fontWeight: "bold", fontSize: "24px"}}>Venue Actions</h4>
        <button className="btn btn-primary venue-action-button" onClick={() => this.editVenue(venue.id, vdm.id)}>Edit Current Venue</button>
        { !orderAndPay && (
          <button className="btn btn-primary venue-action-button" onClick={this.addVenueModalToggle}>Add New Venue</button>
        )}
        { !orderAndPay && (
          <button className="btn btn-primary venue-action-button" onClick={this.paymentModalToggle}>Update Plan/Payment</button>
        )}
        <button className="btn btn-primary venue-action-button" onClick={emailCheck ? this.emailModalToggle : this.promptModalToggle}>Download Customer Emails</button>
        <button className="btn btn-primary add-menu-button" onClick={this.pdfModalToggle}>Print Ready Menu Files</button>
        <DoItForMeModal isModalOpen={this.state.pdfModal} modalToggle={this.pdfModalToggle} doItForMe={doItForMe} orderAndPay={orderAndPay}/>
        <AddVenueModal isModalOpen={this.state.addVenueModal} modalToggle={this.addVenueModalToggle} enterprise={enterprise} />
        <UpdateModal />
        <EmailModal isModalOpen={this.state.emailModal} modalToggle={this.emailModalToggle} />
        <PromptUpgradeModal />
      </div>
    ) 
  }

}


export default connect(
  function (state) {
    return {
      digitalMenus: state.digitalMenus
    };
  },
  function (dispatch) {
    return {
      digitalMenuActions: bindActionCreators(actions.digitalMenus, dispatch)
    };
  }
)(VenueActionsContainer);