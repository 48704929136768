import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ItemsTableHeader from "./ItemsTableHeader";
import ItemRow from "./ItemRow";
import actions from "../actions";
import _ from "lodash";
import Loader from "./Loader";

class ItemsTable extends Component {
  constructor(props) {
    super(props);
  }

  itemsFilter(items, constraints) {
    return _.filter(items, function (item) {
      for (var key in constraints) {
        var itemKey = item[1][key];

        var shouldFilterOut =
          itemKey === undefined ||
          itemKey === null ||
          !itemKey.toLowerCase().includes(constraints[key].toLowerCase());
        if (key === "menu") {
          shouldFilterOut = !constraints[key].includes(itemKey);
        }
        if ( constraints[key] !== '' && (key === "category" || key === 'subcategory')) {
          shouldFilterOut = itemKey.toLowerCase() !== constraints[key].toLowerCase();
        }

        if (shouldFilterOut) return false;
      }

      return true;
    });
  }

  render() {
    const options = [];
    var menusToInclude = "";
    if (this.props.foodFilter) menusToInclude += " Food ";
    if (this.props.drinksFilter) menusToInclude += " Drinks ";
    if (this.props.extrasFilter) menusToInclude += " Extras ";

    var filteredItems = this.itemsFilter(
      Array.from(this.props.items.entries()),
      {
        name: this.props.searchTerm,
        category: this.props.menuCategory,
        subcategory: this.props.menuSubCategory,
        menu: menusToInclude,
      }
    );

    for (const [index, value] of filteredItems) {
      options.push(<ItemRow id={index} key={index} item={value} />);
    }

    var tableContent = <tbody>{options}</tbody>;

    if (!this.props.itemsLoaded) {
      tableContent = (
        <tbody>
          <tr>
            <td colSpan="100%" style={{ textAlign: "center" }}>
              <div style={{ marginLeft: '50%' }}> <Loader /></div>
            </td>
          </tr>
        </tbody>
      );
    } else if (this.props.items.length === 0) {
      tableContent = (
        <tbody>
          <tr>
            <td colSpan="100%" style={{ textAlign: "center" }}>
              <span>No items just yet. Click New Item to get started</span>
            </td>
          </tr>
        </tbody>
      );
    }

    return (
      <div className="items-table-container">
        <table>
          <ItemsTableHeader />
          {tableContent}
        </table>
      </div>
    );
  }
}

export default connect(
  function (state) {
    return {
      menus: state.menus,
      venue: state.menus.venue,
      items: state.menus.items,
      itemsLoaded: state.menus.itemsLoaded,
      searchTerm: state.menus.searchTerm,
      menuCategory: state.menus.filterMenuCategory,
      menuSubCategory: state.menus.filterMenuSubCategory,
      foodFilter: state.menus.foodFilter,
      drinksFilter: state.menus.drinksFilter,
      extrasFilter: state.menus.extrasFilter,
    };
  },
  function (dispatch) {
    return {
      menuActions: bindActionCreators(actions.menus, dispatch),
    };
  }
)(ItemsTable);
