import { Component } from "react";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "../reducers";
import thunk from "redux-thunk";
import QrOrdersContainer from "./QrOrdersContainer.js";

const store = createStore(rootReducer, compose(applyMiddleware(thunk)));

//store.dispatch(loadPromotionCodes());

class QrOrdersRoot extends Component {
  render() {
    return (
      <Provider store={store}>
        <QrOrdersContainer />
      </Provider>
    );
  }
}

export default QrOrdersRoot;
