import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import actions from "../actions";

class ItemReorderEditor extends Component {
  constructor(props) {
    super(props);
    this.up = this.up.bind(this);
    this.down = this.down.bind(this);
  }

  up(event) {
    this.props.menuActions.changeItemOrder(
      event.currentTarget.id,
      "up"
    );
  }

  down(event) {
    this.props.menuActions.changeItemOrder(
      event.currentTarget.id,
      "down"
    );
  }

  render() {
    return (
      <tr>
        <td>
          {this.props.name}
        </td>
        <td>
          <a id={this.props.id} onClick={this.up}>
            <i className="fa fa-chevron-down"></i>
          </a>
        </td>
        <td>
          <a id={this.props.id} onClick={this.down}>
            <i className="fa fa-chevron-up"></i>
          </a>
        </td>
      </tr>
    );
  }
}

export default connect(
  function (state) {
    return {
      menus: state.menus,
    };
  },
  function (dispatch) {
    return {
      menuActions: bindActionCreators(actions.menus, dispatch),
    };
  }
)(ItemReorderEditor);
