import React, { Component } from "react";

class VenueInfo extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let { venue, url, vdm } = this.props;
    let facebook_url = venue.facebook_url && venue.facebook_url.length >  0 ? venue.facebook_url : "None"
    let instagram_url = venue.instagram_url && venue.instagram_url.length >  0 ? venue.instagram_url : "None"
    let twitter_url = venue.twitter_url && venue.twitter_url.length >  0 ? venue.twitter_url : "None"
    let link = `${url}${vdm.digital_menu_venue_id}`

    const editLink = (
      <span style={{display: "inline-block"}}>
        <a 
          href={`${window.location.origin}/venues/${venue.id}/venue_digital_menus/${vdm.id}/edit`}
          style={{ fontSize: "24px" }}
        >edit</a>
      </span>
    )

    return (
      <div className="panel panel-default">
        <div>
          <h4 style={{marginTop: "30px", textAlign: "center", fontWeight: "bold", fontSize: "24px"}}>Venue Info  ( {editLink} )</h4>
        </div>
        <div className="dm-venue-details-container">
          <div className="dm-venue-logo" >
            <h4 style={{marginTop: "30px", textAlign: "center", fontWeight: "bold "}}>Current Logo</h4>
            <img width="250" height="250" src={venue.barpay_web_logo_image.url} />
          </div>
          <div className="dm-venue-info" >
            <span className="dm-venue-info-span" >
              <a 
                href={facebook_url}
                target="_blank"
                className={
                  facebook_url === "None" ? "dm-social-disabled" : "dm-social-link"
                }
              >
                <i className="fa fa-facebook-square"></i>
              </a>
              <h4 className="dm-social-words">{facebook_url}</h4>
            </span>
            <span className="dm-venue-info-span" >
              <a 
                href={instagram_url}
                target="_blank"
                className={
                  instagram_url === "None" ? "dm-social-disabled" : "dm-social-link"
                }
              >
                <i className="fa fa-instagram"></i>
              </a>
              <h4 className="dm-social-words">{instagram_url}</h4>
            </span>
            <span className="dm-venue-info-span" >
              <a 
                href={twitter_url}
                target="_blank"
                className={
                  twitter_url === "None" ? "dm-social-disabled" : "dm-social-link"
                }
              >
                <i className="fa fa-twitter-square"></i>
              </a>
              <h4 className="dm-social-words">{twitter_url}</h4>
            </span>
            <span className="dm-venue-info-span" >
              <h4>Time Zone: {venue.timezone}</h4>
            </span>
            <span className="dm-venue-info-span" >
              <h4>Menu Views: {vdm.total_views}</h4>
            </span>
            <span className="dm-venue-info-span" >
              <h4>Est. Print Savings: ${(vdm.total_views * .05).toFixed(2)}</h4>
            </span>

          </div>
        </div>
        <div>
        <span className="dm-venue-info-link" >
          <h4 style={{fontSize: "14px", fontWeight: "bold", marginRight: "10px" }}>Active Menu Link: </h4>
          <a
            href={link}
            target="_blank"
          >{link}</a>
          <a onClick={() => {
            navigator.clipboard.writeText(link)
            alert('Link Copied!')
          }}>
            <i className="fa fa-files-o dm-copy"></i>
          </a>
        </span>
        </div>
      </div>
    ) 
  }

}

export default VenueInfo