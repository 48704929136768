import { Component } from "react";

import MenusToggle from "./MenusToggle";
import MenusCategorySelection from "./MenusCategorySelection";

class MenuFilters extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <MenusToggle />
        <MenusCategorySelection />
      </div>
    );
  }
}
export default MenuFilters;
