import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import actions from "../actions";

class ItemRow extends Component {
  constructor(props) {
    super(props);
    this.state = { isChecked: props.item.in_stock };
    this.handleChange = this.handleChange.bind(this);
    this.editItemClick = this.editItemClick.bind(this);
    this.toggleMenuItem = this.toggleMenuItem.bind(this);
  }

  handleChange(event) {
    this.setState({ isChecked: event.target.checked });
    this.props.menuActions.toggleInStock(
      this.props.item.item_id,
      event.target.checked
    );
  }

  editItemClick(event) {
    if (
      event.target !== undefined &&
      event.target.getAttribute("class") === null
    ) {
      this.props.menuActions.openEditItem(event.currentTarget.id);
    }
  }

  toggleMenuItem() {
    this.props.menuActions.toggleMenuItem(this.props.item);
  }

  render() {
    let image = <img width="75" height="40" src={this.props.item.image} />;
    if (this.props.item.image === undefined || this.props.item.image === null) {
      image = undefined;
    }

    return (
      <tr
        key={this.props.id}
        id={this.props.item.id}
        onClick={this.editItemClick}
      >
        <td><input className="ignoreRowClick" type="checkbox" onChange={this.toggleMenuItem} checked={
          this.props.item !== undefined
            ? this.props.item.selected
            : false} /></td>
        <td>
          <a>{this.props.item.name}</a>
        </td>
        <td>{this.props.item.price}</td>
        <td>{this.props.item.menu}</td>
        <td>{this.props.item.category}</td>
        <td>{this.props.item.subcategory}</td>
        <td>{image}</td>
        <td>
          <input
            className="tgl tgl-light"
            id={"toggle-item-" + this.props.id}
            data-venue="1"
            type="checkbox"
            name={"toggle-item-" + this.props.id}
            value={this.state.value}
            onChange={this.handleChange}
            checked={this.state.isChecked}
          ></input>
          <label
            className="tgl-btn"
            htmlFor={"toggle-item-" + this.props.id}
          ></label>
        </td>
      </tr>
    );
  }
}

export default connect(
  function (state) {
    return {
      menus: state.menus,
    };
  },
  function (dispatch) {
    return {
      menuActions: bindActionCreators(actions.menus, dispatch),
    };
  }
)(ItemRow);
