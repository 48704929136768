import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import actions from "../actions";
import Datetime from "react-datetime";
import Loader from "../menus/Loader";
import { confirmAlert } from "react-confirm-alert";
import FileUploader from "./FileUploader";
import moment from "moment-timezone"
import PromptUpgradeModal from "./PromptUpgradeModal";

const initialState = {
  currentMenu: {
    id: undefined,
    value: "",
    label: "Please enter menu name",
    starttime: undefined, 
    endtime: undefined, 
    sunday: true, 
    monday: true, 
    tuesday: true, 
    wednesday: true,
    thursday: true, 
    friday: true, 
    saturday: true,
    menu: '',
    useLink: false,
    link: '',
  },
  errors: {
    name: "",
    save: "",
    menuSaveError: "",
    menuDeleteError: "",
  },
  saving: false,
  venueId: undefined,
  allDay: true,
  menuUpdate: false
}

class DigitalMenuEditor extends Component {
  constructor(props) {
    super(props);
    this.onInputClick = this.onInputClick.bind(this);
    this.onInputBlur = this.onInputBlur.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.saveDigitalMenu = this.saveDigitalMenu.bind(this);
    this.deleteDigitalMenu = this.deleteDigitalMenu.bind(this);
    this.onAllDayCheckbox = this.onAllDayCheckbox.bind(this);
    this.onMenuDayCheckbox = this.onMenuDayCheckbox.bind(this);
    this.promptModalToggle = this.promptModalToggle.bind(this);
    this.onUseLinkCheckbox = this.onUseLinkCheckbox.bind(this);
    this.state = {
      currentMenu: {
        id: undefined,
        value: "",
        label: "Please enter menu name",
        starttime: undefined, 
        endtime: undefined, 
        sunday: true, 
        monday: true, 
        tuesday: true, 
        wednesday: true,
        thursday: true, 
        friday: true, 
        saturday: true,
        menu: '',
        useLink: false,
        link: '',
      },
      errors: {
        name: "",
        save: "",
        menuSaveError: "",
        menuDeleteError: "",
      },
      saving: false,
      venueId: undefined,
      allDay: true,
      menuUpdate: false
    };
  }

  promptModalToggle () {
    let { digitalMenuActions } = this.props;
    digitalMenuActions.togglePromptUpgradeModal("schedule")
  }

  onInputClick(event, name) {
    let currentMenu = this.state.currentMenu;
    if (event.target.value === initialState.currentMenu[name]) {
      currentMenu[name] = ""
      this.setState({...this.state, currentMenu});
    }
  }

  onInputBlur(event, name, id = 0) {
    let errors = this.state.errors;
    const value = event.target.value;
    switch (name) {
      case "label":
        errors.name = value.length === 0 ? "Menu Name must be filled out" : "";
        break;
    }
    this.setState({ ...this.state, errors });
  }

  handleInputChange(event, name) {
    let currentMenu = this.state.currentMenu;
    currentMenu[name] = event.target.value;
    this.setState({ ...this.state, currentMenu });
  }

  onAllDayCheckbox() {
    const { digitalMenus } = this.props;
    const venue = digitalMenus.data["venue_data"]
    const timeZone = venue.timezone
    let allDay = this.state.allDay;
    let currentMenu = this.state.currentMenu;
    allDay = !allDay;
    currentMenu.starttime = moment.tz(timeZone).startOf('day')
    currentMenu.endtime = moment.tz(timeZone).startOf('day')
    this.setState({ ...this.state, allDay: allDay, currentMenu: currentMenu });
  }

  onUseLinkCheckbox() {
    let currentMenu = this.state.currentMenu;
    let check = currentMenu.useLink;
    currentMenu.useLink = !check;
    currentMenu.link = "";
    this.setState({ ...this.state, currentMenu: currentMenu });
  }

  onMenuDayCheckbox(day) {
    let currentMenu = this.state.currentMenu;
    currentMenu[day] = !currentMenu[day];
    this.setState({ ...this.state, currentMenu });
  }

  onTimeChange(type, time) {
    let currentMenu = this.state.currentMenu;
    if (type === "start") {
      currentMenu.starttime = time;
    } else {
      currentMenu.endtime = time;
    }
    this.setState({
      ...this.state,
      currentMenu
    });
  }

  componentDidMount() {
    const { digitalMenus, venueId } = this.props;
    const venue = digitalMenus.data["venue_data"]
    const timeZone = venue.timezone
    let currentMenu = this.state.currentMenu;
    let allDay = this.state.allDay;

    if (digitalMenus.currentMenu.id) {
      currentMenu = digitalMenus.currentMenu;
      let startTime = currentMenu.starttime;
      let endTime = currentMenu.endtime;
      if (!moment.isMoment(startTime) && !moment.isMoment(endTime)) {
        if ( startTime || endTime ) {
          allDay = startTime === endTime
          let start = moment(startTime * 1000)
          let end = moment(endTime * 1000)
          currentMenu.starttime = start.tz(timeZone)
          currentMenu.endtime = end.tz(timeZone)
        } else {
          currentMenu.starttime = moment.tz(timeZone).startOf('day')
          currentMenu.endtime = moment.tz(timeZone).startOf('day')
        }
      } else {
        allDay = startTime["_i"] === endTime["_i"]
      }

      if (digitalMenus.currentMenu.link.length > 0) {
        currentMenu.useLink = true;
      }
      this.setState({ ...this.state, currentMenu: currentMenu, venueId: venueId, allDay: allDay })
    } else {
      currentMenu.starttime = moment.tz(timeZone).startOf('day')
      currentMenu.endtime = moment.tz(timeZone).startOf('day')
      this.setState({ ...this.state, currentMenu: currentMenu, venueId: venueId, allDay: true })
    }


  }

  componentWillUnmount() {
    const { digitalMenuActions } = this.props;
    digitalMenuActions.clearCurrentMenu()
  }

  validateForm() {
    return (
      this.state.currentMenu.label.length > 0 &&
      this.state.currentMenu.label !== initialState.currentMenu.label
    );
  }

  closeModal() {
    this.props.digitalMenuActions.toggleDigitalMenuModal()
  }

  saveDigitalMenu() {
    let data = this.state;
    if (this.validateForm()) {
      data.currentMenu.starttime = data.currentMenu.starttime.format()
      data.currentMenu.endtime = data.currentMenu.endtime.format()
      this.setState(
        { ...this.state, saving: true },
        function () {
          this.props.digitalMenuActions.updateDigitalMenu(
            data,
            function () {
              this.setState(initialState);
            }.bind(this)
          );
        }
      );
    } else {
      let errors = this.state.errors;
      errors.save = "Please make sure to fill out the required fields";
      this.setState({ errors });
    }
  }
  
  deleteDigitalMenu() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="confirm-delete-item">
            <h2>Are you sure you want to delete this menu?</h2>
            <button onClick={onClose}>No</button>
            <button
              onClick={() => {
                this.props.digitalMenuActions.deleteDigitalMenu(
                  this.state.currentMenu
                );
                this.setState(initialState)
                this.closeModal()
                onClose();
              }}
            >
              Yes
            </button>
          </div>
        );
      },
    });
  }

  updateMenuInState(menu) {
    let currentMenu = this.state.currentMenu;
    currentMenu.menu = menu
    this.setState({ ...this.state, currentMenu: currentMenu, menuUpdate: true });
  }

  render() {
    const { digitalMenus } = this.props;
    const { errors, currentMenu, allDay } = this.state;
    const venueData = digitalMenus.data
    const venue = venueData["venue_data"]
    const timeZone = venue.timezone ? venue.timezone : null
    const orderAndPay  = venueData["order_and_pay"]
    let filePreviewUrl = '';
    let pdfUploadMessage = "Please upload your menu PDF."
    const days = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"]
    let product = 1
    let schedulingCheck = false

    if (currentMenu.menu) {
      filePreviewUrl = currentMenu.menu.url
    }

    if (digitalMenus && digitalMenus.data) {
      product = digitalMenus.data.product
      schedulingCheck = orderAndPay || (product > 2)
    }

    let saveButton = <button onClick={this.saveDigitalMenu}>Save</button>;
    if (this.state.saving) {
      saveButton = <Loader />;
    }

    let deleteButton = (
      <button className="delete-button" onClick={() => this.deleteDigitalMenu()}>
        Delete
      </button>
    );

    return (
      <div 
        className="schedule-modal item-modal dm-editor"
        style={{ height: '775px' }}
      >
        <div className="row">
          <div className="column">
            <label htmlFor="label">Menu Name</label>
            <input 
              id="label"
              name="label"
              type="text"
              value={currentMenu.label || ''}
              onClick={(event) => this.onInputClick(event, "label")}
              onBlur={(event) => this.onInputBlur(event, "label")}
              onChange={(event) => this.handleInputChange(event, "label")}
            />
            {errors.name !== undefined && errors.name.length > 0 && (
              <span className="error">{errors.name}</span>
            )}
          </div>
        </div>
        { !schedulingCheck ? (
          <div className="dm-upgrade-link">
            <a className="" onClick={this.promptModalToggle}>Activate Schedules</a>
          </div>
        ) : (
          <div>
            <div className="row">
              <div className="dm-time">
                <div>
                  <div className="dm-checkbox">
                    <label>All Day</label>
                    <input
                      type="checkbox"
                      id="allDay"
                      name="allDay"
                      className="dm-checkbox-input"
                      value={allDay || "false"}
                      checked={allDay}
                      onChange={() => this.onAllDayCheckbox()}
                      title="Schedule active all day"
                      disabled={!schedulingCheck}
                    />
                  </div>
                </div>
                <div>
                  <label htmlFor="label">Start Time</label>
                  <Datetime
                    dateFormat={false}
                    onChange={(time) => this.onTimeChange("start", time)}
                    inputProps={{
                      className: allDay ? "schedule-time-picker-disabled dm-datetime-disabled"
                                        : "schedule-time-picker",
                      disabled: allDay || !schedulingCheck,
                    }}
                    value={currentMenu.starttime}
                    displayTimeZone={timeZone}
                    // disabled={allDay}
                  />
                </div>
                <div>
                  <label htmlFor="label">End Time</label>
                  <Datetime
                    dateFormat={false}
                    onChange={(time) => this.onTimeChange("end", time)}
                    inputProps={{
                      className: allDay ? "schedule-time-picker-disabled dm-datetime-disabled"
                                        : "schedule-time-picker",
                      disabled: allDay || !schedulingCheck,
                    }}
                    value={currentMenu.endtime}
                    displayTimeZone={timeZone}
                    // disabled={allDay}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="dm-schedule-container">
              {days.map((day, index) => (
                  <div className="dm-checkbox" key={index}>
                    <label>{day.slice(0, 3).toUpperCase()}</label>
                    <input
                      type="checkbox"
                      id={day}
                      name={day}
                      className="dm-checkbox-input"
                      value={currentMenu[day] || false}
                      checked={currentMenu[day] || false}
                      onChange={() => this.onMenuDayCheckbox(day)}
                      title={`Schedule active on ${day}`}
                      disabled={!schedulingCheck}
                    />
                  </div>
              ))}
              </div>
            </div>
          </div>
        )}
        { !currentMenu.useLink && (
            <div className="row">
              <div className="column">
                <FileUploader filePreviewUrl={filePreviewUrl} message={pdfUploadMessage} update={(file) => this.updateMenuInState(file)} />
              </div>
            </div>
        )}
        <div className="row">
          <div className="column">
            <div className="dm-checkbox">
              <label>Use URL</label>
              <input
                type="checkbox"
                id="useLink"
                name="useLink"
                className="dm-checkbox-input"
                value={currentMenu.useLink || "false"}
                checked={currentMenu.useLink}
                onChange={() => this.onUseLinkCheckbox()}
                title="Use URL in place of PDF"
              />
            </div>
          </div>
          { currentMenu.useLink && (
              <div className="column">
                <label htmlFor="label">URL will be used in place of PDF</label>
                <input 
                  id="link"
                  name="link"
                  type="text"
                  value={currentMenu.link || ''}
                  onChange={(event) => this.handleInputChange(event, "link")}
                />
              </div>
          )}
        </div>
        <div className="row">
            <div className="schedule-modal-save">{saveButton}</div>
            { this.state.currentMenu.id !== undefined && <div>{deleteButton}</div> }
          </div>
          {/* errors */}
          <div className="row"> 
            {errors.save.length > 0 && (
              <span className="error">{errors.save}</span>
            )}
            {errors.menuSaveError.length > 0 && (
              <span className="error">{errors.menuSaveError}</span>
            )}
            {errors.menuDeleteError.length > 0 && (
              <span className="error">{errors.menuDeleteError}</span>
            )}
          </div>
          <div className="row">
            <div className="schedule-modal-cancel">
              <button onClick={this.closeModal}>Cancel</button>
            </div>
          </div>
          <PromptUpgradeModal />
      </div>
    )
  }
};


export default connect (
  function (state) {
    return {
      digitalMenus: state.digitalMenus,
      venueId: state.digitalMenus.data["venue_dm"]["venue_id"]
    };
  },
  function (dispatch) {
    return {
      digitalMenuActions: bindActionCreators(actions.digitalMenus, dispatch)
    };
  }
)(DigitalMenuEditor);