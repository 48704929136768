import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import actions from "../actions";
import { Collapse } from "react-bootstrap";
import ChangePaymentCheckout from "./ChangePaymentCheckout";

class UpdatePaymentPanel extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.digitalMenuActions.getStripeInfo()
  }

  componentWillUnmount() {
    this.props.digitalMenuActions.clearStripeInfo()
    this.props.digitalMenuActions.clearPaymentSetup()
  }
  
  render() {
    let { open, setOpen, clearOpen } = this.props;

    const button = (open ? (
      <a
        className="dm-tab-view-link"
        onClick={() => clearOpen()}
        href={"#"}
        data-toggle="collapse"
      >Update <i className="fa fa-chevron-down"></i></a>
    ) : (
      <a
        className="dm-tab-view-link"
        onClick={() => setOpen()}
        href={"#"}
        data-toggle="collapse"
      >Update <i className="fa fa-chevron-right"></i></a>
    ))
      
    return (
      <div className="panel panel-default dm-menu-panel">
        <div className="dm-table">
          <div className="dm-update-info">
            <div style={{padding: "10px"}}>
              <h1 className="dm-tab-name">Default Payment Method</h1>
            </div>
            <div style={{padding: "10px"}}>
              {button}
            </div>
          </div>
          <div>
          </div>
          <Collapse in={open} unmountOnExit={true} >  
            <div className="payment-change-container">
              <ChangePaymentCheckout clearOpen={clearOpen}/>
            </div>       
          </Collapse>
        </div>
      </div>
    ) 
  }
}

export default connect (
  function (state) {
    return {
      digitalMenus: state.digitalMenus
    };
  },
  function (dispatch) {
    return {
      digitalMenuActions: bindActionCreators(actions.digitalMenus, dispatch)
    };
  }
)(UpdatePaymentPanel);