import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import actions from "../actions";
import Loader from "../menus/Loader";

class QrImage extends Component {
  constructor(props) {
    super(props);
    this.getQr = this.getQr.bind(this);
    this.generateQr = this.generateQr.bind(this);
    this.pollQr = this.pollQr.bind(this);
    this.state = {
      generating: false
    }
  }

  pollQr() {
    let { digitalMenuActions } = this.props;
    let endTime = Number(new Date()) + (900000);
    let interval = 2000;
    const clearState = () => {
      this.setState({ ...this.state, generating: false })
    }
  
    const checkCondition = async function (resolve, reject) {
      let check = await digitalMenuActions.pollQr();
      if (check) {
        clearState()
        digitalMenuActions.loadDigitalMenus()
        resolve();
      } else if (Number(new Date()) < endTime) {
        setTimeout(checkCondition, interval, resolve, reject);
      } else {
        reject(new Error("timed out: " + arguments));
      }
    };
    return new Promise(checkCondition);
  }
  

  generateQr() {
    let { digitalMenuActions } = this.props;
    let { id } = this.props.digitalMenus.data.venue_data;
    this.setState({ generating: true })
    digitalMenuActions.generateQr(id, 
        function () {
          this.pollQr()
        }.bind(this)
    );
  }

  getQr() {
    let {url} = this.props;

    if (this.state.generating) {
      return <Loader />
    } else if (url) {
      return (  <div>
                  <h4 style={{marginTop: "20px", textAlign: "center", fontWeight: "bold", fontSize: "24px"}}>QR Code</h4>
                  <a 
                    href={url} 
                    target="_blank"
                  ><img width="300" height="300" src={url} /></a>
                </div> )
    } else {
      return (  <div>
                  <button className="btn btn-primary add-menu-button" onClick={this.generateQr}>Generate QR</button>
                </div> )
    }
  }

  render() {
    let qrDisplay = this.getQr()

    return (
      <div className="panel panel-default dm-qr-panel">
        {qrDisplay}
      </div>
    ) 
  }

}

export default connect(
  function (state) {
    return {
      digitalMenus: state.digitalMenus
    };
  },
  function (dispatch) {
    return {
      digitalMenuActions: bindActionCreators(actions.digitalMenus, dispatch)
    };
  }
)(QrImage);