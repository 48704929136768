import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import actions from "../actions";
import Modal from "react-modal";
import DoItForMeCheckout from "./DoItForMeCheckout";
import DisplayPdfContainer from "./DisplayPdfContainer";

const customStyles = {
  content: {
    height: "75%",
    top: "30%",
    right: "auto",
    bottom: "auto",
    border: "none",
    borderRadius: "10px",
    transform: "translate(-30%, -20%)",
  },
  overlay: {
    backgroundColor: "rgba(50, 62, 72, 0.5)",
  },
};

class DoItForMeModal extends Component {
  constructor(props) {
    super(props);
  }


  render() {
    let {isModalOpen, modalToggle, doItForMe, orderAndPay} = this.props;

    return (
      <Modal
        isOpen={isModalOpen}
        onRequestClose={modalToggle}
        style={customStyles}
        contentLabel="Print Ready PDF Modal"
        ariaHideApp={false}
        shouldCloseOnOverlayClick={false}
      >
        <div className="schedule-modal dm-pdf-modal">
          <div 
            className="schedule-modal-header"
            style={{display: "flex", justifyContent: "space-between" }}
          >
            <h3 style={{display: "inline-block"}}>Print Ready PDF Files</h3>
            <button 
              className="schedule-close-button" 
              onClick={modalToggle}
              style={{display: "flex", position: "static"}}
            >
              <h3 style={{display: "inline-block"}}>X</h3>
            </button>
          </div>
          { doItForMe || orderAndPay ? (
            <DisplayPdfContainer />
          ) : (
            <div>
              <div>
                <h4 style={{marginTop: "30px", textAlign: "center", fontWeight: "bold", fontSize: "24px"}}>One Time Payment of $10 Includes:</h4>
                <p>- 8.5 x 11 Window Display PDF</p>
                <p>- 5 x 7 Table Tent Display PDF</p>
                <p>- 4 x 6 Table Tent Display PDF</p>
                <p>- 2 x 2 Table Sticker Display PDF</p>
              </div>
              <div>
                <DoItForMeCheckout modalToggle={modalToggle}/>
              </div>
            </div>
          )}
        </div>
      </Modal>
    );
  }
};


export default connect(
  function (state) {
    return {
      digitalMenus: state.digitalMenus
    };
  },
  function (dispatch) {
    return {
      digitalMenuActions: bindActionCreators(actions.digitalMenus, dispatch)
    };
  }
)(DoItForMeModal);