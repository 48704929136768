import React, { Component } from "react";
import { Card } from "react-bootstrap";

class PremiumCard extends Component {
  constructor(props) {
    super(props);
    this.onButtonClick = this.onButtonClick.bind(this);
  }

  onButtonClick() {
    this.props.selectProduct(3)
  }

  render() {
    let { currentProduct, isMonthly } = this.props;
    let active = currentProduct === 3

    return (
        <Card 
          onClick={this.onButtonClick}
          className={ active ? "selection-card-active" : "selection-card" }
        >
          <Card.Header className="card-header">
            <Card.Text className="bold">Premium Plan</Card.Text>
            <h3 className="price">${ isMonthly ? "59" : "649"}</h3>
            <Card.Text>{ isMonthly ? "per month" : "per year"}</Card.Text>
          </Card.Header>
          <Card.Body className="card-body">
            <Card.Text className="bold">- Everything in Standard</Card.Text>
            <Card.Text>- Menu Scheduling</Card.Text>
            <Card.Text>- Collect Customer Emails</Card.Text>
            <Card.Text>- Priority Support</Card.Text>
          </Card.Body>
        </Card>
    )
  }
};


export default PremiumCard;