import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import actions from "../actions";
import Loader from "./Loader";

const SAVE_ERROR_MESSAGE = "You must enter a Set to amount, a dollar increase amount, a percentage increase amount, an availability change, or a printer change. You can not select more than one.";

class BulkEditMenu extends Component {
  constructor(props) {
    super(props);
    this.printerOptions = props.printers.map((printer) => <option key={printer.id} value={printer.id}>{printer.name.charAt(0).toUpperCase() + printer.name.slice(1)}</option>);
    this.printerOptions.unshift(<option key='printer-no-change' value='printer-no-change'>No Change</option>)
    this.menuOptions = props.schedules.map((sked) => <option key={sked.id} value={sked.id}>{sked.name}</option>);
    this.menuOptions.push(<option key='all' value='all'>All Menus</option>)

    this.closeModal = this.closeModal.bind(this);
    this.save = this.save.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      flatRateAmount: "",
      dollarIncrease: "increase",
      percentIncrease: "increase",
      dollarIncreaseAmount: "",
      percentIncreaseAmount: "",
      menuToApply: props.schedules[0].id.toString(),
      printer: "printer-no-change",
      availability: "nochange",
      instock: "nochange",
      errors: {
        flatRateAmount: "",
        dollarIncreaseAmount: "",
        percentIncreaseAmount: "",
        save: "",
      },
    };
  }

  handleChange(event, name) {
    let localState = this.state;
    localState[name] = event.target.value;
    this.setState(localState);
    this.onInputBlur(event, name);

    if (name !== "dollarIncrease" && name !== "percentIncrease" && name !== "menuToApply") {
      let errors = this.state.errors;
      errors.save = this.validateForm() ? "" : SAVE_ERROR_MESSAGE;
      this.setState({ errors });
    }
  }

  onInputBlur(event, name, id = 0) {
    const priceRegex = RegExp(/^\$?[0-9]+(\.[0-9][0-9])?$/);
    const numberRegex = RegExp(/^\d+$/);
    let errors = this.state.errors;
    const value = event.target.value;

    switch (name) {
      case "flatRateAmount":
        errors.flatRateAmount = value === undefined || value === '' || priceRegex.test(value)
          ? ""
          : "Set amount must match USD format like $5.00";
        break;
      case "dollarIncreaseAmount":
        errors.dollarIncreaseAmount = value === undefined || value === '' || priceRegex.test(value)
          ? ""
          : "Dollar increase amount must match USD format like $5.00";
        break;
      case "percentIncreaseAmount":
        errors.percentIncreaseAmount = value === undefined || value === '' || numberRegex.test(value)
          ? ""
          : "Percent increase amount must be a whole number.";
        break;
    }
    this.setState({ errors: errors });
  }

  validateForm() {
    const { errors } = this.state;
    const hasError = (errors.flatRateAmount !== undefined && errors.flatRateAmount.length > 0) ||
      (errors.dollarIncreaseAmount !== undefined && errors.dollarIncreaseAmount.length > 0) ||
      (errors.percentIncreaseAmount !== undefined && errors.percentIncreaseAmount.length > 0);

    const hasSetToAmount = (this.state.flatRateAmount !== undefined && this.state.flatRateAmount.length > 0 ? 1 : 0);
    const hasDollarIncreaseAmount = (this.state.dollarIncreaseAmount !== undefined && this.state.dollarIncreaseAmount.length > 0 ? 1 : 0);
    const hasPercentageIncreaseAmount = (this.state.percentIncreaseAmount !== undefined && this.state.percentIncreaseAmount.length > 0 ? 1 : 0);
    const hasPrinterSet = (this.state.printer !== undefined && this.state.printer !== "printer-no-change" ? 1 : 0);
    const numberOfFieldsFilledOut = hasSetToAmount + hasDollarIncreaseAmount + hasPercentageIncreaseAmount + hasPrinterSet;

    return (this.state.instock !== "nochange" && numberOfFieldsFilledOut <= 1 && !hasError) || (this.state.availability !== "nochange" && numberOfFieldsFilledOut <= 1 && !hasError) || (numberOfFieldsFilledOut === 1 && !hasError);
  }

  save() {
    if (this.validateForm()) {
      const selectedItems = this.props.items.filter((item) => item.selected).map((item) => { return { "id": item.id }; });
      this.props.menuActions.bulkEditItems(selectedItems, this.state);
    } else {
      let errors = this.state.errors;
      errors.save = this.validateForm() ? "" : SAVE_ERROR_MESSAGE;
      this.setState({ errors });
    }
  }

  closeModal() {
    this.props.menuActions.toggleBulkEditModal();
  }

  render() {
    const { errors } = this.state;
    const menuOptions = this.menuOptions;
    const printerOptions = this.printerOptions;
    let saveButton = <input type="button" value="Update" onClick={this.save} />;
    let cancelButton = <input type="button" value="Cancel" onClick={this.closeModal} />;

    if (this.props.bulkSaving) {
      saveButton = <div className="bulkLoader"><Loader /></div>;
      cancelButton = <div />
    }

    if (this.props.bulkEditError && this.props.bulkEditError.length > 0) {
      errors.save = this.props.bulkEditError;
    }

    return <div className="item-bulk-update-menu">
      <div className="set">
        <div className="bulk-edit-label">
          <h5>Set To</h5>
        </div>
        <div className="bulk-edit-action">
          <div className="bulk-edit-prefix">$</div>
          <input type="textbox" value={this.state.flatRateAmount} onBlur={(event) => this.onInputBlur(event, "flatRateAmount")} onChange={(event) => this.handleChange(event, "flatRateAmount")} />
        </div>
      </div>
      <div className="bulk-edit-error">{errors.flatRateAmount !== undefined && errors.flatRateAmount.length > 0 && (
        <span className="error">{errors.flatRateAmount}</span>
      )}</div>

      <div className="relative-dollar">
        <div className="bulk-edit-label">
          <div className="selection-container">
            <select value={this.state.dollarIncrease} onChange={(event) => this.handleChange(event, "dollarIncrease")}>
              <option value="increase" default="default">Increase By</option>
              <option value="decrease">Decrease By</option>
            </select>
            <div id="comp-kcbgydc6arrow" className="select-arrow"><div className="svg_container"><svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.2828 4.89817" id="comp-kcbgydc6icon" className="style-kcbgz2ificon"><defs></defs><title>arrow&amp;amp;v</title><path d="M4.64116,4.89817a.5001.5001,0,0,1-.34277-.13574L.15727.86448A.50018.50018,0,0,1,.84282.136L4.64116,3.71165,8.44.136a.50018.50018,0,0,1,.68555.72852L4.98393,4.76243A.5001.5001,0,0,1,4.64116,4.89817Z" className="style-kcbgz2if_cls-1"></path></svg></div></div>
          </div>
        </div>
        <div className="bulk-edit-action">
          <div className="bulk-edit-prefix">$</div>
          <input type="textbox" value={this.state.dollarIncreaseAmount} onBlur={(event) => this.onInputBlur(event, "dollarIncreaseAmount")} onChange={(event) => this.handleChange(event, "dollarIncreaseAmount")} />
        </div>
      </div>
      <div className="bulk-edit-error">{errors.dollarIncreaseAmount !== undefined && errors.dollarIncreaseAmount.length > 0 && (
        <span className="error">{errors.dollarIncreaseAmount}</span>
      )}</div>

      <div className="relative-percent">
        <div className="bulk-edit-label">
          <div className="selection-container">
            <select value={this.state.percentIncrease} onChange={(event) => this.handleChange(event, "percentIncrease")}>
              <option value="increase" default="default">Increase By</option>
              <option value="decrease">Decrease By</option>
            </select>
            <div id="comp-kcbgydc6arrow" className="select-arrow"><div className="svg_container"><svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.2828 4.89817" id="comp-kcbgydc6icon" className="style-kcbgz2ificon"><defs></defs><title>arrow&amp;amp;v</title><path d="M4.64116,4.89817a.5001.5001,0,0,1-.34277-.13574L.15727.86448A.50018.50018,0,0,1,.84282.136L4.64116,3.71165,8.44.136a.50018.50018,0,0,1,.68555.72852L4.98393,4.76243A.5001.5001,0,0,1,4.64116,4.89817Z" className="style-kcbgz2if_cls-1"></path></svg></div></div>
          </div>
        </div>
        <div className="bulk-edit-action">
          <input type="textbox" value={this.state.percentIncreaseAmount} onBlur={(event) => this.onInputBlur(event, "percentIncreaseAmount")} onChange={(event) => this.handleChange(event, "percentIncreaseAmount")} />
          <div className="bulk-edit-prefix">%</div>
        </div>
      </div>
      <div className="bulk-edit-error">{errors.percentIncreaseAmount !== undefined && errors.percentIncreaseAmount.length > 0 && (
        <span className="error">{errors.percentIncreaseAmount}</span>
      )}</div>
      <div className="set">
        <div className="bulk-edit-label">
          <h5>Printer</h5>
        </div>
        <div className="bulk-edit-action">
          <div className="selection-container">
            <select className="selection-container-small" value={this.state.printer} onChange={(event) => this.handleChange(event, "printer")}>
              {printerOptions}
            </select>
            <div id="comp-kcbgydc6arrow" className="select-arrow"><div className="svg_container"><svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.2828 4.89817" id="comp-kcbgydc6icon" className="style-kcbgz2ificon"><defs></defs><title>arrow&amp;amp;v</title><path d="M4.64116,4.89817a.5001.5001,0,0,1-.34277-.13574L.15727.86448A.50018.50018,0,0,1,.84282.136L4.64116,3.71165,8.44.136a.50018.50018,0,0,1,.68555.72852L4.98393,4.76243A.5001.5001,0,0,1,4.64116,4.89817Z" className="style-kcbgz2if_cls-1"></path></svg></div></div>
          </div>
        </div>
      </div>

      <hr />

      <div className="selection-label">
        <h5>Apply Changes To Which Menu?</h5>
      </div>
      <div className="selection">
        <div className="selection-container">
          <select value={this.state.menuToApply} onChange={(event) => this.handleChange(event, "menuToApply")}>
            {menuOptions}
          </select>
          <div id="comp-kcbgydc6arrow" className="select-arrow"><div className="svg_container"><svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.2828 4.89817" id="comp-kcbgydc6icon" className="style-kcbgz2ificon"><defs></defs><title>arrow&amp;amp;v</title><path d="M4.64116,4.89817a.5001.5001,0,0,1-.34277-.13574L.15727.86448A.50018.50018,0,0,1,.84282.136L4.64116,3.71165,8.44.136a.50018.50018,0,0,1,.68555.72852L4.98393,4.76243A.5001.5001,0,0,1,4.64116,4.89817Z" className="style-kcbgz2if_cls-1"></path></svg></div></div>
        </div>
      </div>
      <div className="selection-label">
        <h5>Menu Availability?</h5>
      </div>
      <div className="selection">
        <div className="selection-container">
          <select value={this.state.availability} onChange={(event) => this.handleChange(event, "availability")}>
            <option value="nochange" default="default">No Change</option>
            <option value="setavailable">Available</option>
            <option value="setnotavailable">Unavailable</option>
          </select>
          <div id="comp-kcbgydc6arrow" className="select-arrow"><div className="svg_container"><svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.2828 4.89817" id="comp-kcbgydc6icon" className="style-kcbgz2ificon"><defs></defs><title>arrow&amp;amp;v</title><path d="M4.64116,4.89817a.5001.5001,0,0,1-.34277-.13574L.15727.86448A.50018.50018,0,0,1,.84282.136L4.64116,3.71165,8.44.136a.50018.50018,0,0,1,.68555.72852L4.98393,4.76243A.5001.5001,0,0,1,4.64116,4.89817Z" className="style-kcbgz2if_cls-1"></path></svg></div></div>
        </div>
      </div>
      <div className="selection-label">
        <h5>Item Availability?</h5>
      </div>
      <div className="selection">
        <div className="selection-container">
          <select value={this.state.instock} onChange={(event) => this.handleChange(event, "instock")}>
            <option value="nochange" default="default">No Change</option>
            <option value="instock">In Stock</option>
            <option value="outofstock">Out of Stock</option>
          </select>
          <div id="comp-kcbgydc6arrow" className="select-arrow"><div className="svg_container"><svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.2828 4.89817" id="comp-kcbgydc6icon" className="style-kcbgz2ificon"><defs></defs><title>arrow&amp;amp;v</title><path d="M4.64116,4.89817a.5001.5001,0,0,1-.34277-.13574L.15727.86448A.50018.50018,0,0,1,.84282.136L4.64116,3.71165,8.44.136a.50018.50018,0,0,1,.68555.72852L4.98393,4.76243A.5001.5001,0,0,1,4.64116,4.89817Z" className="style-kcbgz2if_cls-1"></path></svg></div></div>
        </div>
      </div>
      <div className="action-update">
        {saveButton}
      </div>
      <div className="bulk-edit-error">
        {errors.save !== undefined && errors.save.length > 0 && (
          <span className="error">{errors.save}</span>
        )}
      </div>
      <div className="action-cancel">
        {cancelButton}
      </div>
    </div>;
  }
}
export default connect(
  function (state) {
    return {
      schedules: state.menus.schedules,
      printers: state.menus.printers,
      items: state.menus.items,
      bulkEditError: state.menus.bulkEditError,
      bulkSaving: state.menus.bulkSaving,
    };
  },
  function (dispatch) {
    return {
      menuActions: bindActionCreators(actions.menus, dispatch),
    };
  }
)(BulkEditMenu);
