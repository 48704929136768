import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import actions from "../actions";
import Tab from "./Tab";
import UserFastPass from "./UserFastPass";
import _ from "lodash";
import consumer from "../../cable";

import { Button } from "react-bootstrap";

class TabList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.intervalID = 0;
  }

  componentWillUnmount() {
    this.state.tabSub.unsubscribe();
    clearInterval(this.intervalID);
  }

  componentDidMount() {
    let { tabActions } = this.props;
    tabActions.load();

    let tabSub = consumer.subscriptions.create(
      { channel: "TabChannel", venue: window.venue.id },
      {
        received(data) {
          if (data["add"] == true) {
            tabActions.add(data["tab"]);
          } else if (data["update"] == true) {
            tabActions.update(data["tab"]);
          }
        },
      }
    );

    this.setState({ tabSub: tabSub });

    this.intervalID = setInterval(() => {
      tabActions.load(true);
    }, 1000 * 60);
  }

  searchChanged(event) {
    let { tabActions } = this.props;
    tabActions.search(event.target.value);
  }

  render() {
    let { tabs, tabActions } = this.props;
    let fastpasses = tabs.fastpasses;
    let total_fastpasses = fastpasses.reduce((acc, fp) => {
      return acc + fp.quantity
    }, 0)
    let tabData = _.filter(tabs.data, (tab) => {
      if (tabs.filter == "open") {
        return tab.closed_at == null && tab.declined == false;
      } else if (tabs.filter == "declined") {
        return tab.declined == true && tab.tab_type !== "WebTab";
      } else if (tabs.filter == "not complete") {
        return !tab.mark_tab_complete;
      } else if (tabs.filter == "complete") {
        return tab.mark_tab_complete;
      } else {
        return tab.closed_at != null;
      }
    });

    if (tabs.query.length > 1) {
      tabData = _.filter(tabData, (tab) => {
        return (
          tab.customer_name.toLowerCase().indexOf(tabs.query.toLowerCase()) >
          -1 ||
          (tab.table_number !== null &&
            tab.table_number !== undefined &&
            (tab.table_number.toLowerCase().indexOf(tabs.query.toLowerCase()) >
              -1 ||
              `table #${tab.table_number.toLowerCase()}`.indexOf(
                tabs.query.toLowerCase()
              ) > -1))
        );
      });
    }

    if (tabs.loading) return <div className="loader"></div>;

    let open_count = _.filter(
      tabs.data,
      (tab) => tab.closed_at == null && tab.declined == false
    ).length;
    let declined_count = _.filter(
      tabs.data,
      (tab) => tab.declined == true && tab.tab_type !== "WebTab"
    ).length;
    let closed_count = _.filter(tabs.data, (tab) => tab.closed_at != null)
      .length;
    let complete_count = _.filter(tabs.data, (tab) => tab.mark_tab_complete)
      .length;
    let not_complete_count = _.filter(tabs.data, (tab) => !tab.mark_tab_complete)
      .length;

    return (
      <div className="inner-self-scroll">
        <div className="row" style={{ marginBottom: 20 }}>
          <div className="col-md-8">
            <div className="btn-group tabs-toggle">
              <Button
                bsstyle="primary"
                active={tabs.filter == "open"}
                onClick={() => tabActions.filter("open")}
              >
                OPEN ({open_count})
              </Button>
              <Button
                bsstyle="primary"
                active={tabs.filter == "declined"}
                onClick={() => tabActions.filter("declined")}
              >
                PENDING ({declined_count})
              </Button>
              <Button
                bsstyle="primary"
                active={tabs.filter == "closed"}
                onClick={() => tabActions.filter("closed")}
              >
                CLOSED ({closed_count})
              </Button>
              <Button
                bsstyle="primary"
                active={tabs.filter == "fastpasses"}
                onClick={() => tabActions.filter("fastpasses")}
              >
                FAST PASSES({fastpasses.length})
              </Button>
              { tabs.enableTabComplete && (
                <Button
                  bsstyle="primary"
                  active={tabs.filter == "not complete"}
                  onClick={() => tabActions.filter("not complete")}
                >
                  NOT COMPLETE({not_complete_count})
                </Button>
              )}
              { tabs.enableTabComplete && (
                <Button
                  bsstyle="primary"
                  active={tabs.filter == "complete"}
                  onClick={() => tabActions.filter("complete")}
                >
                  COMPLETE({complete_count})
                </Button>
              )}
            </div>
          </div>
          <div className="col-md-4 left-inner-addon">
            <i className="fa fa-search"></i>
            <form className="customer_search">
              <input
                type="text"
                onChange={this.searchChanged.bind(this)}
                value={tabs.query}
                className="search-input"
              />
            </form>
          </div>
        </div>
        <div>
          {" "}
          {tabs.filter == "declined"
            ? "These transactions are currently pending. The tab should settle on its own but the user has been notified and their Barpay account has been disabled until the debt is settled"
            : ""}
        </div>
        {tabs.filter != "fastpasses"
          ? tabData.map((tab, index) => {
            return (
              <Tab
                key={index}
                isOpen={tabs.open == tab.id}
                tab={tab}
                tabActions={tabActions}
                enableTabComplete={tabs.enableTabComplete}
                disablePrinter={tabs.disablePrinter}
              />
            );
          })
          : ""}

        {tabData.length == 0 && tabs.filter != "fastpasses" ? (
          <h3>
            No {tabs.filter == "declined" ? "pending" : tabs.filter} tabs found
          </h3>
        ) : null}

        {tabs.filter == "fastpasses"
          ? (
            <div>
              <div className="panel-body">Total Fast Passes Sold: {total_fastpasses}</div>
              {fastpasses.map((fastpass, index) => {
                return <UserFastPass key={index} data={fastpass} />
              })}
            </div>
            ) : ""}
      </div>
    );
  }
}
export default connect(
  function (state) {
    return { tabs: state.tabs };
  },
  function (dispatch) {
    return {
      tabActions: bindActionCreators(actions.tabs, dispatch),
      activityActions: bindActionCreators(actions.activities, dispatch),
    };
  }
)(TabList);
