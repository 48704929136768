import React, { Component } from "react";

class PrinterStatus extends Component {
  constructor(props) {
    super(props);
    };

  render () {
    let { printer } = this.props
    let name = printer.printer_type
    if (name === "food") {
      name = "kitchen"
    } else if (name === "drinks") {
      name = "bar"
    }
    return (
      <div style={{ display: "inline-block" }} >
        <span style={{ textTransform: "capitalize" }}>{name}:</span>
        <span
          className={printer.status ? "dot-active" : "dot-inactive"}
        ></span>
        <span style={{ paddingRight: "20px" }}>
          {printer.status ? "Online" : "Offline"}
        </span>
      </div>
    )
  }
}

export default PrinterStatus