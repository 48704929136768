import {Component} from 'react'

class OrderItem extends Component {
  constructor(props) {
    super(props);
  }

  onSelect(event) {
    this.props.onSelect(event.target.checked);
  }

  render() {
    let {item, tabActions, tab} = this.props;

    return <tr>
      <td>
        {this.props.editing ?
          <div className="tabs-checkbox">
            <input type="checkbox" checked={this.props.selected} onChange={this.onSelect.bind(this)}/>
          </div> : null }
      </td>
      <td className="order-item-desc">
        <h1 className="tabs-menu-item">{item.quantity} &nbsp;{item.name}&nbsp;
        <span className="status">
          {item.status == "comped" ? "[COMP]" : null}
          {item.status == "refill" ? "[REFILL]" : null}
          {item.status == "void" ? "[VOID]" : null}
          </span>
        </h1>
      </td>
      <td>
        <h1 className="tabs-menu-item">${item.active_price.formatMoney()}</h1>
      </td>
    </tr>
  }
}
export default OrderItem