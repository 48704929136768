import React, { Component } from "react";
import PrinterStatus from "./PrinterStatus";
import ReactModal from "react-modal";
import PrinterGraphs from "./PrinterGraphs";

const customStyles = {
  content: {
    width: "70%",
    top: "30%",
    right: "auto",
    bottom: "auto",
    border: "none",
    borderRadius: "10px",
    transform: "translate(-50%, -20%)",
  },
  overlay: {
    backgroundColor: "rgba(50, 62, 72, 0.7)",
  },
};


class PrinterStatusContainer extends Component {
  constructor(props) {
    super(props);
    };

  render () {
    let { printers, showHistories, disablePrinter } = this.props
    const numberOfDataPoints = 30 * 72; // currently showing the last 72 hours with 2 minute precision (30 2 minute segments in an hour)
    const coeff = 1000 * 60 * 2;
    const date = new Date();
    const now = new Date(Math.round(date.getTime() / coeff) * coeff).getTime();
    const beginning = now - numberOfDataPoints * 120000;
    const labels = []

    for (var i = beginning; i < now; i += 120000) {
      labels.push(new Date(i));
    }

    const dataForChart = printers.map((p => {
      const data = []
      let name = p.printer_type
      if (name === "food") {
        name = "kitchen"
      } else if (name === "drinks") {
        name = "bar"
      }
      for (var i = beginning; i < now; i += 120000) {
        let val = 0;
        const dateToCheckExists = i;
        if (p.history && p.history.includes(dateToCheckExists)) {
          val = 1;
        }
        data.push(val);
      }
      return { name: name, data: data }
    }))

    let datasets = dataForChart.map((d) => {
      return (
        [{
          label: d.name,
          data: d.data,
          borderColor: `rgba(94,168,0,1)`,
          backgroundColor: `rgba(94,168,0,0.2)`,
          steppedLine: true,
          pointRadius: 0,
        }]
      )
    })

    const options = {
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      scales: {
        xAxes: [
          {
            ticks: {
              maxTicksLimit: 10,
              stepSize: 100,
              callback: function (value, index, values) {
                return `${value.getMonth() + 1}/${value.getDate()}/${value.getFullYear()} ${value.getHours()}:${
                  value.getMinutes() > 10
                    ? value.getMinutes()
                    : `0${value.getMinutes()}`
                }`;
              },
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              maxTicksLimit: 1,
              stepSize: 1,
              callback: function (value, index, values) {
                if (value === 0) {
                  return "Offline";
                } else {
                  return "Online";
                }
              },
            },
          },
        ],
      },
    };


    return (
      <div className="col-md-4 text-center tabs-column">
        { !disablePrinter && (
          <div>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingBottom: "10px" }} >
              <h4 style={{ paddingRight: "20px" }}>System Status</h4>
              <button
                className="btn btn-primary"
                onClick={this.props.toggleHistory}
                style={{padding: "1px", height: "30px", width: "30px"}}
              ><i className="fa fa-line-chart"></i></button>
            </div>
            <div className="col-md-12">
              {printers.map((p,i) => {
                return (
                  <PrinterStatus printer={p} key={i} />
                )
              })}
            </div>
          </div>
        )}
        <ReactModal
          isOpen={showHistories}
          onRequestClose={this.props.toggleHistory}
          style={customStyles}
          contentLabel="Printer Status Modal"
          ariaHideApp={false}
          shouldCloseOnOverlayClick={true}
        >
          <PrinterGraphs printers={printers} labels={labels} datasets={datasets} options={options} />
        </ReactModal>
      </div>
    );
  }
}

export default PrinterStatusContainer