import activities from "./activities";
import tabs from "./tabs";
import promotionCodes from "./promotion_codes";
import menus from "./menus";
import digitalMenus from "./digital_menus"
import { combineReducers } from "redux";

export default combineReducers({
  activities,
  tabs,
  promotionCodes,
  menus,
  digitalMenus
});
