import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import actions from "../actions";
import ProductSelection from "./ProductSelection";

const initialState = {
  currentVenue: {
    name: 'New Venue',
  },
  saving: false,
}

class AddDigitalMenuVenue extends Component {
  constructor(props) {
    super(props);
    this.onInputClick = this.onInputClick.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.state = {
      currentVenue: {
        name: 'New Venue',
      }
    };
  }

  onInputClick(event, name) {
    let currentVenue = this.state.currentVenue;
    if (event.target.value === initialState.currentVenue[name]) {
      currentVenue[name] = ""
      this.setState({...this.state, currentVenue});
    }
  }

  handleInputChange(event, name) {
    let currentVenue = this.state.currentVenue;
    currentVenue[name] = event.target.value;
    this.setState({ ...this.state, currentVenue });
  }

  render() {
    const { currentVenue } = this.state;

    return (
      <div className="add-venue">
        <div className="row">
          <label htmlFor="name">Venue Name</label>
          <input 
            id="name"
            name="name"
            type="text"
            style={{width: "100%"}}
            value={currentVenue.name || ''}
            onClick={(event) => this.onInputClick(event, "name")}
            onChange={(event) => this.handleInputChange(event, "name")}
          />
          <p style={{fontSize: "12px"}}>(note: additional information can be added once new venue is created)</p>
        </div>  
        <div className="row">
          <div className="column align-center">
            <ProductSelection 
              initialProduct={1}
              initialFrequency={true}
              update={true}
              addVenue={true}
              venueName={currentVenue.name}
            />
          </div>
        </div>
      </div>
    )
  }
};


export default connect (
  function (state) {
    return {
      digitalMenus: state.digitalMenus,
    };
  },
  function (dispatch) {
    return {
      digitalMenuActions: bindActionCreators(actions.digitalMenus, dispatch)
    };
  }
)(AddDigitalMenuVenue);