import {Component} from 'react'
import {connect} from 'react-redux'
import {addPromoCode} from '../actions/promotion_codes.js'

class PromotionCodeAddButton extends Component {
  constructor(props) {
    super(props);
  }

  onClick() {
    this.props.dispatch(addPromoCode());
  }

  render() {
    if (this.props.editable) {
      return <tr>
        <td onClick={this.onClick.bind(this)} className="add-promo-code" colSpan="9"><span className="glyphicon glyphicon-plus"></span>Create New Promo Code</td>
      </tr>
    } else {
      return <tr></tr>
    }
  }
}

const PromotionCodeAddButtonContainer = connect()(PromotionCodeAddButton)
export default PromotionCodeAddButtonContainer
