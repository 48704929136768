import { Component } from "react";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "../reducers";
import thunk from "redux-thunk";

const store = createStore(rootReducer, applyMiddleware(thunk));

import TabList from "./TabList";
import ActivityList from "./ActivityList";
import OwnerBar from "./OwnerBar";
import InfoContainer from "./InfoContainer";

Number.prototype.formatMoney = function (c, d, t) {
  var n = this,
    c = isNaN((c = Math.abs(c))) ? 2 : c,
    d = d == undefined ? "." : d,
    t = t == undefined ? "," : t,
    s = n < 0 ? "-" : "",
    i = parseInt((n = Math.abs(+n || 0).toFixed(c))) + "",
    j = (j = i.length) > 3 ? j % 3 : 0;
  return (
    s +
    (j ? i.substr(0, j) + t : "") +
    i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
    (c
      ? d +
        Math.abs(n - i)
          .toFixed(c)
          .slice(2)
      : "")
  );
};

class Root extends Component {
  render() {
    return (
      <Provider store={store}>
        { this.props.orderAndPay ? (
          <div>
            <OwnerBar />
            <div className="container-fluid tabs-padding">
              <div className="row">
                <div className="col-md-6 tablistborder">
                  <TabList />
                </div>
                <div className="col-md-6">
                  <ActivityList />
                </div>
              </div>
            </div>
          </div>
        ) : (
          < InfoContainer />
        )}
      </Provider>
    );
  }
}
export default Root;
