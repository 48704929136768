import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import actions from "../actions";
import moment from "moment";

class QrOrder extends Component {
  constructor(props) {
    super(props);
  }

  async notifyClick(order) {
    let { tabActions } = this.props;
    tabActions.notifyOrder(order);
  }

  async markCompleteClick(order) {
    let { tabActions } = this.props;
    tabActions.markOrderComplete(order);
  }

  render() {
    let { tab } = this.props;
    let placedOrders = tab.placed_orders;

    let qrOrders = placedOrders.map((order) => {
      return order.notification_status === "completed" ? (
        <React.Fragment />
      ) : (
        <div key={order.id} className="qrorder-outer-container">
          <div className="qrorder-inner-container">
            <div
              className={
                order.notified === undefined || order.notified === null
                  ? "qrorder-normal"
                  : new Date() - new Date(order.notified) > 1000 * 60 * 7
                  ? "qrorder-late"
                  : "qrorder-pending"
              }
            ></div>
            <div className="qrorder-body">
              <div className="qrorder-number">Order #{order.order_number}</div>
              <div className="qrorder-name">Name: {tab.customer_name}</div>
              <div className="qrorder-location">
                Location:
                {order.location !== undefined && order.location !== ""
                  ? order.location
                  : tab.table_number === "qrcode" ||
                    tab.table_number === "takeout"
                  ? tab.table_number
                  : `Table ${tab.table_number}`}
              </div>
              <div className="qrorder-notified">
                {order.notified === undefined || order.notified === null
                  ? ""
                  : `Notified ${moment(order.notified).from()}`}
              </div>
            </div>
          </div>
          <div className="qrcode-button">
            <button
              className={`btn qrorder-action-${
                order.notification_status === null
                  ? "notify"
                  : order.notification_status
              }`}
              onClick={
                order.notified === undefined || order.notified === null
                  ? this.markCompleteClick.bind(this, order)
                  : this.notifyClick.bind(this, order)
              }
            >
              {order.notified === undefined || order.notified === null
                ? "Notify"
                : "Mark Complete"}{" "}
            </button>
          </div>
        </div>
      );
    });

    return <div className="qrorder">{qrOrders}</div>;
  }
}

function mapStateToProps(state) {
  return {};
}

const QrOrderContainer = connect(mapStateToProps, function (dispatch) {
  return {
    tabActions: bindActionCreators(actions.tabs, dispatch),
  };
})(QrOrder);

export default QrOrderContainer;
