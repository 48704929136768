import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import actions from "../actions";
import Modal from "react-modal";
import DigitalMenuEditor from "./DigitalMenuEditor";

const customStyles = {
  content: {
    top: "25%",
    right: "auto",
    bottom: "auto",
    border: "none",
    borderRadius: "10px",
    transform: "translate(-40%, -20%)",
  },
  overlay: {
    top: "78px",
    backgroundColor: "rgba(50, 62, 72, 0.5)",
    overflowY: "auto",
  },
};

class EditDigitalMenuModal extends Component {
  constructor(props) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
  }

  closeModal() {
    let { digitalMenuActions } = this.props;
    digitalMenuActions.toggleDigitalMenuModal();
  }

  render() {
    return (
      <Modal
        isOpen={this.props.digitalMenus.editDigitalMenuOpen}
        onRequestClose={this.closeModal}
        style={customStyles}
        contentLabel="Digital Menu Modal"
        ariaHideApp={false}
        shouldCloseOnOverlayClick={false}
      >
        <div className="schedule-modal">
          <div className="schedule-modal-header">
            <span>Invisible Span</span>
            <h3>Digital Menu Editor</h3>
            <button className="schedule-close-button" onClick={this.closeModal}>
              <h3>X</h3>
            </button>
          </div>
          <p>
            Please use the following screen to edit selected digital menu
          </p>
          <DigitalMenuEditor />
        </div>
      </Modal>
    );
  }
};


export default connect (
  function (state) {
    return {
      digitalMenus: state.digitalMenus
    };
  },
  function (dispatch) {
    return {
      digitalMenuActions: bindActionCreators(actions.digitalMenus, dispatch)
    };
  }
)(EditDigitalMenuModal);