import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import actions from "../actions";
import EditDigitalMenuModal from "./EditDigitalMenuModal";
import { Collapse } from "react-bootstrap";

class DigitalMenuPanel extends Component {
  constructor(props) {
    super(props);
    this.onEditClick = this.onEditClick.bind(this);
    this.getPdfViewIcon = this.getPdfViewIcon.bind(this);
    this.up = this.up.bind(this);
    this.down = this.down.bind(this);
  }

  onEditClick() {
    let { digitalMenuActions } = this.props;
    let { menu } = this.props;
    digitalMenuActions.setCurrentMenu(menu)
    digitalMenuActions.toggleDigitalMenuModal();
  }

  getPdfViewIcon(menu) {
    let { menuOpen, setMenuOpen, clearMenuOpen } = this.props;
    return (menu.id === menuOpen ? (
      <a
        className="dm-tab-view-link"
        onClick={() => clearMenuOpen()}
        href={"#" + menu.id}
        data-toggle="collapse"
      >Preview <i className="fa fa-chevron-down"></i></a>
    ) : (
      <a
        className="dm-tab-view-link"
        onClick={() => setMenuOpen(menu.id)}
        href={"#" + menu.id}
        data-toggle="collapse"
      >Preview <i className="fa fa-chevron-right"></i></a>
    ))
  }

  up(id, position) {
    const totalMenus = this.props.digitalMenus.data.menus.length
    if (position !== 1 && totalMenus > 1) {
      this.props.digitalMenuActions.changeOrder(
        this.props.digitalMenus.data.venue_data.id,
        id,
        "up"
      );
    }
  }
  
  down(id, position) {
    const totalMenus = this.props.digitalMenus.data.menus.length
    if (position !== totalMenus && totalMenus > 1) {
      this.props.digitalMenuActions.changeOrder(
        this.props.digitalMenus.data.venue_data.id,
        id,
        "down"
      );
    }
  }
  
  render() {
    let { menu, isPreviewOpen } = this.props;
    let menuPreview = <div></div>
    let pdfIcon = <div className="dm-tab-view-link-disabled">
                    No Menu <i className="fa fa-chevron-right"></i>
                  </div>

    if(menu.link.length > 0) {
      pdfIcon = <a
                  className="dm-tab-view-link"
                  href={menu.link}
                  target={"_blank"}
                >Open Page <i className="fa fa-external-link"></i></a>
    } else if(menu.menu.url) {
      menuPreview = <iframe 
                      className="pdf-viewer"
                      name={menu.label}
                      src={menu.menu.url}
                      title="webviewer"
                    ></iframe>
      pdfIcon = this.getPdfViewIcon(menu)
    }
      
    return (
      <div className="panel panel-default dm-menu-panel">
        <div className="dm-table">
          <div className="dm-menu-info">
            <div className="dm-order">
              <h1 className="dm-tab-name">{menu.position}</h1>
              <div className="dm-arrow-container" >
                <a onClick={() => this.up(menu.id, menu.position)}>
                  <i className="fa fa-chevron-up"></i>
                </a>
                <a onClick={() => this.down(menu.id, menu.position)}>
                  <i className="fa fa-chevron-down"></i>
                </a>
              </div>
            </div>

            <div style={{padding: "10px"}}>
              <h1 className="dm-tab-name">{menu.label}</h1>
            </div>
            <div style={{padding: "10px"}}>
              <a className="dm-tab-edit-link" onClick={this.onEditClick}>Edit Menu</a>
            </div>
            <div style={{padding: "10px"}}>
              {pdfIcon}
            </div>
          </div>
          <div>
          </div>
          <Collapse in={isPreviewOpen} unmountOnExit={true} >  
            <div>
              <div className="pdf-viewer-container">
                {menuPreview}
                <a 
                  className="dm-tab-view-link"
                  href={menu.menu.url}
                  target={"_blank"}
                >
                  View in new tab <i className="fa fa-file-pdf-o"></i>
                </a>
              </div>
            </div>          
          </Collapse>
        </div>
        <EditDigitalMenuModal />
      </div>
    ) 
  }

}

export default connect (
  function (state) {
    return {
      digitalMenus: state.digitalMenus
    };
  },
  function (dispatch) {
    return {
      digitalMenuActions: bindActionCreators(actions.digitalMenus, dispatch)
    };
  }
)(DigitalMenuPanel);