import {Component} from 'react'
import OrderItem from './OrderItem';
import {DropdownButton, Dropdown, Button, Modal} from 'react-bootstrap';
import _ from 'lodash';
import moment from 'moment';

class Order extends Component {
  constructor(props) {
    super(props);
    this.itemSelect = this.itemSelect.bind(this)

    this.state = {
      editAction: null,
      showAdjust: false,
      adjustment: "0",
      selected: []
    }
  }

  onSave() {
    let {order} = this.props;
    let {editAction, selected} = this.state;
    this.props.tabActions.orderAction(order, editAction, selected);
    this.setState({editAction: null, selected: []});
  }

  onComp() {
    this.setState({editAction: "comp"})
  }

  onVoid() {
    this.setState({editAction: "void"})
  }

  onAdjust() {
    this.setState({showAdjust: true})
  }

  applyAdjust() {
    this.props.tabActions.adjustOrder(this.props.order, this.state.adjustment);
    this.setState({showAdjust: false})
  }

  itemSelect(id, bool) {
    if (bool == false) {
      let newArray = _.remove(this.state.selected, function (itemId) {
        return itemId !== id;
      });
      this.setState({selected: newArray});
    }
    else {
      this.state.selected.push(id);
      this.setState({selected: _.uniq(this.state.selected)})
    }
  }

  render() {
    let {order, tabActions, disablePrinter} = this.props;
    let isEditing = this.state.editAction != null;

    return <tbody>
    <tr>
      <td><h1 className="tabs-time">{moment(order.updated_at).from()}</h1></td>
      <td><h1 className="tabs-order">ORDER #{order.order_number}</h1></td>
      <td>
        <div className="btn-group">
          { !disablePrinter && (
            <Button bsstyle="link" bssize="large" onClick={() => tabActions.printOrder(order)}
                    style={{padding: "10px 16px"}}>REPRINT</Button>
          )}
          {this.props.closed ? null :
            isEditing ?
              <Button bsstyle="link" bssize="large" style={{padding: "10px 16px"}}
                      onClick={this.onSave.bind(this)}>SAVE</Button>
              :
              <DropdownButton noCaret bssize="large" bsstyle="link" title="EDIT" id="order-item-edit">
                <Dropdown.Item onClick={this.onComp.bind(this)}>COMP</Dropdown.Item>
                <Dropdown.Item onClick={this.onVoid.bind(this)}>VOID</Dropdown.Item>
              </DropdownButton>
          }
        </div>


        <Modal show={this.state.showAdjust} onHide={() => this.setState({showAdjust: false})}>
          <Modal.Header closeButton>
            <Modal.Title>Apply Discount To Order</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="input-group input-group-lg" style={{marginTop: 25}}>
              <span className="input-group-addon">$</span>
              <input className="form-control input-" type="text"
                     onChange={(event) => this.setState({adjustment: event.target.value})}
                     value={this.state.adjustment}/>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button bssize="large" className="pull-left"
                    onClick={() => this.setState({showAdjust: false})}>CANCEL</Button>
            <Button bsstyle="primary" bssize="large" onClick={this.applyAdjust.bind(this)}>SAVE</Button>
          </Modal.Footer>
        </Modal>
      </td>
    </tr>
    {order.order_items.map((item, index) => {
      return <OrderItem
        {...this.props}
        editing={isEditing}
        key={index}
        item={item}
        onSelect={(bool) => this.itemSelect(item.id, bool)}
        selected={this.state.selected.indexOf(item.id) > -1}
      />
    })}
    {parseFloat(order.total_adjustments) != 0 ?
      <tr className="adjustment-row">
        <td></td>
        <td><h1 className="tabs-menu-item">Adjustment</h1></td>
        <td><h1 className="tabs-menu-item">${parseFloat(order.total_adjustments).formatMoney()}</h1></td>
      </tr> : null}
    </tbody>
  }
}
export default Order