import React, { Component } from "react";

class DefaultCheckbox extends Component {
  constructor(props) {
    super(props);
    this.defaultPaymentComplete = this.defaultPaymentComplete.bind(this);
    this.state = {
      loading: false
    };
  }

  defaultPaymentComplete() {
    let { paymentComplete } = this.props;
    this.setState({ ...this.state, loading: true });
    paymentComplete(undefined)
  }

  render() {
    let { defaultPayment, useDefault, amount, toggleDefault} = this.props;
    let loading = this.state.loading;

    return (
      <div>
        { defaultPayment && (
          <div className="default-checkbox-container">
            <div className="checkbox-container">
              <label>Use Default Payment Method: </label>
              <input
                type="checkbox"
                id="default"
                name="default"
                className="dm-checkbox-input"
                value={useDefault || "false"}
                checked={useDefault}
                onChange={toggleDefault}
              />
            </div>
            { useDefault && (
                <div className="default-items-container">
                  <p>Brand: {defaultPayment.brand.toUpperCase()}</p>
                  <p>xxxx-xxxx-xxxx-{defaultPayment.last4}</p>
                  <p>exp: {defaultPayment.exp_year}</p>
                </div>
              )
            }
          { useDefault && amount && (
            <button 
              className='btn btn-primary stripe-submit-button' 
              type="submit" 
              disabled={!useDefault || loading}
              onClick={this.defaultPaymentComplete}
            >
              { loading ? 'Processing...' : `Pay $${amount}` }
            </button>
            )
          }
          </div>
          )
        }
      </div>
    )
  }}

  export default DefaultCheckbox;