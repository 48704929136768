import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import actions from "../actions";

class ImageUploader extends Component {
  constructor(props) {
    super(props);
    let imagePreview =
      this.props.editItem !== undefined ? this.props.editItem.image : "";
    this.state = { file: "", imagePreviewUrl: imagePreview };
  }

  _handleSubmit(e) {
    e.preventDefault();
  }

  _handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState(
        {
          file: file,
          imagePreviewUrl: reader.result,
        },
        function () {
          this.props.menuActions.updateItemImage(this.state.imagePreviewUrl);
        }
      );
    };

    reader.readAsDataURL(file);
  }

  render() {
    let { imagePreviewUrl } = this.state;
    let $imagePreview = null;
    if (imagePreviewUrl) {
      $imagePreview = <img src={imagePreviewUrl} />;
    } else {
      $imagePreview = (
        <div className="previewText">
          Please upload an item image. Recommended image size is 350px x 200px
        </div>
      );
    }

    return (
      <div className="preview-component">
        <div className="imgPreview">{$imagePreview}</div>
        <form onSubmit={(e) => this._handleSubmit(e)}>
          <div className="upload-btn-wrapper">
            <button className="submitButton">Upload a file</button>
            <input type="file" onChange={(e) => this._handleImageChange(e)} />
          </div>
        </form>
      </div>
    );
  }
}

export default connect(
  function (state) {
    return {
      menus: state.menus,
      editItem: state.menus.editItem,
    };
  },
  function (dispatch) {
    return {
      menuActions: bindActionCreators(actions.menus, dispatch),
    };
  }
)(ImageUploader);
