import {Component} from 'react'
import {connect} from 'react-redux'
import {editPromoCode, closePromoCode, savePromoCode} from '../actions/promotion_codes.js'
import Select from 'react-select';
import Datetime from 'react-datetime';
import Toggle from 'react-toggle';
import USD from 'react-icons/lib/fa/dollar';
import Percent from 'react-icons/lib/fa/percent';
import NumberFormat from 'react-number-format';

class PromotionCodeAdd extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: this.props.id,
      name: this.props.name,
      code: this.props.code,
      limit: this.props.limit,
      venue: this.props.venue,
      discount_is_percent: this.props.discount_is_percent,
      discount: this.props.discount,
      expires: this.props.expires,
      is_barpay: this.props.is_barpay
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.id !== this.props.id)
      this.setState({id: nextProps.id});
    if (nextProps.name !== this.props.name)
      this.setState({name: nextProps.name});
    if (nextProps.limit !== this.props.limit)
      this.setState({limit: nextProps.limit});
    if (nextProps.code !== this.props.code)
      this.setState({code: nextProps.code});
    if (nextProps.venue !== this.props.venue)
      this.setState({venue: nextProps.venue});
    if (nextProps.discount_is_percent !== this.props.discount_is_percent)
      this.setState({discount_is_percent: nextProps.discount_is_percent});
    if (nextProps.discount !== this.props.discount)
      this.setState({discount: nextProps.discount});
    if (nextProps.expires !== this.props.expires)
      this.setState({expires: nextProps.expires});
    if (nextProps.is_barpay !== this.props.is_barpay)
      this.setState({is_barpay: nextProps.is_barpay});
  }

  onChange(e) {
    var intermediateState = {};
    intermediateState[e.target.id] = e.target.value;
    this.setState(intermediateState, function() {
      this.props.dispatch(editPromoCode(this.state));
    });
  }

  expiresOnChange(e) {
    var expires = this.props.expires ? '' : Datetime.moment();
    this.setState({expires: expires}, function(){
      this.props.dispatch(editPromoCode(this.state));
    });
  }

  isBarpayOnChange(e) {
    this.setState({is_barpay: e.target.checked}, function() {
      this.props.dispatch(editPromoCode(this.state));
    });
  }

  expiresDateOnChange(e) {
    this.setState({expires: e}, function(){
      this.props.dispatch(editPromoCode(this.state));
    });
  }

  discountTypeOnChange(e) {
    this.setState({discount_is_percent: !e.target.checked, discount: 0}, function() {
      this.props.dispatch(editPromoCode(this.state));
    });
  }

  limitOnChange(e) {
    this.setState({limit: e.value}, function() {
      this.props.dispatch(editPromoCode(this.state));
    });
  }

  venueOnChange(e) {
    this.setState({venue: e.value}, function() {
      this.props.dispatch(editPromoCode(this.state));
    });
  }

  discountFormatter(num) {
    if (this.props.discount_is_percent) {
      return num + '%';
    } else {
      return '$' + num;
    }
  }

  cancel() {
    this.props.dispatch(closePromoCode());
  }

  save() {
    this.props.dispatch(savePromoCode(this.state));
  }

  render() {
    return <div className={this.props.editable ? 'promo-code-add' : 'promo-code-add-invisible'}>
        <div className="promo-code-form container">
          <div className="row">
            <div className="promo-code-header">New Code</div>
          </div>
          <div className="row">
            <form role="form">
              <div className="form-group col-xs-10 col-sm-3 col-md-3 col-lg-6">
                  <label htmlFor="name">Name</label>
                  <input type="text" className="form-control" id="name" placeholder="Enter Name" onChange={this.onChange.bind(this)} value={this.props.name || ""}/>
              </div>
              <div className="form-group col-xs-10 col-sm-3 col-md-3 col-lg-6">
                  <label htmlFor="limit">Limit</label>
                  <Select
                    onChange={this.limitOnChange.bind(this)}
                    options={this.props.limits}
                    value={this.props.limit ? this.props.limit.toString() : "Unlimited"}
                    limit
                    />
              </div>
              <div className="clearfix"></div>
              <div className="form-group col-xs-10 col-sm-3 col-md-3 col-lg-6">
                  <label htmlFor="code">Code</label>
                  <input type="text" className="form-control" id="code" placeholder="Code" onChange={this.onChange.bind(this)} value={this.props.code.toUpperCase() || ""}/>
              </div>
              <div className="form-group col-xs-10 col-sm-3 col-md-3 col-lg-6">
                  <label htmlFor="venue">Limit to Specific Venue</label>
                  <Select
                    onChange={this.venueOnChange.bind(this)}
                    options={this.props.venues}
                    value={this.props.venue ? this.props.venue : 0}
                    venue
                    />
                  <label className="custom-control custom-checkbox sub-label">
                    <span className="custom-control-description">*Select "All" for all venues</span>
                  </label>
              </div>
              <div className="clearfix"></div>
              <div className="form-group col-xs-10 col-sm-3 col-md-3 col-lg-6">
                  <label htmlFor="discount" className="center-label">Discount <Toggle 
                    className='discount-toggle'
                    checked={!this.props.discount_is_percent}
                    onChange={this.discountTypeOnChange.bind(this)}
                    icons={{
                      checked: <USD/>,
                      unchecked: <Percent/>,
                    }}/>
                  </label>
                  <NumberFormat className="form-control"
                    id="discount" 
                    value={this.props.discount ? this.props.discount_is_percent ? this.props.discount * 100 : this.props.discount : ''} 
                    prefix={this.props.discount_is_percent ? '' : '$'} 
                    suffix={this.props.discount_is_percent ? '%' : ''} 
                    decimalPrecision={this.props.discount_is_percent ? 0 : 2}
                    onChange={(e, values) => {
                      const {formattedValue, value} = values;
                      if (this.props.discount_is_percent)
                      {
                        this.setState({discount: (value/ 100)}, function(){
                          this.props.dispatch(editPromoCode(this.state));
                        });
                      } else
                      {
                        this.setState({discount: value}, function(){
                          this.props.dispatch(editPromoCode(this.state));
                        });
                      }
                    }}/>
              </div>
              <div className="form-group col-xs-10 col-sm-3 col-md-3 col-lg-6">
                  <label htmlFor="expires">Expires</label>
                  <label className="custom-control custom-checkbox sub-label first-time-checkbox">
                    <span className="custom-control-indicator"></span>
                    <input type="checkbox" className="custom-control-input" value={this.props.expires || ''} checked={this.props.expires || ''} onChange={this.expiresOnChange.bind(this)}/>
                  </label>
                  <Datetime value={Datetime.moment(this.props.expires) || new Date()} inputProps={{disabled: !this.props.expires}} onChange={this.expiresDateOnChange.bind(this)}/>
              </div>
              <div className="clearfix"></div>
              <div className="form-group col-xs-20 col-sm-6 col-md-6 col-lg-12">
                  <label htmlFor="is_barpay">Barpay Promotion</label>
                  <label className="custom-control custom-checkbox sub-label first-time-checkbox">
                    <span className="custom-control-indicator"></span>
                    <input type="checkbox" className="custom-control-input" value={this.props.is_barpay || ''} checked={this.props.is_barpay || ''} onChange={this.isBarpayOnChange.bind(this)}/>
                  </label>
              </div>
              <div className="clearfix"></div>
            </form>
          </div>
        </div>
        <div className="container row save-buttons">
          <div className="col-xs-10 col-sm-4 col-md-4 col-lg-4">
            <button type="submit" onClick={this.cancel.bind(this)} className="btn btn-default btn-lg btn-block cancel">Cancel</button>
          </div>
          <div className="col-xs-10 col-sm-4 col-md-4 col-lg-4">
            <button type="submit" onClick={this.save.bind(this)} className="btn btn-default btn-lg btn-block save">Save</button>
          </div>
        </div>
      </div>
  }
}

function mapStateToProps(state){  
  return {
    editable: state.promotionCodes.promo_code_editable,
    id: state.promotionCodes.id,
    name: state.promotionCodes.name, 
    code: state.promotionCodes.code, 
    limit: state.promotionCodes.limit, 
    venue: state.promotionCodes.venue, 
    discount_is_percent: state.promotionCodes.discount_is_percent, 
    discount: state.promotionCodes.discount, 
    expires: state.promotionCodes.expires,
    is_barpay: state.promotionCodes.is_barpay
  };
}

const PromotionCodeAddContainer = connect(
  mapStateToProps
)(PromotionCodeAdd)

export default PromotionCodeAddContainer
