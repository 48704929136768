import React, { Component } from "react";
import { Line } from "react-chartjs-2"
import PrinterStatus from "./PrinterStatus";

class PrinterGraphs extends Component {
  constructor(props) {
    super(props);
    };

  render () {
    let { printers, datasets, options, labels } = this.props
    return (
      <div style={{ display: "flex", flexDirection: "column" }} >
        {datasets.length > 0 ? (
          datasets.map((ds, i) => {
            return (
              <div style={{ height: "300px", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", marginBottom: "20px" }} key={i} >
                <PrinterStatus printer={printers[i]}/>
                <Line data={{datasets: ds, labels: labels}} options={options} height={200} />
              </div>
            )
          })) : ""
        }
      </div>
    );
  }
}

export default PrinterGraphs