import {Component} from 'react'
import moment from 'moment';

function getClass(activity) {
  switch(activity.event) {
    case "comped":
      return "tabs-comp";
    case "voided":
      return "tabs-void";
    case "closed":
      return "tabs-closed";
    default:
      return "tabs-activity-name";
  }

}
class Activity extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    let {activity, activityActions} = this.props;

    return <tr>
      <td><h1 className="tabs-time">{moment(activity.event_at).format('h:mm a')}</h1></td>
      <td><h1 className={getClass(activity)}>{activity.description}</h1></td>
      <td><h1 className={"text-right tabs-payment "+getClass(activity)}>${parseFloat(activity.event_amount).formatMoney()}</h1></td>

    </tr>
  }
}
export default Activity