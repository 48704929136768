import {Component} from 'react'
import {connect} from 'react-redux'
import {editPromoCode, deletePromoCode} from '../actions/promotion_codes.js';
import moment from 'moment'

class PromotionCode extends Component {
  constructor(props) {
    super(props);
  }

  editRow() {
    if(this.props.editable) {
      this.props.dispatch(editPromoCode(this.props.promoCode));
    }
  }

  delete() {
    var result = confirm("Are you sure you wish to delete this promotion code?");
    if (result) {
      this.props.dispatch(deletePromoCode(this.props.promoCode.id));
    }
  }

  render() {
    const isExpired = moment().isAfter(moment(this.props.promoCode.expires));
    return <tr className={this.props.editable ? 'clickable-row' : ''}>
      <td onClick={this.editRow.bind(this)} >{this.props.promoCode.name}</td>
      <td onClick={this.editRow.bind(this)} ><a>{this.props.promoCode.code.toUpperCase()}</a></td>
      <td onClick={this.editRow.bind(this)}>{this.props.promoCode.discount_is_percent ? (this.props.promoCode.discount*100).toFixed(0) + '%' : '$' + this.props.promoCode.discount.toFixed(2) }</td>
      <td onClick={this.editRow.bind(this)}>{this.props.promoCode.limit ? this.props.promoCode.limit : 'Unlimited'}</td>
      <td onClick={this.editRow.bind(this)}>{this.props.promoCode.expires ? moment(this.props.promoCode.expires).format('MM/DD/YY h:mmA') : 'Never'}</td>
      <td onClick={this.editRow.bind(this)}>{this.props.promoCode.venue ? this.props.promoCode.venue.name : 'All'}</td>
      <td onClick={this.editRow.bind(this)}>{this.props.promoCode.redeemed}</td>
      <td onClick={this.editRow.bind(this)} className={this.props.promoCode.is_barpay ? 'Barpay' : 'Venue'}>{this.props.promoCode.is_barpay ? 'Barpay' : 'Venue'}</td>
      <td onClick={this.editRow.bind(this)} className={isExpired ? 'expired' : 'running'}>{isExpired ? 'Expired' : 'Running'}</td>
      <td>{this.props.editable ? <a onClick={this.delete.bind(this)}><span className="glyphicon glyphicon-minus-sign" aria-hidden="true"></span></a> : ''}</td>
    </tr>
  }
}

const PromotionCodeContainer = connect()(PromotionCode)

export default PromotionCodeContainer
