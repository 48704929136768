import { Component } from "react";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "../reducers";
import { loadPromotionCodes } from "../actions/promotion_codes.js";
import thunk from "redux-thunk";
import PromotionCodeListContainer from "./PromotionCodeList.js";

const store = createStore(rootReducer, compose(applyMiddleware(thunk)));

store.dispatch(loadPromotionCodes());

class PromotionCodeRoot extends Component {
  render() {
    return (
      <Provider store={store}>
        <PromotionCodeListContainer />
      </Provider>
    );
  }
}

export default PromotionCodeRoot;
