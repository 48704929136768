import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import actions from "../actions";

class FileUploader extends Component {
  constructor(props) {
    super(props);
    this.state = { file: undefined, filePreviewUrl: undefined };
  }

  handleSubmit(e) {
    e.preventDefault();
  }

  handleFileChange(e) {
    e.preventDefault();
    const { update } = this.props;

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState(
        {
          file: file,
          filePreviewUrl: reader.result,
        },
        function () {
          update(reader.result)
        }
      )
    }

    reader.readAsDataURL(file);
  }

  render() {
    const { message, filePreviewUrl } = this.props;
    let filePreview = "";
    if (filePreviewUrl || this.state.filePreviewUrl) {
      if(filePreviewUrl) {
        filePreview = <iframe className="file" src={filePreviewUrl} title="webviewer" />;
      }
      if (this.state.filePreviewUrl) {
        filePreview = <embed className="file" src={this.state.filePreviewUrl} title="webviewer" />;
      }
    } else {
      filePreview = (
        <div className="previewText">
          {message}
        </div>
      );
    }

    return (
      <div className="preview-component">
        <div className="file-preview">{filePreview}</div>
        <form onSubmit={(e) => this.handleSubmit(e)}>
          <div className="upload-btn-wrapper">
            <button className="submitButton">Upload a file</button>
            <input type="file" onChange={(e) => this.handleFileChange(e)} />
          </div>
          { this.state.file && this.state.file.name && (
            <label>{this.state.file.name}</label>
          )}
        </form>
      </div>
    );
  }

}

export default FileUploader