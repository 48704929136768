const toggleMenuItem = function (menuItem) {
  return { type: "TOGGLE_MENU_ITEM", menuItem: menuItem };
};

const toggleAllMenuItems = function () {
  return { type: "TOGGLE_ALL_MENU_ITEMS" };
};

const toggleScheduleModal = function () {
  return { type: "TOGGLE_SCHEDULE_MODAL" };
};

const toggleReorderModal = function () {
  return { type: "TOGGLE_REORDER_MODAL" };
};

const toggleBulkEditModal = function () {
  return { type: "TOGGLE_BULK_EDIT_MODAL" };
};

const toggleCategoryManagementModal = function () {
  return function (dispatch, getState) {
    dispatch({ type: "TOGGLE_CATEGORY_MODAL" });
    dispatch(loadCategories());
    dispatch(loadSubCategories());
    dispatch(loadMenuItems());
  };
};

const toggleSubCategoryManagementModal = function () {
  return function (dispatch, getState) {
    dispatch({ type: "TOGGLE_SUBCATEGORY_MODAL" });
    dispatch(loadCategories());
    dispatch(loadSubCategories());
    dispatch(loadMenuItems());
  };
};

const toggleItemEditingModal = function () {
  return { type: "TOGGLE_EDIT_ITEM_MODAL" };
};

const setVenue = function (venueId) {
  return { type: "SET_VENUE", venueId: venueId };
};

const setSearch = function (searchTerm) {
  return { type: "SET_SEARCH", searchTerm: searchTerm };
};

const setMenuCategory = function (searchTerm, categoryId = 0) {
  return {
    type: "SET_SEARCH_MENU_CATEGORY",
    searchTerm: searchTerm,
    categoryId: categoryId,
  };
};

const setMenuSubCategory = function (searchTerm) {
  return { type: "SET_SEARCH_MENU_SUBCATEGORY", searchTerm: searchTerm };
};

const toggleFoodFilter = function (val) {
  return { type: "SET_FOOD_FILTER", foodFilter: val };
};

const toggleDrinksFilter = function (val) {
  return { type: "SET_DRINKS_FILTER", drinksFilter: val };
};

const toggleExtrasFilter = function (val) {
  return { type: "SET_EXTRAS_FILTER", extrasFilter: val };
};

const updateItemImage = function (image) {
  return { type: "UPDATE_ITEM_IMAGE", image: image };
};

const toggleInStock = function (item_id, in_stock) {
  return function (dispatch, getState) {
    const url = `/venues/${getState().menus.venue
      }/menus/in_stock?item_id=${item_id}&in_stock=${in_stock}`;
    fetch(url, { method: "POST" }).then(function () {
      dispatch({ type: "MENU_ITEMS_INSTOCK" });
      dispatch(loadMenuItems());
    });
  };
};

const loadMenuItems = function () {
  return function (dispatch, getState) {
    const url = "/venues/" + getState().menus.venue + "/menus/items.json";
    dispatch({ type: "MENU_ITEMS_LOADING" });
    fetch(url, { method: "GET" })
      .then((response) => response.json())
      .then(function (data) {
        dispatch({ type: "MENU_ITEMS_LOADED", data: data });
      });
  };
};

const loadMenuSchedules = function () {
  return function (dispatch, getState) {
    const url =
      "/venues/" + getState().menus.venue + "/menus/menu-items-schedule.json";
    fetch(url, { method: "GET", credentials: "omit" })
      .then((response) => response.json())
      .then(function (data) {
        dispatch({ type: "SCHEDULES_LOADED", data: data });
      });
  };
};

const loadCategories = function () {
  return function (dispatch, getState) {
    const url = "/venues/" + getState().menus.venue + "/menus/categories.json";
    fetch(url, { method: "GET" })
      .then((response) => response.json())
      .then(function (data) {
        dispatch({ type: "MENU_CATEGORIES_LOADED", data: data });
      });
  };
};

const loadPrinters = function () {
  return function (dispatch, getState) {
    const url =
      "/venues/" + getState().menus.venue + "/printers.json";
    fetch(url, { method: "GET" })
      .then((response) => response.json())
      .then(function (data) {
        dispatch({
          type: "MENU_PRINTERS_LOADED",
          data: data.printers,
        });
      });
  };
};

const loadSubCategories = function () {
  return function (dispatch, getState) {
    const url =
      "/venues/" + getState().menus.venue + "/menus/subcategories.json";
    fetch(url, { method: "GET" })
      .then((response) => response.json())
      .then(function (data) {
        dispatch({
          type: "MENU_SUBCATEGORIES_LOADED",
          data: data.subcategories,
        });
      });
  };
};

const deleteSchedule = function (name) {
  return function (dispatch, getState) {
    const url = `/venues/${getState().menus.venue}/schedules?name=${name}`;
    fetch(url, { method: "DELETE" }).then(function () {
      dispatch(loadMenuSchedules());
    });
  };
};

const editCategory = function (id, type, name) {
  return function (dispatch, getState) {
    var resource = "categories";
    if (type === "subcategory") {
      resource = "subcategories";
    }
    const url = `/venues/${getState().menus.venue
      }/menus/${resource}.json?id=${id}&name=${name}`;
    fetch(url, { method: "PUT" });
  };
};

const deleteCategory = function (id, type) {
  return function (dispatch, getState) {
    var resource = "categories";
    if (type === "subcategory") {
      resource = "subcategories";
    }
    const url = `/venues/${getState().menus.venue
      }/menus/${resource}.json?id=${id}`;
    fetch(url, { method: "DELETE" }).then(function () {
      if (resource === "categories") {
        dispatch({
          type: "MENU_CATEGORY_DELETED",
        });
      } else {
        dispatch({
          type: "MENU_SUBCATEGORY_DELETED",
        });
      }

      dispatch(loadCategories());
      dispatch(loadSubCategories());
      dispatch(loadMenuItems());
    });
  };
};

const changeOrder = function (id, type, direction) {
  return function (dispatch, getState) {
    var resource = "categories";
    if (type === "subcategory") {
      resource = "subcategories";
    }
    const url = `/venues/${getState().menus.venue
      }/menus/${resource}.json?id=${id}&direction=${direction}`;
    fetch(url, { method: "PUT" }).then(function () {
      if (resource === "categories") {
        dispatch({
          type: "MENU_CATEGORY_UPDATED",
        });
      } else {
        dispatch({
          type: "MENU_SUBCATEGORY_UPDATED",
        });
      }

      dispatch(loadCategories());
      dispatch(loadSubCategories());
    });
  };
};

const changeItemOrder = function (id, direction) {
  return function (dispatch, getState) {
    const url = `/venues/${getState().menus.venue
      }/menus/item.json?item_id=${id}&direction=${direction}`;
    fetch(url, { method: "PUT" }).then(function () {
      dispatch({
        type: "MENU_ITEM_UPDATED",
      });

      dispatch(loadMenuItems());
    });
  };
};

const addCategory = function (type, name, menu, callback) {
  return function (dispatch, getState) {
    var resource = "categories";
    if (type === "subcategory") {
      resource = "subcategories";
    }
    const url = `/venues/${getState().menus.venue
      }/menus/${resource}.json?name=${name}&menu=${menu}`;
    fetch(url, { method: "POST" }).then(function (result) {
      if (result.status >= 400) {
        dispatch({
          type: "MENU_CATEGORY_ADD_ERROR",
        });
      } else {
        if (resource === "categories") {
          dispatch({
            type: "MENU_CATEGORY_ADDED",
          });
          callback();
        } else {
          dispatch({
            type: "MENU_SUBCATEGORY_ADDED",
          });
          callback();
        }

        dispatch(loadCategories());
        dispatch(loadSubCategories());
      }
    });
  };
};

const openEditItem = function (id) {
  return function (dispatch, getState) {
    const url = `/venues/${getState().menus.venue}/menus/item?id=${id}`;
    fetch(url, { method: "GET" })
      .then((response) => response.json())
      .then(function (data) {
        dispatch({
          type: "OPEN_EDIT_ITEM",
          data: data,
        });
      });
  };
};

const deleteItem = function (item, callback) {
  return function (dispatch, getState) {
    const url = `/venues/${getState().menus.venue}/menus/items.json?id=${item.id
      }`;

    fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(item),
    })
      .then((result) => {
        if (result.status > 400) {
          dispatch({
            type: "MENU_ITEM_DELETE_ERROR",
            data: "There was an error contacting the server",
          });
        } else {
          dispatch({ type: "MENU_ITEM_DELETED" });
          callback();
          dispatch(loadMenuItems());
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
};

const updateItem = function (itemDetails, callback) {
  return function (dispatch, getState) {
    let method = "";
    if (!itemDetails.editItem) {
      method = "POST";
    } else {
      method = "PUT";
    }

    const url = `/venues/${getState().menus.venue}/menus/items.json`;

    fetch(url, {
      method: method,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(itemDetails),
    })
      .then((result) => {
        if (result.status > 400) {
          dispatch({
            type: "MENU_ITEM_ADD_ERROR",
            data: "There was an error contacting the server",
          });
        } else {
          dispatch({ type: "MENU_ITEM_UPDATED" });
          callback();
          dispatch(loadMenuItems());
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
};

const bulkEditItems = function (items, editCriteria) {
  return function (dispatch, getState) {
    dispatch({ type: "BULK_UPDATE_STARTED" });
    const url = `/venues/${getState().menus.venue}/menus/bulk-edit.json`;
    fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ items: items, editCriteria: editCriteria }),
    })
      .then((result) => {
        if (result.status > 400) {
          dispatch({
            type: "BULK_EDIT_UPDATE_ERROR",
            data: "There was an error updating the selected items",
          });
        } else {
          dispatch(loadMenuItems());
          if (getState().menus.allMenuItemsSelected) dispatch(toggleAllMenuItems());
          dispatch({ type: "BULK_UPDATE_COMPLETE" });
          dispatch({ type: "TOGGLE_BULK_EDIT_MODAL" });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
};

const saveSchedules = function (schedules, timezone) {
  return function (dispatch, getState) {
    const url = `/venues/${getState().menus.venue}/schedules`;
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ schedules: schedules, timezone: timezone }),
    })
      .then((result) => {
        if (result.status > 400) {
          console.error("Error: ", result.statusText);
          dispatch({
            type: "SCHEDULE_UPDATE_ERROR",
            data: "There was an error updating the schedule",
          });
        } else {
          dispatch({ type: "SCHEDULE_UPDATED" });
          dispatch(loadMenuSchedules());
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
};

export default {
  toggleScheduleModal,
  toggleReorderModal,
  toggleCategoryManagementModal,
  toggleSubCategoryManagementModal,
  toggleItemEditingModal,
  loadCategories,
  loadSubCategories,
  loadPrinters,
  loadMenuItems,
  setVenue,
  setSearch,
  setMenuCategory,
  setMenuSubCategory,
  toggleDrinksFilter,
  toggleExtrasFilter,
  toggleFoodFilter,
  toggleInStock,
  deleteSchedule,
  deleteCategory,
  editCategory,
  deleteItem,
  changeOrder,
  changeItemOrder,
  addCategory,
  updateItem,
  openEditItem,
  updateItemImage,
  loadMenuSchedules,
  saveSchedules,
  toggleMenuItem,
  toggleAllMenuItems,
  toggleBulkEditModal,
  bulkEditItems,
};
