import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import actions from "../actions";
import BulkEditMenu from "./BulkEditMenu";

class ItemBulkEditButton extends Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    this.props.menuActions.toggleBulkEditModal();
  }

  render() {
    let bulkEditMenu = <div />;
    if (this.props.menus.bulkEditModalOpen) {
      bulkEditMenu = <BulkEditMenu />;
    }

    return (
      <div className="item-bulk-update">
        <div className="item-bulk-update-button">
          <input
            type="button"
            value="Bulk Update"
            onClick={this.onClick}
          />
        </div>
        {bulkEditMenu}
      </div>
    );
  }
}

export default connect(
  function (state) {
    return {
      menus: state.menus,
    };
  },
  function (dispatch) {
    return {
      menuActions: bindActionCreators(actions.menus, dispatch),
    };
  }
)(ItemBulkEditButton);
