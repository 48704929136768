import moment from 'moment';

const load = function (background = false) {
  return function (dispatch, getState) {
    if (!background)
      dispatch({type: "ACTIVITIES_LOADING"});
    
    let momentDate = getState().tabs.date
    if (!moment.isMoment(momentDate)) {
      momentDate = moment(momentDate)
    }

    fetch('activities.json?date='+ momentDate.startOf('day').format()).then(response => response.json()).then(function (data) {
      dispatch({type: "ACTIVITIES_LOADED", data: data})
    }).catch(function () {
      dispatch({type: "ACTIVITIES_FAILED"})
    })
  }
};

const add = function(activity) {
  return {type: "ACTIVITIES_ADD", activity}
}

const filter = function (filter) {
  return {type: "ACTIVITIES_FILTER", filter}
};

export default {load, filter, add}