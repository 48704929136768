import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import actions from "../actions";
import Activity from "./Activity";
import { Button, DropdownButton, Dropdown } from "react-bootstrap";
import _ from "lodash";
import consumer from "../../cable";
import soundFile from "../../../sounds/new-order.mp3"

class ActivityList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.intervalID = 0;
  }

  componentWillUnmount() {
    this.state.tabSub.unsubscribe();
    clearInterval(this.intervalID);
  }

  componentDidMount() {
    let { activityActions, activities } = this.props;
    activityActions.load();
    
    let tabSub = consumer.subscriptions.create(
      { channel: "TabChannel", venue: window.venue.id },
      {
        received(data) {
          let activity = data["activity"]
          if (activity !== undefined) {
            if (activity["event"] === "opened" && activity["enable_tab_sounds"]) {
              this.newOrder()
            }
            activityActions.add(data["activity"]);
          }
        },

        newOrder() {
          Promise.resolve()
            .then(() => this.playSound())
            .then(() => this.delay(500))
            .then(() => this.playSound())
            .then(() => this.delay(500))
            .then(() => this.playSound())
            .then(() => this.delay(500))
            .then(() => this.playSound())
            .then(() => this.delay(500))
            .then(() => this.playSound())
        },

        delay(duration) {
          return new Promise((resolve) => {
            setTimeout(() => resolve(), duration);
          });
        },

        playSound() {
          return new Promise((resolve, reject) => {
            try{
                let sound = new Audio(soundFile);
                sound.onended = () => { resolve() };
                sound.play();
            }catch(error){
                reject(error);
            }
          });
        }
      }
    );

    this.setState({ tabSub: tabSub });

    this.intervalID = setInterval(function () {
      activityActions.load(true);
    }, 1000 * 60);
  }

  render() {
    let { activities, activityActions } = this.props;
    
    if (activities.loading) return <div className="loader"></div>;

    let activityData = _.filter(activities.data, function (a) {
      switch (activities.filter) {
        case "VOIDS":
          return a.event == "voided";
        case "COMPS":
          return a.event == "comped";
        case "CLOSED":
          return a.event == "closed";
        default:
          return true;
      }
    });

    return (
      <div className="inner-self-scroll">
        <div className="row" style={{ marginBottom: 20 }}>
          <div className="col-md-4 col-md-offset-4 text-center">
            <h1 className="tabs-activity">ACTIVITY</h1>
          </div>
          <div className="col-md-4">
            <div className="pull-right">
              <DropdownButton
                pullRight
                className="tabs-dropdown"
                bssize="large"
                title={activities.filter}
                id="dropdown-size-large"
                onSelect={(key) => activityActions.filter(key)}
              >
                <Dropdown.Item eventKey="ALL">SHOW ALL</Dropdown.Item>
                <Dropdown.Item eventKey="VOIDS">SHOW VOIDS</Dropdown.Item>
                <Dropdown.Item eventKey="COMPS">SHOW COMPS</Dropdown.Item>
                <Dropdown.Item eventKey="CLOSED">SHOW CLOSED</Dropdown.Item>
              </DropdownButton>
            </div>
          </div>
        </div>
        <div className="panel panel-default">
          {activityData.length == 0 ? (
            <div className="panel-body">No activities found</div>
          ) : (
              <table className="table">
                <tbody>
                  {activityData.map((activity, index) => {
                    return (
                      <Activity
                        key={index}
                        activity={activity}
                        activityActions={activityActions}
                      />
                    );
                  })}
                </tbody>
              </table>
            )}
        </div>
      </div>
    );
  }
}
export default connect(
  function (state) {
    return { activities: state.activities };
  },
  function (dispatch) {
    return {
      activityActions: bindActionCreators(actions.activities, dispatch),
    };
  }
)(ActivityList);
