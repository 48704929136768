import {Component} from 'react'
import {connect} from 'react-redux'
import Datetime from 'react-datetime';
import {updateReportStartDate, updateReportEndDate, loadVenues} from '../actions/promotion_codes.js'
import moment from 'moment'

class PromotionCodeReportDatePicker extends Component {
  constructor(props) {
    super(props);
  }

  endDateOnChange(e) {
    this.props.dispatch(updateReportEndDate(e));
  }

  startDateOnChange(e) {
    this.props.dispatch(updateReportStartDate(e));
  }

  set() {
    this.props.dispatch(loadVenues(this.props.startTime, this.props.endTime));
  }

  render() {
    return <div className="row">
      <div className="col-xs-10 col-sm-12 col-md-12 col-lg-5 report-datepickers">
        <span><Datetime className="report-datetime" value={Datetime.moment(this.props.startTime) || moment().startOf('day')} onChange={this.startDateOnChange.bind(this)} /></span>
        <span>to</span>
        <span><Datetime className="report-datetime" value={Datetime.moment(this.props.endTime) || moment().endOf('day')} onChange={this.endDateOnChange.bind(this)}/></span>
      </div>
      <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 set-button">
        <button type="submit" onClick={this.set.bind(this)} className="btn btn-default save">Set</button>
      </div>
    </div>
  }
}

function mapStateToProps(state){  
  return {
    startTime: state.promotionCodes.reportStartTime,
    endTime: state.promotionCodes.reportEndTime,
  };
}

const PromotionCodeReportDatePickerContainer = connect(mapStateToProps)(PromotionCodeReportDatePicker)
export default PromotionCodeReportDatePickerContainer 
