let initialState = {
  data: {
    venue_data: {},
    venue_dm: {},
    menus: [],
    url: "",
    dm_activate: false
  },
  editDigitalMenuOpen: false,
  paymentModalOpen: false,
  promptModalOpen: false,
  planSelectOpen: false,
  editMenu: undefined,
  editMenuPdf: undefined,
  currentMenuNumber: undefined,
  updateDigitalMenuError: "",
  deleteDigitalMenuError: "",
  updateDigitalMenuVenueError: "",
  venueId: undefined,
  currentMenu: {},
  paymentSetupInfo: undefined,
  pdfError: "",
  currentProduct: 1,
  stripePublicKey: undefined,
  stripeInfo: undefined,
  customerEmailsUrl: undefined,
  upgradeType: ""
};

export default function digitalMenus(state = initialState, action) {
  switch (action.type) {
    case 'DIGITAL_MENUS_LOADING':
      return {...state, data: action.data, loading: true, closing: false};
    case 'DIGITAL_MENUS_LOADED':
      return {...state, data: action.data, loading: false, closing: false};
    case 'DIGITAL_MENUS_FAILED':
      return {...state, loading: false};
    case 'TOGGLE_DIGITAL_MENU_MODAL':
      return {
        ...state, 
        editDigitalMenuOpen: !state.editDigitalMenuOpen,
        editMenu: undefined,
        editMenuPdf: undefined,
        paymentModalOpen: false,
        promptModalOpen: false,
      };
    case 'SET_CURRENT_MENU':
      return { ...state, currentMenu: action.currentMenu }
    case 'CLEAR_CURRENT_MENU':
      return { ...state, currentMenu: {} }
    case "DIGITAL_MENU_UPDATE_ERROR":
      return { ...state, updateDigitalMenuError: action.data };
    case "DIGITAL_MENU_VENUE_UPDATE_ERROR":
      return { ...state, updateDigitalMenuVenueError: action.data };
    case "DIGITAL_MENU_UPDATED":
      return { ...state, editDigitalMenuOpen: false, editMenuPdf: undefined };
    case "DIGITAL_MENU_DELETE_ERROR":
      return { ...state, deleteDigitalMenuError: action.data };
    case "DIGITAL_MENU_DELETED":
      return { ...state, editDigitalMenuOpen: false };
    case "DIGITAL_MENU_DELETED":
      return { ...state, editDigitalMenuOpen: false };
    case "UPDATE_DIGITAL_MENUS_ONLY":
      let newData = state.data
      newData.menus = action.data
      return { ...state, data: newData };
    case 'PAYMENT_SETUP':
      return { ...state, paymentSetupInfo: action.data, stripePublicKey: action.key };
    case 'CLEAR_PAYMENT_SETUP':
      return { ...state, paymentSetupInfo: undefined, stripePublicKey: undefined }
    case 'SET_PDF_ERROR':
      return { ...state, pdfError: action.data }
    case 'SET_CURRENT_PRODUCT':
      return { ...state, currentMenu: action.currentProduct }
    case 'SET_VENUE_ID':
      return { ...state, venueId: action.venueId }
    case 'STRIPE_INFO_LOADED':
      return { ...state, stripeInfo: action.data };
    case 'CLEAR_STRIPE_INFO':
      return { ...state, stripeInfo: undefined }
    case 'TOGGLE_PAYMENT_MODAL':
      return {
        ...state,
        editDigitalMenuOpen: false,
        paymentModalOpen: !state.paymentModalOpen,
        planSelectOpen: action.data,
        promptModalOpen: false,
      };
    case 'TOGGLE_PROMPT_UPGRADE_MODAL':
      return {
        ...state,
        paymentModalOpen: false,
        promptModalOpen: !state.promptModalOpen,
        upgradeType: action.upgradeType
      };
    case 'CUSTOMER_EMAILS_LOADED':
      return { ...state, customerEmailsUrl: action.url };
    case 'CLEAR_CUSTOMER_EMAILS':
      return { ...state, customerEmailsUrl: undefined }
    default:
      return state
  }
}