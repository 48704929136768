import { Component } from "react";

class CategoryEditorHeader extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <tr className="category-header-row">
        <td colSpan={this.props.span || 7 }>
          <p>{this.props.name}</p>
        </td>
      </tr>
    );
  }
}
export default CategoryEditorHeader;
