$(document).on("turbolinks:load", function () {
  $(".withdraw-tgl").on("click", function () {
    var elem = $(this).prev();
    var available = elem.data("withdraw-enabled");

    $.ajax({
      type: "POST",
      url: "/venues/withdraw-toggle",
      data: { withdraw_enabled: !available },
      success: function () {
        elem.data("withdraw-enabled", !available);
        var aval = !available ? "Enabled" : "Disabled";
        elem
          .parent()
          .prev()
          .text("Referral Withdraw " + aval);
        if (!available) {
          elem.prop("checked", "checked");
        } else {
          elem.removeAttr("checked");
        }
      },
    });
  });

  $(".tables-tgl").on("click", function () {
    var elem = $(this).prev();
    var venueId = elem.data("venue");
    var available = elem.data("table-service-enabled");

    $.ajax({
      type: "POST",
      url: "/venues/" + venueId + "/table-service-toggle",
      data: { table_service_enabled: !available },
      success: function () {
        elem.data("table-service-enabled", !available);
        if (!available) {
          $("#table-service-enabled").text("Table service is enabled");
        } else {
          $("#table-service-enabled").text("Table service is disabled");
        }
      },
    });
  });

  $(".print-tgl").on("click", function () {
    var elem = $(this).prev();
    var venueId = elem.data("venue");
    var available = elem.data("enable-tip-print");
    $.ajax({
      type: "POST",
      url: "/venues/" + venueId + "/print-tips-toggle",
      data: { enable_tip_receipt: !available },
      success: function () {
        elem.data("enable-tip-print", !available);
      },
    });
  });

  $(".server-tgl").on("click", function () {
    var elem = $(this).prev();
    var venueId = elem.data("venue");
    var available = elem.data("enable-server-copy-print");
    $.ajax({
      type: "POST",
      url: "/venues/" + venueId + "/print-server-copy-toggle",
      data: { enable_server_copy_receipt: !available },
      success: function () {
        elem.data("enable-server-copy-print", !available);
      },
    });
  });

  $("#tog1").on("click", function () {
    if (this.checked) {
      $("#will-not").html("WILL");
    } else {
      $("#will-not").html("WILL NOT");
    }
  });
  $(".role").on("click", function () {
    var role = $(this).data();
    if (role.user) {
      $.ajax({
        type: "POST",
        url: "/settings/users/role",
        data: { id: role.roleid, name: role.rolename, user: role.user },
        success: function (response) {
          var userId = response[0].id;

          $(".userRole")
            .find("[data-userId=" + userId + "]")
            .html(response[1].name)
            .css("textTransform", "capitalize")
            .append("<span class=caret></span>");
        },
      }).fail(function (err) {
        throw err;
      });
    } else {
      $(".userRole")
        .find("[data-userId]")
        .html(role.rolename)
        .css("textTransform", "capitalize")
        .append("<span class=caret></span>");
      $("#user_roles").val(role.rolename);
    }
  });
  $(".client").on("click", function () {
    var client = $(this).data();
    $(".userClient")
      .find("#clientToggle")
      .html(client.clientname)
      .css("textTransform", "capitalize")
      .append("<span class=caret></span>");
    $("#client_id").val(client.clientid);
  });
});
