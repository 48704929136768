var moment = require('moment');

let initialState = {
  promotion_codes: [],
  venues: [],
  limits: [],
  name: "",
  code: "",
  limit: undefined, 
  venue: 0,
  discount_is_percent: false,
  discount: 0,
  expires: '',
  is_barpay: false,
  promo_code_editable: false,
  reportStartTime: moment().startOf('day'),
  reportEndTime: moment().endOf('day'),
  reportVenues: []
};

export default function promotionCodes(state = initialState, action) {
  switch (action.type) {
    case 'PROMOTION_CODES_LOADED':
      return {...state, promotion_codes: action.data.promotion_codes, venues: action.data.venues, limits: action.data.limits}
    case 'VENUES_LOADED':
      return {...state, reportVenues: action.data.venues}
    case 'ADD_PROMO_CODE':
      return {...state,
        name: initialState.name,
        code: initialState.code, 
        limit: initialState.limit, 
        venue: initialState.venue,
        discount_is_percent: initialState.discount_is_percent, 
        discount: initialState.discount, 
        expires: initialState.expires, 
        is_barpay: initialState.is_barpay, 
        promo_code_editable: true}
    case 'EDIT_PROMO_CODE':
      return {...state, 
        id: action.data.id,
        name: action.data.name, 
        code: action.data.code, 
        limit: action.data.limit, 
        venue: action.data.venue ? action.data.venue.id ? action.data.venue.id : action.data.venue : 0, 
        discount_is_percent: action.data.discount_is_percent, 
        discount: action.data.discount, 
        expires: action.data.expires, 
        is_barpay: action.data.is_barpay,
        promo_code_editable: true}
    case 'CLOSE_PROMO_CODE':
      return {...state, promo_code_editable: false}
    case 'UPDATE_REPORT_START_DATE':
      return {...state, reportStartTime: action.data}
    case 'UPDATE_REPORT_END_DATE':
      return {...state, reportEndTime: action.data}
    default:
      return state
  }
}

