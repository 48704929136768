import activityActions from "./activities";
import moment from 'moment';

const reload = function (background = false) {
  return function (dispatch) {
    dispatch(load(background));
    dispatch(activityActions.load(background));
  };
};

const transactionStatus = function (tab) {
  return async function (dispatch, getState) {
    const response = await fetch(`tabs/transaction_status?id=${tab.id}`);
    const transaction = await response.json();
    return transaction.status;
  };
};

const load = function (background = false) {
  return function (dispatch, getState) {
    if (!background) dispatch({ type: "TABS_LOADING" });

    let momentDate = getState().tabs.date
    if (!moment.isMoment(momentDate)) {
      momentDate = moment(momentDate)
    }

    fetch("tabs.json?date=" + momentDate.startOf("day").format(), {
      method: "GET",
    })
      .then((response) => response.json())
      .then(function (data) {
        dispatch({ type: "TABS_LOADED", data: data });
      })
      .catch(function () {
        dispatch({ type: "TABS_FAILED" });
      });

    fetch("tabs/sum.json", { method: "GET" })
      .then((response) => response.json())
      .then(function (data) {
        dispatch({ type: "TABS_SUM", sum: data.sum });
      });

    fetch(
      "userfastpasses.json?date=" +
        momentDate.startOf("day").format(),
      { method: "GET" }
    )
      .then((response) => response.json())
      .then(function (data) {
        dispatch({ type: "FASTPASSES_LOADED", data: data });
      })
      .catch(function () {
        dispatch({ type: "FASTPASSES_FAILED" });
      });
  };
};
const setOpen = function (id) {
  return function (dispatch, getState) {
    if (id == getState().tabs.open) {
      dispatch({ type: "TAB_OPEN", undefined });
    } else {
      dispatch({ type: "TAB_OPEN", id });
    }
  };
};
const search = function (query) {
  return { type: "TABS_SEARCH", query };
};
const filter = function (filter) {
  return { type: "TABS_FILTER", filter };
};

const dateChange = function (date) {
  return function (dispatch) {
    const newDate = moment(date)
    dispatch({ type: "TABS_DATE", newDate });
    setTimeout(function () {
      dispatch(reload(false));
    }, 50);
  };
};

const closeTab = function (tab) {
  return function (dispatch) {
    fetch("tabs/" + tab.id, { method: "PUT" })
      .then((response) => response.json())
      .then(function (data) {
        if (data.user) {
          clevertap.profile.push({
            Site: {
              Name: data.user.first_name + " " + data.user.last_name,
              Identity: data.user.email,
              Email: data.user.email,
            },
          });
          clevertap.event.push("Charged", {
            "Venue Name": data.tab.venue,
            "Charged ID": data.user.email,
            Amount: data.tab.amount,
            Type: "Backend",
            Amount_String: data.tab.amount.toFixed(2),
          });
        }

        if (data.message) toastr.info(data.message);
      });
  };
};

const printOrder = function (order) {
  return function (dispatch) {
    fetch("tabs/re_print?order=" + order.id)
      .then((response) => response.json())
      .then(function (data) {
        if (data.message) toastr.info(data.message);
      });
  };
};

const markOrderComplete = function (order) {
  return function (dispatch) {
    fetch("tabs/notify?order=" + order.id)
      .then((response) => response.json())
      .then(function (data) {
        dispatch(load());
        if (data.message) toastr.info(data.message);
      });
  };
};

const notifyOrder = function (order) {
  return function (dispatch) {
    fetch("tabs/mark-complete?order=" + order.id)
      .then((response) => response.json())
      .then(function (data) {
        dispatch(load());
        if (data.message) toastr.info(data.message);
      });
  };
};

const adjustOrder = function (order, amount) {
  return function (dispatch) {
    fetch(`tabs/${order.tab_id}/orders/${order.id}/adjust`, {
      method: "PUT",
      body: JSON.stringify({ amount }),
    })
      .then((response) => response.json())
      .then(function (data) {
        if (data.message) toastr.info(data.message);
      });
  };
};

const orderAction = function (order, type = "void", items = []) {
  return function (dispatch) {
    fetch(`tabs/${order.tab_id}/orders/${order.id}/order_items/${type}`, {
      method: "PUT",
      body: JSON.stringify({ order_items: items }),
    })
      .then((response) => response.json())
      .then(function (data) {
        if (data.message) toastr.info(data.message);
      });
  };
};

const closeAll = function () {
  return function (dispatch) {
    dispatch({ type: "TABS_CLOSING" });
    fetch(`tabs/close`, {
      method: "POST",
    })
      .then((response) => response.json())
      .then(function (data) {
        dispatch(reload(false));

        if (data.message) toastr.info(data.message);
      });
  };
};

const refundTab = function (tab, refundAmount, type) {
  return function (dispatch) {
    dispatch({ type: "ISSUE_REFUND" });
    fetch(`tabs/${tab.id}/refund`, {
      method: "PUT",
      body: JSON.stringify({ refundAmount, type }),
    })
      .then((response) => response.json())
      .then(function (data) {
        dispatch(reload(false));
        if (data.message) toastr.info(data.message);
      });
  };
};

const markTabComplete = function (tabId) {
  return function (dispatch) {
    fetch(`tabs/${tabId}/mark_tab_complete`, {
      method: "PUT"
    })
      .then(function () {
        dispatch(reload(true));
      });
  };
};

const printerStatus = function () {
  return async function (dispatch, getState) {
    const response = await fetch(`printer_status`);
    const transaction = await response.json();
    return transaction
  };
};

const add = function (tab) {
  toastr.info("New tab created");
  return { type: "TABS_ADD", tab };
};

const update = function (tab) {
  return { type: "TABS_UPDATED", tab };
};

export default {
  load,
  search,
  filter,
  dateChange,
  setOpen,
  closeTab,
  printOrder,
  adjustOrder,
  orderAction,
  closeAll,
  reload,
  add,
  update,
  refundTab,
  transactionStatus,
  printerStatus,
  notifyOrder,
  markOrderComplete,
  markTabComplete,
};
