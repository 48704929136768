import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Datetime from "react-datetime";
import Modal from "react-modal";
import actions from "../actions";
import trashicon from "../../../images/delete.png";
import { confirmAlert } from "react-confirm-alert";
import Loader from "./Loader";

const customStyles = {
  content: {
    minHeight: "700px",
    height: "70%",
    top: "35%",
    right: "auto",
    bottom: "auto",
    border: "none",
    borderRadius: "10px",
    transform: "translate(-30%, -30%)",
  },
  overlay: {
    backgroundColor: "rgba(50, 62, 72, 0.5)",
  },
};

class MenuScheduleModal extends Component {
  constructor(props) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
    this.save = this.save.bind(this);
    this.onChange = this.onChange.bind(this);
    this.addMenuSchedule = this.addMenuSchedule.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onInputClick = this.onInputClick.bind(this);
    this.menuNameInput = this.menuNameInput.bind(this);
    this.onAfterOpen = this.onAfterOpen.bind(this);
    this.onMenuDayCheckbox = this.onMenuDayCheckbox.bind(this);
    this.onMenuDelete = this.onMenuDelete.bind(this);
    this.state = { schedules: [], menuName: "Enter Menu Name", saving: false };
  }

  initializeState() {
    if (this.props.schedules.length > 0) {
      let options = [];
      for (const [index, value] of this.props.schedules.entries()) {
        options.push({
          name: value.name,
          start: this.convertTimeToDate(value.start_time),
          end: this.convertTimeToDate(value.end_time),
          days: value.days,
        });
      }

      this.setState({ schedules: options, saving: false });
    }
  }

  onAfterOpen() {
    this.initializeState();
  }

  onInputClick(event) {
    if (event.target.value === "Enter Menu Name") {
      this.setState({ menuName: "" });
    }
  }

  menuNameInput(event) {
    this.setState({ menuName: event.target.value });
  }

  onBlur(event) {
    if (event.target.value === "") {
      this.setState({
        menuName: "Enter Menu Name",
      });
    }
  }

  addMenuSchedule() {
    let schedules = this.state.schedules;
    schedules[this.state.schedules.length] = {
      days: {
        sunday: true,
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
        saturday: true,
      },
      start: new Date(),
      end: new Date(),
      position: this.state.schedules.length,
      name: this.state.menuName,
    };

    this.setState({
      ...this.state,
      schedules: schedules,
    });
  }

  onMenuDelete(index) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="confirm-delete-item">
            <h2>Are you sure you want to delete this schedule?</h2>
            <button onClick={onClose}>No</button>
            <button
              onClick={() => {
                if (this.props.schedules[index] !== undefined) {
                  this.props.menuActions.deleteSchedule(
                    this.state.schedules[index].name
                  );
                }

                this.setState({
                  schedules: this.state.schedules.filter((_, i) => i !== index),
                });

                onClose();
              }}
            >
              Yes
            </button>
          </div>
        );
      },
    });
  }

  onMenuDayCheckbox(day, index) {
    let updatedSchedules = this.state.schedules;

    updatedSchedules[index].days[day] = !updatedSchedules[index].days[day];

    this.setState({
      ...this.state,
      schedules: updatedSchedules,
    });
  }

  addScheduleEntry(index, value) {
    return (
      <tr key={index}>
        <td className="schedule-cell">
          <p>{value.name}</p>
        </td>
        <td className="schedule-cell">
          <Datetime
            dateFormat={false}
            onChange={(time) => this.onChange(index, "start", time)}
            inputProps={{
              className:
                value.name === "Default"
                  ? "schedule-time-picker-disabled"
                  : "schedule-time-picker",
              disabled: value.name === "Default",
            }}
            value={value.start}
          />
        </td>
        <td className="schedule-cell">
          <p>To</p>
        </td>
        <td className="schedule-cell">
          <Datetime
            dateFormat={false}
            onChange={(time) => this.onChange(index, "end", time)}
            inputProps={{
              className:
                value.name === "Default"
                  ? "schedule-time-picker-disabled"
                  : "schedule-time-picker",
              disabled: value.name === "Default",
            }}
            value={value.end}
            disabled={value.name === "Default"}
          />
        </td>
        <td className="schedule-cell">
          <div className="checkbox">
            <input
              id={"menu-day-sunday-" + index}
              name={"menu-day-sunday-" + index}
              type="checkbox"
              onChange={() => this.onMenuDayCheckbox("sunday", index)}
              checked={value.days.sunday}
              disabled={value.name === "Default"}
            />
            <label htmlFor={"menu-day-sunday-" + index}></label>
          </div>
        </td>
        <td className="schedule-cell">
          <div className="checkbox">
            <input
              id={"menu-day-monday-" + index}
              name={"menu-day-monday-" + index}
              type="checkbox"
              onChange={() => this.onMenuDayCheckbox("monday", index)}
              checked={value.days.monday}
              disabled={value.name === "Default"}
            />
            <label htmlFor={"menu-day-monday-" + index}></label>
          </div>
        </td>
        <td className="schedule-cell">
          <div className="checkbox">
            <input
              id={"menu-day-tuesday-" + index}
              name={"menu-day-tuesday-" + index}
              type="checkbox"
              onChange={() => this.onMenuDayCheckbox("tuesday", index)}
              checked={value.days.tuesday}
              disabled={value.name === "Default"}
            />
            <label htmlFor={"menu-day-tuesday-" + index}></label>
          </div>
        </td>
        <td className="schedule-cell">
          <div className="checkbox">
            <input
              id={"menu-day-wednesday-" + index}
              name={"menu-day-wednesday-" + index}
              type="checkbox"
              onChange={() => this.onMenuDayCheckbox("wednesday", index)}
              checked={value.days.wednesday}
              disabled={value.name === "Default"}
            />
            <label htmlFor={"menu-day-wednesday-" + index}></label>
          </div>
        </td>
        <td className="schedule-cell">
          <div className="checkbox">
            <input
              id={"menu-day-thursday-" + index}
              name={"menu-day-thursday-" + index}
              type="checkbox"
              onChange={() => this.onMenuDayCheckbox("thursday", index)}
              checked={value.days.thursday}
              disabled={value.name === "Default"}
            />
            <label htmlFor={"menu-day-thursday-" + index}></label>
          </div>
        </td>
        <td className="schedule-cell">
          <div className="checkbox">
            <input
              id={"menu-day-friday-" + index}
              name={"menu-day-friday-" + index}
              type="checkbox"
              onChange={() => this.onMenuDayCheckbox("friday", index)}
              checked={value.days.friday}
              disabled={value.name === "Default"}
            />
            <label htmlFor={"menu-day-friday-" + index}></label>
          </div>
        </td>
        <td className="schedule-cell">
          <div className="checkbox">
            <input
              id={"menu-day-saturday-" + index}
              name={"menu-day-saturday-" + index}
              type="checkbox"
              onChange={() => this.onMenuDayCheckbox("saturday", index)}
              checked={value.days.saturday}
              disabled={value.name === "Default"}
            />
            <label htmlFor={"menu-day-saturday-" + index}></label>
          </div>
        </td>
        <td>
          {value.name === "Default" ? (
            ""
          ) : (
            <img
              onClick={() => this.onMenuDelete(index)}
              width="20px"
              src={trashicon}
            />
          )}
        </td>
      </tr>
    );
  }

  onChange(index, type, time) {
    let schedules = this.state.schedules;
    schedules[index][type] = time;
    this.setState({
      ...this.state,
      schedules: schedules,
    });
  }

  convertTimeToDate(time) {
    const retDate = new Date();
    const parts = time.match(/(\d+)\:(\d+)(\w+)/);
    let hours = parseInt(parts[1], 10);
    if (hours === 12) {
      hours = hours - 12;
    }
    if (parts[3] === "PM") {
      hours = hours + 12;
    }
    const mins = parseInt(parts[2], 10);
    retDate.setHours(hours);
    retDate.setMinutes(mins);
    return retDate;
  }

  save() {
    this.setState(
      {
        saving: true,
      },
      this.props.menuActions.saveSchedules(
        this.state.schedules,
        new Date().getTimezoneOffset() / 60
      )
    );
  }

  closeModal() {
    this.props.menuActions.toggleScheduleModal();
  }

  render() {
    let options = [];
    options.push(
      <tr key={1000}>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td>
          <p>Sun</p>
        </td>
        <td>
          <p>Mon</p>
        </td>
        <td>
          <p>Tue</p>
        </td>
        <td>
          <p>Wed</p>
        </td>
        <td>
          <p>Thu</p>
        </td>
        <td>
          <p>Fri</p>
        </td>
        <td>
          <p>Sat</p>
        </td>
        <td></td>
      </tr>
    );
    for (const [index, value] of this.state.schedules.entries()) {
      options.push(this.addScheduleEntry(index, value));
    }

    let saveButton = <button onClick={this.save}>Save</button>;
    if (this.state.saving) {
      saveButton = <Loader />;
    }

    return (
      <Modal
        isOpen={this.props.menus.scheduleModalOpen}
        onRequestClose={this.closeModal}
        onAfterOpen={this.onAfterOpen}
        style={customStyles}
        contentLabel="Schedule Modal"
        ariaHideApp={false}
        shouldCloseOnOverlayClick={false}
      >
        <div className="schedule-modal">
          <div className="schedule-modal-header">
            <span>Invisible Span</span>
            <h3>Specialty Menus</h3>
            <button className="schedule-close-button" onClick={this.closeModal}>
              <h3>X</h3>
            </button>
          </div>
          <p>
            Add a new specialty menu. Specialty menus override your default menu
            pricing and scheduling. (Lunch, Dinner, Late Night, Taco Tuesday,
            Half Price Wednesday, Special Events, etc)
          </p>
          <div className="schedule-modal-entries schedule-settings">
            <table className="schedule-table">
              <tbody>{options}</tbody>
            </table>
          </div>
          <div className="schedule-modal-save-container">
            <div className="schedule-modal-save">
              <input
                id="name"
                name="name"
                className="new-menu-name"
                onChange={this.menuNameInput}
                onClick={this.onInputClick}
                onBlur={this.onBlur}
                type="text"
                value={this.state.menuName}
              />
              <button onClick={this.addMenuSchedule}>Add New Menu</button>
            </div>
            <div className="schedule-modal-save">
              {saveButton}
              {this.props.scheduleError.length > 0 && (
                <div className="error">{this.props.scheduleError}</div>
              )}
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
export default connect(
  function (state) {
    return {
      menus: state.menus,
      schedules: state.menus.schedules,
      scheduleError: state.menus.scheduleError,
    };
  },
  function (dispatch) {
    return {
      menuActions: bindActionCreators(actions.menus, dispatch),
    };
  }
)(MenuScheduleModal);
