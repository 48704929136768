import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import actions from "../actions";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "30%",
    right: "auto",
    bottom: "auto",
    border: "none",
    borderRadius: "10px",
    transform: "translate(-30%, -20%)",
  },
  overlay: {
    backgroundColor: "rgba(50, 62, 72, 0.5)",
  },
};

class UpdateModal extends Component {
  constructor(props) {
    super(props);
    this.downloadEmails = this.downloadEmails.bind(this);
    this.pollEmails = this.pollEmails.bind(this);
    this.state = {
      emails: 0,
      loading: false
    }
  }

  pollEmails() {
    let { digitalMenuActions } = this.props;
    let endTime = Number(new Date()) + (900000);
    let interval = 2000;
    const clearState = () => {
      this.setState({ ...this.state, loading: false })
    }
  
    const checkCondition = async function (resolve, reject) {
      let check = await digitalMenuActions.pollCustomerEmails();
      if (check) {
        clearState()
        resolve();
      } else if (Number(new Date()) < endTime) {
        setTimeout(checkCondition, interval, resolve, reject);
      } else {
        reject(new Error("timed out: " + arguments));
      }
    };
    return new Promise(checkCondition);
  }

  downloadEmails() {
    let { digitalMenuActions } = this.props;
    this.setState({ ...this.state, loading: true });
    digitalMenuActions.getCustomerEmails(
      function () {
        this.pollEmails()
      }.bind(this)
    );
  }

  componentDidMount() {
    let { digitalMenuActions } = this.props;
    digitalMenuActions.pollCustomerEmails();
  }

  render() {
    let {isModalOpen, modalToggle, digitalMenus } = this.props;
    const loading = this.state.loading;
    let totalEmails = this.props.digitalMenus.data ? this.props.digitalMenus.data["total_emails"] : 0
    let extra = totalEmails < 1 ? " Please ensure that 'COLLECT EMAILS' is enabled in your venue settings." : ""
    const buttonMessage = loading ? "Loading, please wait..." : "Generate CSV"
    
    return (
      <Modal
        isOpen={isModalOpen}
        onRequestClose={modalToggle}
        style={customStyles}
        contentLabel="Customer Email Modal"
        ariaHideApp={false}
        shouldCloseOnOverlayClick={false}
      >
        <div className="schedule-modal update-payment-modal">
          <div 
            className="schedule-modal-header"
            style={{display: "flex", justifyContent: "space-between" }}
          >
            <h3 style={{display: "inline-block"}}>Download Emails</h3>
            <button 
              className="schedule-close-button" 
              onClick={modalToggle}
              style={{display: "flex", position: "static"}}
            >
              <h3 style={{display: "inline-block"}}>X</h3>
            </button>
          </div>
          <div className="dm-emails" style={{}}>
            <p 
              style={{
                margin: "30px 0 30px 0", 
                textAlign: "left", 
                fontWeight: "bold", 
                fontSize: "16px"
              }}
            >
              There are {totalEmails} customer emails that can be downloaded at the moment.{extra}
            </p>
            { digitalMenus.customerEmailsUrl && (
                <a 
                  className="dm-tab-view-link" 
                  style={{marginBottom: "30px"}}
                  href={digitalMenus.customerEmailsUrl} 
                  download
                >
                  Download File (.csv) <i className="fa fa-download"></i>
                </a>
            )}
            { totalEmails > 0 && (
              <button disabled={loading} style={{width: "100%"}} className='btn btn-primary' onClick={this.downloadEmails}>{buttonMessage}</button>
            )}
          </div>
        </div>
      </Modal>
    );
  }
};


export default connect(
  function (state) {
    return {
      digitalMenus: state.digitalMenus
    };
  },
  function (dispatch) {
    return {
      digitalMenuActions: bindActionCreators(actions.digitalMenus, dispatch)
    };
  }
)(UpdateModal);