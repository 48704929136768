jQuery(document).on("turbolinks:load", function () {
  $(".chosen-select").chosen({
    allow_single_deselect: true,
    no_results_text: "No results matched",
    width: "400px",
  });

  //Activates the Boostrap Popovers
  $('[data-toggle="popover"]').popover();

  $('[data-toggle="btns"] .btn').on("click", function () {
    var $this = $(this);
    $this.parent().find(".active").removeClass("active");
    $this.addClass("active");
    $(this).blur();
  });

  // did not track down why - http://stackoverflow.com/a/29006010/224288
  $(".dropdown-toggle").dropdown();

  $.ajaxSetup({
    headers: { "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content") },
  });

  $(".merchant-timepicker").datetimepicker({
    format: "YYYY-MM-DD",
    icons: {
      previous: 'fa fa-arrow-left',
      next: 'fa fa-arrow-right',
      date: "fa fa-calendar"
    },
  });
});
