import {Component} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import PromotionCodeReportDatePickerContainer from './PromotionCodeReportDatePicker.js'
import PromotionCodeReportRowContainer from './PromotionCodeReportRow.js'

class PromotionCodeReport extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <div>
      <PromotionCodeReportDatePickerContainer />
      <table className="promotion-code-list">
        <thead>
          <tr>
            <th colSpan="6">Promos Report</th>
          </tr>
          <tr>
            <th>Venue Name</th>
            <th>Codes Redeemed</th>
            <th>Amount Discounted</th>
            <th>Total Due</th>
            <th>Amount to Pay</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {this.props.venues.map((venue, index) => {
            return <PromotionCodeReportRowContainer venue={venue} key={index} />
          })}
        </tbody>
      </table>
    </div>
  }
}

function mapStateToProps(state){  
  return {
    reportStartTime: state.promotionCodes.reportStartTime,
    reportEndTime: state.promotionCodes.reportEndTime,
    venues: state.promotionCodes.reportVenues
  };
}

const PromotionCodeReportContainer = connect(
  mapStateToProps
)(PromotionCodeReport)

export default PromotionCodeReportContainer
