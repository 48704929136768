let initialState = {
  scheduleModalOpen: false,
  reorderModalOpen: false,
  categoryModalOpen: false,
  subCategoryModalOpen: false,
  editItemModalOpen: false,
  bulkEditModalOpen: false,
  categories: [],
  subcategories: [],
  printers: [],
  menus: [],
  items: [],
  itemsLoaded: false,
  searchTerm: "",
  filterMenuCategory: "",
  filterMenuCategoryId: 0,
  filterMenuSubCategory: "",
  foodFilter: true,
  drinksFilter: true,
  extrasFilter: true,
  venue: 0,
  itemError: "",
  editItem: undefined,
  editImage: undefined,
  schedules: [],
  scheduleError: "",
  menuAddError: false,
  deleteItemError: "",
  allMenuItemsSelected: false,
  bulkEditError: "",
  bulkSaving: false,
};

export default function menus(state = initialState, action) {
  switch (action.type) {
    case "TOGGLE_MENU_ITEM":
      return {
        ...state,
        items: state.items.map((el) => {
          if (el.id === action.menuItem.id) {
            el.selected = !el.selected;
          }

          return el;
        }),
      };
    case "BULK_UPDATE_STARTED":
      return {
        ...state,
        bulkSaving: true,
      };
    case "BULK_EDIT_UPDATE_ERROR":
      return {
        ...state,
        bulkEditError: action.data,
        bulkSaving: false,
      };
    case "BULK_UPDATE_COMPLETE":
      return {
        ...state,
        bulkEditError: "",
        bulkSaving: false,
      };
    case "TOGGLE_ALL_MENU_ITEMS":
      let menusToInclude = "";
      if (state.foodFilter) menusToInclude += " Food ";
      if (state.drinksFilter) menusToInclude += " Drinks ";
      if (state.extrasFilter) menusToInclude += " Extras ";

      const constraints = {
        name: state.searchTerm,
        category: state.filterMenuCategory,
        subcategory: state.filterMenuSubCategory,
        menu: menusToInclude,
      };

      const shouldBeIncluded = (item, constraints) => {
        for (var key in constraints) {
          var itemKey = item[key];

          var shouldFilterOut =
            itemKey === undefined ||
            itemKey === null ||
            !itemKey.toLowerCase().includes(constraints[key].toLowerCase());
          if (key === "menu") {
            shouldFilterOut = !constraints[key].includes(itemKey);
          }

          if (shouldFilterOut) return false;
        }

        return true;
      };

      const filteredItems = state.items.map((el) => {
        if (shouldBeIncluded(el, constraints)) {
          el.selected = !state.allMenuItemsSelected;
        }

        return el;
      });

      return {
        ...state,
        allMenuItemsSelected: !state.allMenuItemsSelected,
        items: filteredItems,
      };
    case "SET_VENUE":
      return { ...state, venue: action.venueId };
    case "SET_SEARCH":
      return { ...state, searchTerm: action.searchTerm };
    case "SET_SEARCH_MENU_CATEGORY":
      return {
        ...state,
        filterMenuCategory: action.searchTerm,
        filterMenuCategoryId: action.categoryId,
      };
    case "SET_SEARCH_MENU_SUBCATEGORY":
      return { ...state, filterMenuSubCategory: action.searchTerm };
    case "SET_FOOD_FILTER":
      return {
        ...state,
        foodFilter: action.foodFilter,
        filterMenuCategoryId: 0,
        filterMenuCategory: "",
        filterMenuSubCategory: "",
      };
    case "SET_DRINKS_FILTER":
      return {
        ...state,
        drinksFilter: action.drinksFilter,
        filterMenuCategoryId: 0,
        filterMenuCategory: "",
        filterMenuSubCategory: "",
      };
    case "SET_EXTRAS_FILTER":
      return {
        ...state,
        extrasFilter: action.extrasFilter,
        filterMenuCategoryId: 0,
        filterMenuCategory: "",
        filterMenuSubCategory: "",
      };
    case "SCHEDULES_LOADED":
      return { ...state, schedules: action.data };
    case "MENU_CATEGORIES_LOADED":
      return {
        ...state,
        categories: action.data.categories,
        menus: action.data.menus,
      };
    case "MENU_ITEMS_LOADING":
      return {
        ...state,
        itemsLoaded: false,
      };
    case "MENU_ITEMS_LOADED":
      action.data.forEach((item) => (item.selected = false));

      return {
        ...state,
        items: action.data === null ? [] : action.data,
        itemsLoaded: true,
      };
    case "MENU_ITEM_UPDATED":
      return { ...state, editItemModalOpen: false, editImage: undefined };
    case "OPEN_EDIT_ITEM":
      return {
        ...state,
        editItem: action.data,
        editItemModalOpen: true,
        bulkEditModalOpen: false,
      };
    case "UPDATE_ITEM_IMAGE":
      return {
        ...state,
        editImage: action.image,
      };
      break;
    case "MENU_ITEM_ADD_ERROR":
      return { ...state, itemError: action.data };
    case "MENU_SUBCATEGORIES_LOADED":
      return { ...state, subcategories: action.data };
    case "MENU_PRINTERS_LOADED":
      return { ...state, printers: action.data };
    case "MENU_CATEGORY_DELETED":
      return { ...state };
    case "MENU_SUBCATEGORY_DELETED":
      return { ...state };
    case "MENU_CATEGORY_UPDATED":
      return { ...state };
    case "MENU_SUBCATEGORY_UPDATED":
      return { ...state };
    case "MENU_CATEGORY_ADDED":
      return { ...state, menuAddError: false };
    case "MENU_CATEGORY_ADD_ERROR":
      return { ...state, menuAddError: true };
    case "MENU_ITEM_INSTOCK":
      return { ...state };
    case "MENU_SUBCATEGORY_ADDED":
      return { ...state, menuAddError: false };
    case "TOGGLE_SCHEDULE_MODAL":
      return {
        ...state,
        scheduleModalOpen: !state.scheduleModalOpen,
        bulkEditModalOpen: false,
      };
    case "TOGGLE_REORDER_MODAL":
      return {
        ...state,
        reorderModalOpen: !state.reorderModalOpen,
      };
    case "TOGGLE_BULK_EDIT_MODAL":
      return {
        ...state,
        bulkEditModalOpen: !state.bulkEditModalOpen,
        bulkEditError: "",
        bulkSaving: false,
      };
    case "TOGGLE_CATEGORY_MODAL":
      return {
        ...state,
        categoryModalOpen: !state.categoryModalOpen,
        menuAddError: false,
        bulkEditModalOpen: false,
      };
    case "TOGGLE_SUBCATEGORY_MODAL":
      return {
        ...state,
        subCategoryModalOpen: !state.subCategoryModalOpen,
        menuAddError: false,
        bulkEditModalOpen: false,
      };
    case "TOGGLE_EDIT_ITEM_MODAL":
      return {
        ...state,
        editItemModalOpen: !state.editItemModalOpen,
        editItem: undefined,
        editImage: undefined,
        deleteItemError: "",
        bulkEditModalOpen: false,
      };
    case "SCHEDULE_UPDATE_ERROR":
      return {
        ...state,
        scheduleError: action.data,
      };
    case "SCHEDULE_UPDATED":
      return { ...state, scheduleModalOpen: !state.scheduleModalOpen };
    case "MENU_ITEM_DELETE_ERROR":
      return { ...state, deleteItemError: action.data };
    case "MENU_ITEM_DELETED":
      return { ...state, editItemModalOpen: !state.editItemModalOpen };
    default:
      return state;
  }
}
