import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import actions from "../actions";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "30%",
    right: "auto",
    bottom: "auto",
    border: "none",
    borderRadius: "10px",
    transform: "translate(-30%, -20%)",
  },
  overlay: {
    backgroundColor: "rgba(50, 62, 72, 0.5)",
  },
};

class PromptUpgradeModal extends Component {
  constructor(props) {
    super(props);
    this.upgradePlan = this.upgradePlan.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  closeModal() {
    let { digitalMenuActions } = this.props;
    digitalMenuActions.togglePromptUpgradeModal("");
  }

  upgradePlan() {
    let { digitalMenuActions } = this.props;
    digitalMenuActions.togglePaymentModal(true);
  }

  render() {
    let { digitalMenus } = this.props;
    const type = digitalMenus.upgradeType
    let message = "You'll need to upgrade your plan in order to access this feature."

    switch (type) {
      case "menus":
        message = "If you'd like access to more than one menu, please update to our Standard or Premium plan."
        break;
      case "schedule":
        message = "If you'd like access menu scheduling, please update to our Premium plan."
        break;
      case "emails":
        message = "If you'd like collect customer emails, please update to our Premium plan."
        break;
      default:
        break;
    }
    
    return (
      <Modal
        isOpen={digitalMenus.promptModalOpen}
        onRequestClose={this.closeModal}
        style={customStyles}
        contentLabel="Product Checkout Modal"
        ariaHideApp={false}
        shouldCloseOnOverlayClick={false}
      >
        <div className="schedule-modal">
          <div 
            className="schedule-modal-header"
            style={{display: "flex", justifyContent: "space-between" }}
          >
            <h3 style={{display: "inline-block"}}>Upgrade</h3>
            <button 
              className="schedule-close-button" 
              onClick={this.closeModal}
              style={{display: "flex", position: "static"}}
            >
              <h3 style={{display: "inline-block"}}>X</h3>
            </button>
          </div>
          <div className="row">
            <div className="column">
              <h4 style={{margin: "50px 30px", textAlign: "center", fontSize: "24px"}}>{message}</h4>
              <div className="schedule-modal-save">
                <button className="btn btn-primary venue-action-button" onClick={this.upgradePlan}>Upgrade Plan</button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
};


export default connect (
  function (state) {
    return {
      digitalMenus: state.digitalMenus,
    };
  },
  function (dispatch) {
    return {
      digitalMenuActions: bindActionCreators(actions.digitalMenus, dispatch)
    };
  }
)(PromptUpgradeModal);