import React, { Component } from "react";
import { Card } from "react-bootstrap";

class BasicCard extends Component {
  constructor(props) {
    super(props);
    this.onButtonClick = this.onButtonClick.bind(this);
  }

  onButtonClick() {
    this.props.selectProduct(1)
  }

  render() {
    let { currentProduct } = this.props;
    let active = currentProduct === 1

    return (
        <Card 
          onClick={this.onButtonClick}
          className={ active ? "selection-card-active" : "selection-card" }
        >
          <Card.Header className="card-header">
            <Card.Text className="bold">Basic Plan</Card.Text>
            <h3 className="price">FREE</h3>
            <Card.Text>always</Card.Text>
          </Card.Header>
          <Card.Body className="card-body">
            <Card.Text>- Up and Running In Minutes</Card.Text>
            <Card.Text>- 100% Contactless Menu</Card.Text>
            <Card.Text>- Real-Time Menu Updates</Card.Text>
            <Card.Text>- Manage One Menu</Card.Text>
            <Card.Text>- Collect Customer Ratings</Card.Text>
          </Card.Body>
        </Card>
    )
  }
};


export default BasicCard;