import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import actions from "../actions";
import ItemSearch from "./ItemSearch";
import ItemBulkEditButton from './ItemBulkEditButton';

class ItemsHeader extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const hasItemSelected = this.props.menus.items.filter(el => el.selected).length > 0;
    let bulkEdit = <div className="items-header-actions-rightSide"></div>;
    if (hasItemSelected) {
      bulkEdit = <ItemBulkEditButton />
    }

    return (
      <div className="items-header">
        <div className="items-header-text">Items</div>
        <div className="items-header-actions">
          {bulkEdit}
          <ItemSearch />
          <div className="items-header-actions-rightSide"></div>
        </div>
      </div>
    );
  }
}

export default connect(
  function (state) {
    return {
      menus: state.menus,
    };
  },
  function (dispatch) {
    return {
      menuActions: bindActionCreators(actions.menus, dispatch),
    };
  }
)(ItemsHeader);
