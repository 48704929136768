import { Component } from "react";
import MenuDropdown from "./MenuDropdown";

class MenusCategorySelection extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <MenuDropdown type={"Category"} />
        <MenuDropdown type={"Sub Category"} />
      </div>
    );
  }
}
export default MenusCategorySelection;
