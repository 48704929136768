import { Component } from "react";
import ContentEditable from "react-contenteditable";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import actions from "../actions";
import sanitizeHtml from "sanitize-html";
import { confirmAlert } from "react-confirm-alert";

class CategoryEditor extends Component {
  constructor(props) {
    super(props);
    this.delete = this.delete.bind(this);
    this.up = this.up.bind(this);
    this.down = this.down.bind(this);
    this.edit = this.edit.bind(this);
  }

  edit(event) {
    const sanitizeConf = {
      allowedTags: [],
      allowedAttributes: {}
    };
    let newName = sanitizeHtml(event.target.value, sanitizeConf) 
    this.props.menuActions.editCategory(this.props.id, this.props.type, newName);
  }

  delete(event) {
    const id = event.target.id
    confirmAlert({ 
      customUI: ({ onClose }) => {
        return (
          <div className="confirm-delete-item">
            {this.props.type === "category" ? (
              <h2>This action will delete <b>ALL</b> items associated with this category, and it cannot be undone.<br/><br/>Are you sure you want to delete this category?</h2>
              ) : (
              <h2>This action will delete <b>ALL</b> items associated with this sub category, and it cannot be undone.<br/><br/>Are you sure you want to delete this sub category?</h2>
            )
            }
            <button onClick={onClose}>No</button>
            <button
              onClick={() => {
                this.props.menuActions.deleteCategory(id, this.props.type);
                onClose();
              }}
            >
              Yes
            </button>
            <br/>
            <br/>
            <h3>(If you're looking to update multiple items, select them in the items list for a bulk update)</h3>
          </div>
        );
      }
    });
  }

  up(event) {
    this.props.menuActions.changeOrder(
      event.currentTarget.id,
      this.props.type,
      "up"
    );
  }

  down(event) {
    this.props.menuActions.changeOrder(
      event.currentTarget.id,
      this.props.type,
      "down"
    );
  }

  render() {
    return (
      <tr>
        <td>
          <ContentEditable
            html={`<p style="text-align: left;" id=${this.props.id}>${this.props.name}</p>`}
            disabled={false}
            onChange={this.edit}
          />
        </td>
        <td>
          <a id={this.props.id} type={this.props.type} onClick={this.delete}>
            Delete
          </a>
        </td>
        <td>
          <a id={this.props.id} type={this.props.type} onClick={this.up}>
            <i className="fa fa-chevron-down"></i>
          </a>
        </td>
        <td>
          <a id={this.props.id} type={this.props.type} onClick={this.down}>
            <i className="fa fa-chevron-up"></i>
          </a>
        </td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
    );
  }
}

export default connect(
  function (state) {
    return {
      menus: state.menus,
    };
  },
  function (dispatch) {
    return {
      menuActions: bindActionCreators(actions.menus, dispatch),
    };
  }
)(CategoryEditor);
