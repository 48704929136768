// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.

$(document).on('turbolinks:load', function() {
  $('.fast-pass-tgl').on('click', function(){
    var elem = $(this).prev();
    var venueId = elem.data('venue');
    var available = elem.data('fast-pass-available');

    $.ajax({
      type: 'POST',
      url : "/venues/" + venueId + "/fast-pass-toggle",
      data: {fast_pass_available: !available},
      success: function(){
        elem.data('fast-pass-available', !available);
        if (!available) {
          $("#fast-pass-available").text('Fast Pass is turned on')
          $('.fast-pass-off').toggleClass('fast-pass-on')
          $('.fast-pass-off').removeClass('fast-pass-off');
        } else {
          $("#fast-pass-available").text('Fast Pass is turned off')
          $('.fast-pass-on').addClass('fast-pass-off')
          $('.fast-pass-on').removeClass('fast-pass-on');
        }
      }
    });
  });

  $('.edit-link').on('click', function() {
    var editable = $('.editing').length != 0;
    if (editable) {
      disableEditing();
    } else {
      enableEditing();
    }
  })
});

function enableEditing() {
  addEditingClass();
  addRows();
  updateTiers();
}

function addEditingClass() {
  $('table').addClass('editing');
}

function addRows() {
  var lastRow = $('table tr').last();
  var addNewTier = $('<tr><td class="add-new-tier" colspan=4><span class="glyphicon glyphicon-plus" aria-hidden="true"></span>Add New Tier</td></tr>')
  var saveButton = $('<tr><td class="save-button" colspan=4>Save</td></tr>')

  lastRow.after(saveButton);
  lastRow.after(addNewTier);

  $('.save-button').on('click', disableEditing);
  $('.add-new-tier').on('click', addRow);
}

function updateTiers() {
  var tiers = parseTiers();
  tiers.forEach(function(tier, index){
    if (tier.remaining !== tier.quantity) { // passes have been sold at this tier already
      disableRow(index);
    } else {
      updateRowForEditing(tier);
    }
  });
}

function findTierCell(index) {
  var tierStr = "Tier " + (index + 1);
  return $('td:contains(' + tierStr + ')').first();
}

function updateRowForEditing(tier) {
  var tierCell = findTierCell(tier.tier);
  var row = tierCell.parent();

  row.children().eq(1).remove();
  row.children().eq(1).remove();
  row.children().eq(1).remove();

  var venueId = $('.fast-pass-tgl').prev().data('venue');

  row.append($('<td class="editing-row" colspan=3>' +
      'I would like to sell ' +
      '<a id="quantity-' + tier.tier + '" class="quantity" data-type="text" data-pk="' + tier.tier + '" data-url="/venues/' + venueId + '/fast_passes" data-title="Enter quantity" data-name="quantity"> ' + tier.quantity + '</a>' +
      ' passes at <a id="price-' + tier.tier + '" class="price" data-type="text" data-pk="' + tier.tier + '" data-url="/venues/' + venueId + '/fast_passes" data-title="Enter price" data-name="price"> $' + tier.price.toFixed(2) + '</a>' +
      '<span class="glyphicon glyphicon-minus-sign" aria-hidden="true"></span></td>'));

  row.find($('.glyphicon-minus-sign')).on('click', deleteRow);
  $('#quantity-' + tier.tier).editable({
    mode: "inline",
    success: updatePotentialRevenue
  });
  $('#price-' + tier.tier).editable({
    mode: "inline",
    display: displayPrice, success: updatePotentialRevenue
  });
}

function deleteRow(event) {
  var venueId = $('.fast-pass-tgl').prev().data('venue');
  var row = $(event.currentTarget).parent().parent();
  var tierCell = row.children().first();
  var tier = parseInt(tierCell.text().split(' ')[1], 10) - 1;

  row.remove();
  $.ajax({
    type: 'DELETE',
    url : "/venues/" + venueId + "/fast_passes/" + tier,
    success: function(data){
      updateTierNumbers();
      updatePotentialRevenue(data);
    }
  });
}

function updateTierNumbers() {
  $('td.first-column').each(function(index) {
    $(this).text("Tier " + (index + 1));
  });
}

function disableRow(tier) {
  var tierCell = findTierCell(tier);
  var row = tierCell.parent();
  row.children().eq(1).remove();
  row.children().eq(1).remove();
  row.children().eq(1).remove();

  var disabledMessage = "At least 1 pass has been sold in this tier. Editing is disabled.";
  row.append($('<td class="disabled-row" colspan=3>' + disabledMessage + '</td>'));
}

function parseTiers() {
  var tiers = [];
  $('.fast-pass-table table tr').each(function(index) {
    if ($(this).children().length > 1) {
      var tierCell = $(this).children().first();
      var tier = parseInt(tierCell.text().split(' ')[1], 10) - 1;
      var quantityCell = $(this).children().eq(1);
      var quantity = parseInt(quantityCell.text().split(' ')[3], 10);
      var priceCell = $(this).children().eq(2);
      var price = parseFloat(priceCell.text().split(' ')[2].replace(/[^0-9\.]+/g, "")); // Number(currency.replace(/[^0-9\.]+/g,""));
      var remainingCell = $(this).children().last();
      var remaining = parseInt(remainingCell.text().split(' ')[0], 10);
      if (Object.is(remaining, NaN)) {
        remaining = 0;
      }
      tiers.push({tier: tier, quantity: quantity, price: price, remaining: remaining});
    }
  });
  return tiers;
}

function addRow() {
  var addTierRow = $('.add-new-tier').parent();
  var lastTierCell = addTierRow.prev().children().first();
  var newTier = 1;
  var venueId = $('.fast-pass-tgl').prev().data('venue');

  if (lastTierCell.length === 0) {
    addTierRow.before($('<tr><td class="first-column">Tier ' + newTier + '</td><td class="editing-row" colspan=3>' +
        'I would like to sell ' +
        '<a id="quantity-' + (newTier - 1) + '" class="quantity" data-type="text" data-pk="' + (newTier - 1) + '" data-url="/venues/' + venueId + '/fast_passes" data-title="Enter quantity" data-name="quantity"> enter quantity </a>' +
        ' passes at <a id="price-' + (newTier - 1) + '" class="price" data-type="text" data-pk="' + (newTier - 1) + '" data-url="/venues/' + venueId + '/fast_passes" data-title="Enter price" data-name="price"> enter price </a>' +
        '<span class="glyphicon glyphicon-minus-sign" aria-hidden="true"></span></td></tr>'));
    lastTierCell = addTierRow.prev().children().first();
  }

  else {
    var tierTextArray = lastTierCell.text().split(' ');
    newTier = parseInt(tierTextArray[tierTextArray.length - 1], 10) + 1;

    lastTierCell.parent().after($('<tr><td class="first-column">Tier ' + newTier + '</td><td class="editing-row" colspan=3>' +
        'I would like to sell ' +
        '<a id="quantity-' + (newTier - 1) + '" class="quantity" data-type="text" data-pk="' + (newTier - 1) + '" data-url="/venues/' + venueId + '/fast_passes" data-title="Enter quantity" data-name="quantity"> enter quantity </a>' +
        ' passes at <a id="price-' + (newTier - 1) + '" class="price" data-type="text" data-pk="' + (newTier - 1) + '" data-url="/venues/' + venueId + '/fast_passes" data-title="Enter price" data-name="price"> enter price </a>' +
        '<span class="glyphicon glyphicon-minus-sign" aria-hidden="true"></span></td></tr>'));
  }

  $('#quantity-' + (newTier - 1)).editable({
    mode: "inline",
    success: updatePotentialRevenue
  });
  $('#price-' + (newTier-1)).editable({
    mode: "inline",
    display: displayPrice, success: updatePotentialRevenue
  });
  updateTierNumbers();
  lastTierCell.parent().next().find($('.glyphicon-minus-sign')).on('click', deleteRow);
}

function updatePotentialRevenue(revenue) {
  $('.potential-revenue').html('Potential Revenue: $' + parseFloat(revenue.potentialRevenue).toFixed(2))
}

function displayPrice(value, response) {
  value = Number(value.replace(/[^0-9\.]+/g,""))
  if (!Object.is(parseFloat(value), NaN)) {
    $(this).text('$' + parseFloat(value).toFixed(2));
  }
}

function disableEditing() {
  removeEditingClass();
  removeTable();
  rebuildTable();
}

function removeEditingClass() {
  $('table').removeClass('editing');
}

function removeTable() {
  var table = $('#fast-pass-table');
  table.remove();
}

function rebuildTable() {
  var venueId = $('.fast-pass-tgl').prev().data('venue');

  $.ajax({
    type: 'GET',
    url : "/venues/" + venueId + "/fast-passes/fast-pass-tiers",
    success: function(data){
      $('.fast-pass-table').html(data);
    }
  });
}