import React, { Component } from "react";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "../reducers";
import thunk from "redux-thunk";

import MenuEditingHeaderMenu from "./MenuEditingHeaderMenu";
import MenuFilters from "./MenuFilters";
import ItemsHeader from "./ItemsHeader";
import ItemsTable from "./ItemsTable";
import actions from "../actions/menus.js";

const store = createStore(rootReducer, compose(applyMiddleware(thunk)));

class MenuRoot extends Component {
  constructor(props) {
    super(props);
    store.dispatch(actions.setVenue(props.venueId));
    store.dispatch(actions.loadCategories());
    store.dispatch(actions.loadSubCategories());
    store.dispatch(actions.loadPrinters());
    store.dispatch(actions.loadMenuItems());
    store.dispatch(actions.loadMenuSchedules());
  }

  render() {
    return (
      <Provider store={store}>
        <div id="menu-editing-root" className="menu-editing-container">
          <MenuEditingHeaderMenu />
          <MenuFilters />
          <ItemsHeader />
          <ItemsTable />
        </div>
      </Provider>
    );
  }
}
export default MenuRoot;
