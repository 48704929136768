import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import actions from "../actions";
import { Elements } from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from "./CheckoutForm";

class ChangePaymentCheckout extends Component {
  constructor(props) {
    super(props);
    this.paymentComplete = this.paymentComplete.bind(this);
    this.state = {
      paymentIntent: undefined
    }
  }

  componentDidMount() {
    let { digitalMenuActions } = this.props;
    let { id } = this.props.digitalMenus.data.venue_data;
    digitalMenuActions.updatePaymentSetup(id);
  }

  componentWillUnmount() {
    this.props.digitalMenuActions.clearPaymentSetup()
  }

  paymentComplete(payment) {
    let { digitalMenuActions } = this.props;
    let { id } = this.props.digitalMenus.data.venue_data;
    digitalMenuActions.updatePaymentComplete(id, payment,
      function () {
        this.props.clearOpen();
      }.bind(this)
  );
  }

  render() {
    let { paymentSetupInfo, data } = this.props.digitalMenus;
    let options = undefined
    if (paymentSetupInfo && paymentSetupInfo["client_secret"]) {
      options = { 
        clientSecret: paymentSetupInfo["client_secret"],
        appearance: { theme: 'stripe' }
      };
    }
    return (
      <div className="stripe-update-container">
        {
          options && data && (
            <Elements stripe={loadStripe(data.stripe_key)} options={options}>
              <CheckoutForm update={true} paymentComplete={(payment) => this.paymentComplete(payment)}/>
            </Elements>
          )
        }
      </div>
    )
  }}

  export default connect(
    function (state) {
      return {
        digitalMenus: state.digitalMenus
      };
    },
    function (dispatch) {
      return {
        digitalMenuActions: bindActionCreators(actions.digitalMenus, dispatch)
      };
    }
  )(ChangePaymentCheckout);