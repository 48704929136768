import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Modal from "react-modal";
import actions from "../actions";
import ImageUploader from "./ImageUploader";
import Loader from "./Loader";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

const customStyles = {
  content: {
    top: "30%",
    right: "auto",
    bottom: "auto",
    border: "none",
    borderRadius: "10px",
    transform: "translate(-30%, -20%)",
  },
  overlay: {
    backgroundColor: "rgba(50, 62, 72, 0.5)",
  },
};

const priceRegex = RegExp(/^\$?[0-9]+(\.[0-9][0-9])?$/);

const inititalState = {
  showAdvancedOptions: "hideAdvancedOptions",
  name: "Please enter item name",
  description: "",
  price: "Default price such as 5.00",
  menu: "food",
  link: "",
  freeRefills: false,
  selectedCategory: 0,
  selectedSubCategory: 0,
  selectedPrinter: 0,
  asModifierDropDown: 0,
  asModifierPrice: "Enter Price",
  modifiers: {},
  errors: {
    name: "",
    description: "",
    price: "",
    save: "",
    modifierprice: "",
  },
  schedules: {},
  scheduleerrors: {},
  saving: false,
  editImage: undefined,
  useLink: false
};

class ItemEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...inititalState,
      errors: {
        name: "",
        description: "",
        price: "",
        save: "",
        modifierprice: "",
        scheduleprice: "",
        link: ""
      },
      modifiers: {},
      schedules: {},
      scheduleerrors: {},
      saving: false,
      useLink: false
    };

    this.closeModal = this.closeModal.bind(this);
    this.showAdvancedOptions = this.showAdvancedOptions.bind(this);
    this.onInputClick = this.onInputClick.bind(this);
    this.onInputBlur = this.onInputBlur.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.onMenuClick = this.onMenuClick.bind(this);
    this.onFreeRefillClick = this.onFreeRefillClick.bind(this);
    this.handleCategoryChange = this.handleCategoryChange.bind(this);
    this.handlePrinterChange = this.handlePrinterChange.bind(this);
    this.resetState = this.resetState.bind(this);
    this.saveItem = this.saveItem.bind(this);
    this.onAfterOpen = this.onAfterOpen.bind(this);
    this.onModifierLimitChange = this.onModifierLimitChange.bind(this);
    this.onModifierLimitClick = this.onModifierLimitClick.bind(this);
    this.onModifierCheckboxClick = this.onModifierCheckboxClick.bind(this);
    this.onModifierDropdownChange = this.onModifierDropdownChange.bind(this);
    this.onAsModifierChange = this.onAsModifierChange.bind(this);
    this.onAsModifierClick = this.onAsModifierClick.bind(this);
    this.onScheduleCheckboxClick = this.onScheduleCheckboxClick.bind(this);
    this.onSchedulePriceClick = this.onSchedulePriceClick.bind(this);
    this.onSchedulePriceBlur = this.onSchedulePriceBlur.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
    this.onUseLinkClick = this.onUseLinkClick.bind(this);
  }

  deleteItem() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="confirm-delete-item">
            <h2>Are you sure you want to delete this item?</h2>
            <button onClick={onClose}>No</button>
            <button
              onClick={() => {
                this.props.menuActions.deleteItem(
                  this.props.editItem,
                  function () {
                    this.resetState();
                  }.bind(this)
                );
                onClose();
              }}
            >
              Yes
            </button>
          </div>
        );
      },
    });
  }

  onAsModifierChange(event) {
    this.setState({ asModifierPrice: event.target.value });
  }

  onAsModifierClick(event) {
    if (event.target.value === "Enter Price") {
      this.setState({ asModifierPrice: "" });
    }
  }

  onModifierLimitBlur(event, id) {
    const value = event.target.value;

    let updatedModifiers = this.state.modifiers;
    let error = "";

    if (this.state.modifiers[id]["selected"]) {
      let lim = parseInt(this.state.modifiers[id]["limit"], 10);
      if (!Number.isInteger(lim) || lim <= 0) {
        error = "Modifier Limit must be greater than 0 if selected";
      }
    }

    updatedModifiers[id]["limiterror"] = error;
    this.setState({
      ...this.state,
      modifiers: updatedModifiers,
    });
  }

  onModifierDropdownChange(event, id) {
    let updatedModifiers = this.state.modifiers;
    updatedModifiers[id]["item"] = event.target.value;
    this.setState({
      ...this.state,
      modifiers: updatedModifiers,
    });
  }

  onModifierLimitChange(event, id) {
    let updatedModifiers = this.state.modifiers;
    updatedModifiers[id]["limit"] = event.target.value;
    this.setState({
      ...this.state,
      modifiers: updatedModifiers,
    });
  }

  onModifierCheckboxClick(id) {
    let updatedModifiers = this.state.modifiers;

    if (updatedModifiers[id] === undefined) {
      let items = this.props.items.filter((item) => item.subcategory_id === id);

      updatedModifiers[id] = {
        id: id,
        name: items[0] !== undefined ? items[0].name : "",
        item: items[0] !== undefined ? items[0].id : 0,
        selected: false,
        limit: "",
        limiterror: "",
      };
    }

    updatedModifiers[id]["selected"] = !updatedModifiers[id]["selected"];
    this.setState({
      ...this.state,
      modifiers: updatedModifiers,
    });
  }

  onModifierLimitClick(id) {
    if (this.state.modifiers[id].limit === "Enter numeric limit") {
      let updatedModifiers = this.state.modifiers;
      updatedModifiers[id]["limit"] = "";
      this.setState({
        ...this.state,
        modifiers: updatedModifiers,
      });
    }
  }

  onAfterOpen() {
    if (this.props.editItem === undefined || this.props.editItem.id === 0) {
      let entries = {};
      let schedule_entries = {};
      let printers = this.props.printers;

      if (printers.length > 0) {
        this.setState({ selectedPrinter: printers[0].id });
      }

      let items = this.categoriesFilter(this.props.categories, this.state.menu);
      if (items.length > 0) {
        const firstCategoryId = items[0].id;
        this.setState({ selectedCategory: firstCategoryId });
        items = this.props.items.filter(
          (item) => item.category_id === firstCategoryId
        );

        items = this.props.subcategories.filter(
          (item) => item.menu_category_id === firstCategoryId
        );

        if (items.length > 0)
          this.setState({ selectedSubCategory: items[0].id });
      }

      for (const [index, value] of this.props.subcategories.entries()) {
        let items = this.props.items.filter(
          (item) => item.subcategory_id === value.id
        );

        entries[value.id] = {
          id: value.id,
          name: value.name,
          limit: "Enter numeric limit",
          selected: false,
          item: 0,
          limiterror: "",
        };
      }

      for (const [index, value] of this.props.schedules.entries()) {
        schedule_entries[value.position] = {
          name: value.name,
          retail: 0,
          selected: true,
        };
      }

      this.setState({ modifiers: entries, schedules: schedule_entries });
    } else {
      let asModifier = _.find(
        this.props.items,
        function (item) {
          let retVal =
            item.item_id === this.props.editItem.item_id &&
            item.subcategory_id !== this.props.editItem.subcategory_id;
          return retVal;
        }.bind(this)
      );

      let asModifierDropDown =
        asModifier !== undefined ? asModifier.subcategory_id : 0;
      let asModifierPrice = asModifier !== undefined ? asModifier.price : 0;

      if (parseInt(asModifierPrice, 10) > this.props.editItem.price) {
        asModifierPrice = "Enter Price";
        asModifierDropDown = 0;
      }

      let modifiers = {};
      for (const [index, value] of this.props.editItem.modifiers.entries()) {
        modifiers[value.subcategory_id] = {
          id: value.subcategory_id,
          selected: true,
          limit: value.limit,
          item: value.default_item_id,
          limiterror: "",
        };
      }

      let schedules = {};
      for (const [index, value] of this.props.schedules.entries()) {
        schedules[value.position] = {
          name: value.name,
          retail: 0,
          selected: false,
        };
      }

      for (const [
        index,
        value,
      ] of this.props.editItem.menu_item_selected_schedules.entries()) {
        schedules[value.position] = {
          name: value.name,
          retail: value.retail,
          selected: value.active,
        };
      }

      this.setState({
        name: this.props.editItem.name,
        description: this.props.editItem.description,
        price: this.props.editItem.price,
        menu: this.props.editItem.menu,
        freeRefills: this.props.editItem.free_refills || false,
        selectedCategory: this.props.editItem.category_id,
        selectedSubCategory: this.props.editItem.subcategory_id,
        selectedPrinter: this.props.editItem.printer,
        asModifierDropDown: asModifierDropDown,
        asModifierPrice: asModifierPrice,
        modifiers: modifiers,
        schedules: schedules,
        link: this.props.editItem.link,
        useLink: this.props.editItem.link.length > 0
      });
    }
  }

  validateForm() {
    let asModifierDropDownValid = true;
    if (
      !(
        this.state.asModifierDropDown === 0 ||
        this.state.asModifierDropDown === "0"
      )
    ) {
      if (!priceRegex.test(this.state.asModifierPrice)) {
        asModifierDropDownValid = false;
      }
    }

    let modifierLimitsValid = true;
    for (const [index, value] of this.props.subcategories.entries()) {
      if (this.state.modifiers[value.id] !== undefined) {
        let lim = parseInt(this.state.modifiers[value.id]["limit"], 10);
        if (
          this.state.modifiers[value.id]["selected"] === true &&
          this.state.modifiers[value.id].limiterror !== undefined &&
          (!Number.isInteger(lim) || lim <= 0)
        ) {
          modifierLimitsValid = false;
        }
      }
    }

    let schedulePricesValid = true;
    for (const [index, value] of this.props.schedules.entries()) {
      if (this.state.schedules[index] !== undefined) {
        if (
          this.state.schedules[index].selected &&
          !priceRegex.test(this.state.schedules[index].retail)
        ) {
          schedulePricesValid = false;
        }
      }
    }

    let linkValid = true;
    if (this.state.useLink && this.state.link.indexOf("https://") != 0) {
      errors.link = "Link must begin with 'https://'";
      linkValid= false;
    }

    return (
      this.state.name.length > 0 &&
      this.state.name !== inititalState.name &&
      priceRegex.test(this.state.price) &&
      asModifierDropDownValid &&
      modifierLimitsValid &&
      schedulePricesValid &&
      linkValid
    );
  }

  saveItem() {
    if (this.validateForm()) {
      this.setState(
        {
          editImage: this.props.editImage,
          editItem: this.props.editItem !== undefined,
          itemId:
            this.props.editItem === undefined ? 0 : this.props.editItem.id,
          saving: true,
          link: this.state.useLink ? this.state.link : ""
        },
        function () {
          this.props.menuActions.updateItem(
            this.state,
            function () {
              this.resetState();
            }.bind(this)
          );
        }
      );
    } else {
      let errors = this.state.errors;
      errors.save = "Please make sure to fill out the required fields";
      this.setState({ errors });
    }
  }

  resetState() {
    this.setState({
      ...inititalState,
      errors: {
        name: "",
        description: "",
        price: "",
        save: "",
        modifierprice: "",
        scheduleprice: "",
      },
      modifiers: {},
      schedules: {},
      scheduleerrors: {},
      saving: false,
    });
  }

  onInputClick(event, name) {
    if (event.target.value === inititalState[name]) {
      this.setState({ [name]: "" });
    }
  }

  printerFilter(printerName) {
    let printers = this.props.printers.filter(
      (item) => item.name === printerName
    );

    if (printers.length > 0) return printers[0].id;
    else return this.props.printers[0].id;
  }

  onMenuClick(event) {
    let items = this.categoriesFilter(
      this.props.categories,
      event.target.value
    );

    let printerId = this.printerFilter(event.target.value);

    const firstCategoryId = items[0].id;
    this.setState({
      menu: event.target.value,
      selectedCategory: firstCategoryId,
      selectedPrinter: printerId,
    });

    items = this.props.subcategories.filter(
      (item) => item.menu_category_id === firstCategoryId
    );

    if (items.length > 0) this.setState({ selectedSubCategory: items[0].id });
  }

  onFreeRefillClick() {
    this.setState({ freeRefills: !this.state.freeRefills });
  }

  onUseLinkClick() {
    this.setState({ useLink: !this.state.useLink });
  }

  closeModal() {
    let { menuActions } = this.props;
    this.resetState();
    menuActions.toggleItemEditingModal();
  }

  showAdvancedOptions() {
    if (this.state.showAdvancedOptions === "showAdvancedOptions") {
      this.setState({ showAdvancedOptions: "hideAdvancedOptions" });
    } else {
      this.setState({ showAdvancedOptions: "showAdvancedOptions" });
    }
  }

  handlePrinterChange(event) {
    this.setState({
      selectedPrinter: event.target.value,
    });
  }

  handleCategoryChange(event) {
    if (event.target.id === "menu-category") {
      let items = this.props.subcategories.filter(
        (item) => item.menu_category_id === parseInt(event.target.value, 10)
      );

      this.setState({
        selectedCategory: event.target.value,
        selectedSubCategory: items[0].id,
      });
    } else if (event.target.id === "menu-subcategory") {
      this.setState({ selectedSubCategory: event.target.value });
    } else {
      this.setState({ asModifierDropDown: event.target.value });
    }
  }

  onInputBlur(event, name, id = 0) {
    let errors = this.state.errors;
    const value = event.target.value;

    switch (name) {
      case "name":
        errors.name = value.length === 0 ? "Item Name must be filled out" : "";
        break;
      case "price":
        errors.price = priceRegex.test(value)
          ? ""
          : "Item Price must match USD format like $5.00";
        break;
      case "link":
        let useLink = this.state.useLink
        let link = this.state.link
        if (useLink && link.indexOf("https://") != 0) {
          errors.link = "Link must begin with 'https://'"
        }
        break;
      case "modifier-price":
        if (
          this.state.asModifierDropDown === 0 ||
          this.state.asModifierDropDown === "0"
        ) {
          errors.modifierprice = "";
        } else {
          if (priceRegex.test(value)) {
            errors.modifierprice = "";
          } else {
            errors.modifierprice =
              "Modifier Price must match USD format like $5.00";
          }
        }
        break;
    }

    this.setState({ errors });
  }

  handleInputChange(event, name) {
    let schedule_entries = {};
    if (this.props.editItem === undefined || this.props.editItem.id === 0) {
      if (event.target.id === "price") {
        for (const [index, value] of this.props.schedules.entries()) {
          schedule_entries[value.position] = {
            name: value.name,
            retail: event.target.value,
            selected: true,
          };
        }
        this.setState({ schedules: schedule_entries });
      }
    }
    this.setState({ [name]: event.target.value });
  }

  categoryDropDown(items, name, addBlank = false) {
    var options = [];
    var dropdown = undefined;

    if (addBlank) {
      options.push(
        <option key={0} value={0}>
          Select Modifier Type
        </option>
      );
    }

    for (const [index, value] of items.entries()) {
      options.push(
        <option key={index + 1} value={value.id}>
          {value.name}
        </option>
      );
    }

    let selectedDropdown = "selectedCategory";
    if (name === "menu-subcategory") {
      selectedDropdown = "selectedSubCategory";
    }
    if (name === "menu-asmodifier") {
      selectedDropdown = "asModifierDropDown";
    }

    dropdown = (
      <select
        name={name}
        id={name}
        value={this.state[selectedDropdown]}
        onChange={this.handleCategoryChange}
      >
        {options}
      </select>
    );

    return dropdown;
  }

  modifierCheckbox(id, value) {
    return (
      <div className="smaller-column">
        <div className="checkbox">
          <input
            id={"modifier-add-" + id}
            name={"modifier-add-" + id}
            type="checkbox"
            onChange={() => this.onModifierCheckboxClick(id)}
            checked={
              this.state.modifiers[id] !== undefined
                ? this.state.modifiers[id].selected
                : false
            }
          />
          <label htmlFor={"modifier-add-" + id}>{value}</label>
        </div>
      </div>
    );
  }

  modifierDropdown(id) {
    let options = [];
    options.push(
      <option key={`${id}-0`} value="default" data-id={0}>
        No default
      </option>
    );

    let items = this.props.items.filter((item) => item.subcategory_id === id);

    for (const [index, value] of items.entries()) {
      options.push(
        <option key={index} value={value.id}>
          {value.name}
        </option>
      );
    }

    return (
      <div className="smaller-column">
        <select
          name={"default-modifier-" + id}
          id={"default-modifier-" + id}
          value={
            this.state.modifiers[id] !== undefined
              ? this.state.modifiers[id].item
              : 0
          }
          onChange={(event) => this.onModifierDropdownChange(event, id)}
        >
          {options}
        </select>
      </div>
    );
  }

  modifierLimit(id) {
    return (
      <div className="smaller-column">
        <input
          id={"modifier-limit-" + id}
          name={"modifier-limit-" + id}
          type="text"
          onBlur={(event) => this.onModifierLimitBlur(event, id)}
          onChange={(event) => this.onModifierLimitChange(event, id)}
          onClick={() => this.onModifierLimitClick(id)}
          value={
            this.state.modifiers[id] !== undefined
              ? this.state.modifiers[id]["limit"]
              : ""
          }
        />
        {this.state.modifiers[id] !== undefined &&
          this.state.modifiers[id]["limiterror"].length > 0 && (
            <span className="error">
              {this.state.modifiers[id]["limiterror"]}
            </span>
          )}
      </div>
    );
  }

  modifierRow(index, obj) {
    return (
      <div key={index} className={"row " + this.state.showAdvancedOptions}>
        <div className="bigger-column">
          {this.modifierCheckbox(obj.id, obj.name)}
          {this.modifierDropdown(obj.id)}
          {this.modifierLimit(obj.id)}
        </div>
      </div>
    );
  }

  modifierItems(items) {
    var options = [];
    items = items.sort(function (a, b) {
      return a.name > b.name ? 1 : -1;
    });

    for (const [index, value] of items.entries()) {
      options.push(this.modifierRow(index, value));
    }

    var blah = <div>{options}</div>;
    return blah;
  }

  onScheduleCheckboxClick(event, index) {
    let schedules = this.state.schedules;
    schedules[index].selected = !schedules[index].selected;
    this.setState({ schedules: schedules });
  }

  onSchedulePriceClick(event, index) {
    let schedules = this.state.schedules;
    schedules[index].retail = event.target.value;
    this.setState({ schedules: schedules });
  }

  onSchedulePriceBlur(event, index) {
    let errors = this.state.scheduleerrors;
    const value = event.target.value;

    if (this.state.schedules[index].selected && priceRegex.test(value)) {
      errors[index] = "";
    } else {
      errors[index] = "Price must match USD format like $5.00";
    }

    this.setState({ errors });
  }

  categoryPrinters(items, name) {
    var options = [];
    var dropdown = undefined;

    for (const [index, value] of items.entries()) {
      options.push(
        <option key={index + 1} value={value.id}>
          {value.name.charAt(0).toUpperCase() + value.name.slice(1)}
        </option>
      );
    }

    dropdown = (
      <select
        name={name}
        id={name}
        value={this.state.selectedPrinter}
        onChange={this.handlePrinterChange}
      >
        {options}
      </select>
    );

    return dropdown;
  }

  addSchedule(index, name) {
    const { scheduleerrors } = this.state;
    return (
      <div key={index} className={"row " + this.state.showAdvancedOptions}>
        <div className="bigger-column">
          <div className="smaller-column">
            <div className="checkbox">
              <input
                id={"menu-item-schedule-selected-" + index}
                name={"menu-item-schedule-selected-" + index}
                type="checkbox"
                onChange={(event) => this.onScheduleCheckboxClick(event, index)}
                checked={
                  this.state.schedules[index] !== undefined
                    ? this.state.schedules[index].selected
                    : false
                }
              />
              <label htmlFor={"menu-item-schedule-selected-" + index}>
                {name}
              </label>
            </div>
          </div>
          <div className="smaller-column">
            <input
              id={"menu-item-schedule-price-" + index}
              name={"menu-item-schedule-price-" + index}
              type="text"
              onBlur={(event) => this.onSchedulePriceBlur(event, index)}
              onChange={(event) => this.onSchedulePriceClick(event, index)}
              disabled={name === "Default"}
              value={
                this.state.schedules[index] !== undefined
                  ? this.state.schedules[index].retail
                  : "Enter Price"
              }
            />
            {this.state.schedules[index] !== undefined &&
              this.state.schedules[index].selected &&
              scheduleerrors[index] !== undefined &&
              scheduleerrors[index].length > 0 && (
                <span className="error">{scheduleerrors[index]}</span>
              )}
          </div>
          <div className="smaller-column"></div>
        </div>
      </div>
    );
  }

  addSchedules() {
    var options = [];
    for (const [index, value] of this.props.schedules.entries()) {
      options.push(this.addSchedule(index, value.name));
    }
    return options;
  }

  categoriesFilter(items, menuName) {
    return _.filter(
      items,
      function (item) {
        const menu = _.find(this.props.menus.menus, function (menu) {
          return menuName.toLowerCase() === menu.name.toLowerCase();
        });

        return item.menu_id === menu.id;
      }.bind(this)
    );
  }

  subcategoriesFilter(items, categories) {
    return _.filter(
      items,
      function (item) {
        return this.find((x) => x.id === item.menu_category_id) !== undefined;
      }.bind(categories)
    );
  }

  render() {
    let items = this.categoriesFilter(this.props.categories, this.state.menu);
    var categories = this.categoryDropDown(items, "menu-category");

    items = this.subcategoriesFilter(this.props.subcategories, items);

    if (this.state.selectedCategory) {
      items = _.filter(
        this.props.subcategories,
        function (item) {
          return (
            item.menu_category_id === parseInt(this.state.selectedCategory, 10)
          );
        }.bind(this)
      );
    }

    const { errors } = this.state;

    let subcategories = this.categoryDropDown(items, "menu-subcategory");
    let printers = this.categoryPrinters(this.props.printers, "printer");

    var extras_subcategories = _.filter(this.props.subcategories, function (
      item
    ) {
      return item.menu_name === "Extras";
    });

    var modifiers = this.categoryDropDown(
      extras_subcategories,
      "menu-asmodifier",
      true
    );

    var addModifiersHeader = (
      <div className={"row " + this.state.showAdvancedOptions}>
        <div className="bigger-column">
          <div className="smaller-column">
            <p>Add Modifiers</p>
          </div>
          <div className="smaller-column">
            <p>Default</p>
          </div>
          <div className="smaller-column">
            <p>Limit</p>
          </div>
        </div>
      </div>
    );

    var asModifier = (
      <div className={"row " + this.state.showAdvancedOptions}>
        <div className="column">
          <label htmlFor="as-modifier">As Modifier? (optional)</label>
          {modifiers}
          <input
            id="modifier-price"
            name="modifier-price"
            type="text"
            value={this.state.asModifierPrice}
            onBlur={(event) => this.onInputBlur(event, "modifier-price")}
            onChange={this.onAsModifierChange}
            onClick={this.onAsModifierClick}
          />
          {errors.modifierprice !== undefined &&
            errors.modifierprice.length > 0 && (
              <span className="error">{errors.modifierprice}</span>
            )}
        </div>
        <div className="column"></div>
      </div>
    );

    var useLink = (
      <div className={"row " + this.state.showAdvancedOptions}>
        <div className="row">
          <div className="column">
            <div className="checkbox">
              <input
                id="use-link"
                name="use-link"
                type="checkbox"
                onClick={this.onUseLinkClick}
                checked={this.state.useLink}
              />
              <label htmlFor="use-link">Use link? <span style={{fontWeight: "bold"}}>Warning: this will disable the normal item features</span></label>
            </div>
          </div>
        </div>
        { this.state.useLink &&
          <div className="row">
            <div className="column">
              <label htmlFor="link">Link (Must start with with "https://")</label>
              <input
                id="link"
                name="link"
                type="text"
                value={this.state.link}
                onBlur={(event) => this.onInputBlur(event, "link")}
                onChange={(event) => this.handleInputChange(event, "link")}
                onClick={(event) => this.onInputClick(event, "link")}
              />
              {errors.link !== undefined && errors.link.length > 0 && (
                <span className="error">{errors.link}</span>
              )}
            </div>
          </div>
        }
      </div>
    );

    var addModifiers = (
      <div>{this.modifierItems(this.props.subcategories)}</div>
    );

    if (this.state.menu.toLowerCase() === "extras") {
      useLink = <div />;
      addModifiers = <div />;
      addModifiersHeader = <div />;
      asModifier = <div />;
    }

    var schedules = this.addSchedules();

    let saveButton = <button onClick={this.saveItem}>Save</button>;
    if (this.state.saving) {
      saveButton = <Loader />;
    }

    let deleteButton = (
      <button className="delete-button" onClick={this.deleteItem}>
        Delete
      </button>
    );

    return (
      <Modal
        isOpen={this.props.menus.editItemModalOpen}
        onAfterOpen={this.onAfterOpen}
        onRequestClose={this.closeModal}
        style={customStyles}
        contentLabel="Item Editing Modal"
        ariaHideApp={false}
        shouldCloseOnOverlayClick={false}
      >
        <div className="schedule-modal item-modal">
          <div className="schedule-modal-header">
            <span>Invisible Span</span>
            <h3>
              {this.props.editItem === undefined
                ? "Create New Item"
                : "Edit Item"}
            </h3>
            <button className="schedule-close-button" onClick={this.closeModal}>
              <h3>X</h3>
            </button>
          </div>
          <p>You can add a new menu item by filling out the details below</p>
          <div className="row">
            <div className="column">
              <label htmlFor="name">Item Name*</label>
              <input
                id="name"
                name="name"
                type="text"
                value={this.state.name}
                onBlur={(event) => this.onInputBlur(event, "name")}
                onChange={(event) => this.handleInputChange(event, "name")}
                onClick={(event) => this.onInputClick(event, "name")}
              />
              {errors.name !== undefined && errors.name.length > 0 && (
                <span className="error">{errors.name}</span>
              )}
            </div>
            <div className="column">
              <div className="radio-toolbar">
                <p>Select Menu*</p>
                <ul className="menu-option">
                  <li>
                    <input
                      id="menu-option-food"
                      name="menu-options-food"
                      type="radio"
                      value="food"
                      onChange={this.onMenuClick}
                      checked={this.state.menu.toLowerCase() === "food"}
                    />
                    <label htmlFor="menu-option-food">Food</label>
                  </li>
                  <li>
                    <input
                      id="menu-option-drink"
                      name="menu-options-drink"
                      type="radio"
                      value="drinks"
                      onChange={this.onMenuClick}
                      checked={this.state.menu.toLowerCase() === "drinks"}
                    />
                    <label htmlFor="menu-option-drink">Drink</label>
                  </li>
                  <li>
                    <input
                      id="menu-option-extra"
                      name="menu-options-extra"
                      type="radio"
                      value="extras"
                      onChange={this.onMenuClick}
                      checked={this.state.menu.toLowerCase() === "extras"}
                    />
                    <label htmlFor="menu-option-extra">Mods/Extras</label>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="column">
              <label htmlFor="description">Description</label>
              <textarea
                id="description"
                name="description"
                rows={5}
                cols={20}
                placeholder={"Please enter item description"}
                value={this.state.description}
                onBlur={(event) => this.onInputBlur(event, "description")}
                onChange={(event) =>
                  this.handleInputChange(event, "description")
                }
                onClick={(event) => this.onInputClick(event, "description")}
              ></textarea>
            </div>
            <div className="column">
              <label htmlFor="menu-category">Select Category*</label>
              {categories}
              <label htmlFor="menu-sub-category">Select Sub-Category*</label>
              {subcategories}
            </div>
          </div>
          <div className="row">
            <div className="column">
              <label htmlFor="image">Item Image</label>
              <ImageUploader />
            </div>
            <div className="column">
              <label htmlFor="price">Default Price*</label>
              <input
                id="price"
                name="price"
                type="text"
                value={this.state.price}
                onBlur={(event) => this.onInputBlur(event, "price")}
                onChange={(event) => this.handleInputChange(event, "price")}
                onClick={(event) => this.onInputClick(event, "price")}
              />
              {errors.price !== undefined && errors.price.length > 0 && (
                <span className="error">{errors.price}</span>
              )}

              {this.state.menu.toLowerCase() !== "extras" ? <label htmlFor="printer">Select Printer*</label> : ''}
              {this.state.menu.toLowerCase() !== "extras" ? printers : ''}

              <div className="checkbox">
                <input
                  id="refills"
                  name="refills"
                  type="checkbox"
                  onChange={this.onFreeRefillClick}
                  checked={this.state.freeRefills}
                />
                <label htmlFor="refills">Free Refills?</label>
              </div>
              <a onClick={this.showAdvancedOptions}>
                {this.state.showAdvancedOptions === "hideAdvancedOptions"
                  ? "Show "
                  : "Hide "}
                Advanced Options
              </a>
            </div>
          </div>
          {useLink}
          {asModifier}
          {addModifiersHeader}
          {addModifiers}
          <div className={"row " + this.state.showAdvancedOptions}>&nbsp;</div>
          <div className={"row " + this.state.showAdvancedOptions}>&nbsp;</div>
          <div className={"row " + this.state.showAdvancedOptions}>
            <div className="bigger-column">
              <div className="smaller-column">
                <p>Availability</p>
              </div>
              <div className="smaller-column">
                <p>Default Price</p>
              </div>
              <div className="smaller-column"></div>
            </div>
          </div>
          {schedules}
          <div className="row">
            <div className="schedule-modal-save">{saveButton}</div>
            {this.props.editItem !== undefined && <div>{deleteButton}</div>}
          </div>
          <div className="row">
            {errors.save !== undefined && errors.save.length > 0 && (
              <span className="error">{errors.save}</span>
            )}
            {this.props.itemSaveError.length > 0 && (
              <span className="error">{this.props.itemSaveError}</span>
            )}
            {this.props.deleteItemError.length > 0 && (
              <span className="error">{this.props.deleteItemError}</span>
            )}
          </div>
          <div className="row">
            <div className="schedule-modal-cancel">
              <button onClick={this.closeModal}>Cancel</button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default connect(
  function (state) {
    return {
      menus: state.menus,
      categories: state.menus.categories,
      subcategories: state.menus.subcategories,
      printers: state.menus.printers,
      items: state.menus.items,
      itemSaveError: state.menus.itemError,
      editItem: state.menus.editItem,
      editImage: state.menus.editImage,
      schedules: state.menus.schedules,
      deleteItemError: state.menus.deleteItemError,
    };
  },
  function (dispatch) {
    return {
      menuActions: bindActionCreators(actions.menus, dispatch),
    };
  }
)(ItemEditor);
