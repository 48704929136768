import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import QrOrder from "./QrOrder";
import actions from "../actions";
import consumer from "../../cable";

class QrOrdersList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.intervalID = 0;
  }

  componentWillUnmount() {
    this.state.tabSub.unsubscribe();
    clearInterval(this.intervalID);
  }

  componentDidMount() {
    let { tabActions } = this.props;
    tabActions.load();

    let tabSub = consumer.subscriptions.create(
      { channel: "TabChannel", venue: window.venue.id },
      {
        received(data) {
          if (data["add"] == true) {
            tabActions.add(data["tab"]);
          } else if (data["update"] == true) {
            tabActions.update(data["tab"]);
          }
        },
      }
    );

    this.setState({ tabSub: tabSub });

    this.intervalID = setInterval(() => {
      tabActions.load(true);
    }, 1000 * 60);
  }

  render() {
    let { tabs } = this.props;
    let qrOrders = <div>There are no orders available for text message notifications at the moment</div>;

    let tabData = _.filter(tabs.data, (tab) => {
      const orders = _.filter(tab.placed_orders, (order) => {
        return (order.notification_status !== "completed") && order.print_qr;
      });

      return orders.length > 0;
    });

    if (tabData !== undefined && tabData.length > 0) {
      qrOrders = tabData.map((tab) => {
        return <QrOrder key={tab.id} tab={tab} />;
      });
    }
    return <div className="qrorders-container">{qrOrders}</div>;
  }
}

function mapStateToProps(state) {
  return { tabs: state.tabs };
}

const QrOrdersContainer = connect(mapStateToProps, function (dispatch) {
  return {
    tabActions: bindActionCreators(actions.tabs, dispatch),
  };
})(QrOrdersList);

export default QrOrdersContainer;
