let initialState = {
  data: [],
  filter: "ALL",
  loading: true
}
export default function todos(state = initialState, action) {
  switch (action.type) {
    case 'ACTIVITIES_LOADED':
      return {...state, data: action.data, loading: false}
    case 'ACTIVITIES_FILTER':
      return {...state, filter: action.filter};
    case 'ACTIVITIES_LOADING':
      return {...state, loading: true};
    case 'ACTIVITIES_FAILED':
      return {...state, loading: true};
    case 'ACTIVITIES_ADD':
      return {...state, data: [action.activity, ...state.data]};
    default:
      return state
  }
}