import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import actions from "../actions";
import { Elements } from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from "./CheckoutForm";
import { Collapse } from "react-bootstrap";
import DefaultCheckbox from "./DefaultCheckbox";

class UpdateProductCheckout extends Component {
  constructor(props) {
    super(props);
    this.paymentComplete = this.paymentComplete.bind(this);
    this.toggleDefault = this.toggleDefault.bind(this);
    this.state = {
      useDefault: true
    }
  }

  componentDidMount() {
    let { digitalMenuActions, product, isMonthly, updateAction } = this.props;
    const venueId = this.props.digitalMenus.data.venue_data.id
    const addVenue = updateAction === "add_venue"

    digitalMenuActions.getStripeInfo()
    digitalMenuActions.updateProductPaymentSetup(venueId, product, isMonthly, addVenue);
  }

  componentWillUnmount() {
    this.props.digitalMenuActions.clearStripeInfo()
    this.props.digitalMenuActions.clearPaymentSetup()
  }

  toggleDefault() {
    let check = this.state.useDefault;
    this.setState({...this.state, useDefault: !check});
  }

  paymentComplete(payment) {
    let { digitalMenuActions, digitalMenus, updateAction, product, isMonthly, venueName } = this.props;
    let { data, paymentSetupInfo, stripeInfo } = digitalMenus;
    let defaultCheck = false;
    let name = ""

    if (stripeInfo.default && this.state.useDefault) {
      payment = paymentSetupInfo
      defaultCheck = true
    }

    if(venueName) {
      name = venueName
    }

    const updateData = {
      venueId: data.venue_data.id,
      useDefault: defaultCheck, 
      payment: payment,
      updateAction: updateAction,
      product: product,
      monthly: isMonthly,
      venueName: name
    }

    digitalMenuActions.updateProductPaymentComplete(updateData,
      function (venueId) {
        window.location.assign(`${window.location.origin}/venues/${venueId}/digital_menus`)
      }.bind(this)
    );
  }

  render() {
    let { paymentSetupInfo, data, stripeInfo } = this.props.digitalMenus;
    let { updateAction } = this.props;
    let { useDefault } = this.state;
    let showForm = !(stripeInfo && stripeInfo.default && useDefault)
    let options = undefined
    let amount = undefined
    let prorateCheck = false
    let downgradeProductCheck = (updateAction === "downgrade_plan") || (updateAction === "upgrade_freq")
    let message = <p style={{ textAlign: "center", fontSize: "12px" }}></p>

    if (paymentSetupInfo && paymentSetupInfo["client_secret"]) {
      options = { 
        clientSecret: paymentSetupInfo["client_secret"],
        appearance: { theme: 'stripe' }
      };
      if (paymentSetupInfo["metadata"]) {
        amount = (parseFloat(paymentSetupInfo["metadata"]["amount"]) / 100).toFixed(2)
        prorateCheck = paymentSetupInfo["metadata"]["prorate"] === "true"
      }
    }
    
    if (prorateCheck) {
      message = <p style={{ textAlign: "center", fontSize: "12px" }}>Current price reflects prorated cost. Full amount will be charged next billing cycle.</p>
    } else if (downgradeProductCheck && !prorateCheck) {
      message = <p style={{ textAlign: "center", fontSize: "12px" }}>Current plan will be active until next billing cycle. Updated price will be charged next billing cycle.</p>
    }
    
    return (
      <div className="dm-checkout">

        { data && stripeInfo && stripeInfo.default && (
            <DefaultCheckbox 
              defaultPayment={stripeInfo.default}
              useDefault={useDefault}
              toggleDefault={this.toggleDefault}
              amount={amount}
              paymentComplete={(payment) => this.paymentComplete(payment)}
            />
          )
        }
        <Collapse in={showForm} > 
          <div>
          {
            options && data && (
              <Elements stripe={loadStripe(data.stripe_key)} options={options}>
                <CheckoutForm
                  type={"setup"} 
                  amount={amount} 
                  paymentComplete={(payment) => this.paymentComplete(payment)}
                />
              </Elements>
            )
          }
          </div>
        </Collapse>
        <div style={{width: "100%", margin: "10px 0"}}>
          { message }
        </div>
      </div>
    )
  }}

export default connect(
  function (state) {
    return {
      digitalMenus: state.digitalMenus
    };
  },
  function (dispatch) {
    return {
      digitalMenuActions: bindActionCreators(actions.digitalMenus, dispatch)
    };
  }
)(UpdateProductCheckout);

