import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import actions from "../actions";

class ItemsTableHeader extends Component {
  constructor(props) {
    super(props);
    this.toggleAllMenuItems = this.toggleAllMenuItems.bind(this);
  }

  toggleAllMenuItems() {
    this.props.menuActions.toggleAllMenuItems();
  }

  render() {
    return (
      <thead>
        <tr>
          <th><input type="checkbox" onChange={this.toggleAllMenuItems} checked={
            this.props.menus.allMenuItemsSelected !== undefined
              ? this.props.menus.allMenuItemsSelected
              : false} /></th>
          <th>Name</th>
          <th>Price</th>
          <th>Menu</th>
          <th>Category</th>
          <th>Sub Category</th>
          <th>Image</th>
          <th>Availability</th>
        </tr>
      </thead>
    );
  }
}

export default connect(
  function (state) {
    return {
      menus: state.menus,
    };
  },
  function (dispatch) {
    return {
      menuActions: bindActionCreators(actions.menus, dispatch),
    };
  }
)(ItemsTableHeader);
