$(document).on('turbolinks:load', function() {
  $("#daily-datepicker").datetimepicker({format: 'L'});
  $("#daily-datepicker").on('dp.hide', function (e) {
    window.location.replace("/reporting/" + $(this).children().first().attr('data-target') + "?venue_id=" + $(this).children().first().attr('data-venue') + "&day=" + e.date.toISOString());
  });

  $('#start-timepicker').datetimepicker({
    icons: {
      previous: 'fa fa-arrow-left',
      next: 'fa fa-arrow-right',
      time: "fa fa-clock-o",
      date: "fa fa-calendar",
      up: "fa fa-arrow-up",
      down: "fa fa-arrow-down"
    },
    format: 'YYYY-MM-DD hh:mm A'
  });

  $('#end-timepicker').datetimepicker({
    icons: {
      previous: 'fa fa-arrow-left',
      next: 'fa fa-arrow-right',
      time: "fa fa-clock-o",
      date: "fa fa-calendar",
      up: "fa fa-arrow-up",
      down: "fa fa-arrow-down"
    },
    format: 'YYYY-MM-DD hh:mm A'
  });

  $(" #set-time-range").on('click', function () {
    window.location.replace("/reporting/" + $(this).attr('data-target') + "?venue_id=" + $(this).attr('data-venue') + "&shift_start=" + $("#start-timepicker").data("date") + "&shift_end=" + $("#end-timepicker").data("date"));
  });

  //Build chart for report home page
  var chartElement = document.getElementById('salesChart');
  if (chartElement) {
    new Chart(chartElement, {
      type: 'line',
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              callback: function (value, index, values) {
                if (parseInt(value) > 1000) {
                  return '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                } else {
                  return '$' + value;
                }
              }
            }
          }]
        }
      },
      data: {
        labels: window.weeks,
        datasets: [{
          label: 'Gross Sales',
          data: window.sales,
          backgroundColor: [
            'rgba(121,203,239, 0.4)'
          ],
          borderColor: [
            'rgba(121,203,239,1)'
          ],
          borderWidth: 1
        },
        {
          label: 'Deposit Total',
          data: window.deposits,
          backgroundColor: [
            'rgba(150,250,200, 0.4)'
          ],
          borderColor: [
            'rgba(150,250,200,1)'
          ],
          borderWidth: 1
        }]
      }
    })
  }

});
