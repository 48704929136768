import { Component } from "react";
import Order from "./Order";
import moment from "moment";
import { Collapse } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";

class Tab extends Component {
  constructor(props) {
    super(props);
    this.valid = this.valid.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.state = { refundAmount: 0, refundError: "" };
  }

  refundChanged(e) {
    this.setState({ refundAmount: e.target.value });
  }

  valid() {
    let { tab, tabActions } = this.props;
    var r = /^\$?[0-9]+(\.[0-9][0-9])?$/;
    let amt = parseFloat(this.state.refundAmount);
    let visibleTotal = tab.visible_total;

    if (visibleTotal === undefined || visibleTotal === null) {
      visibleTotal = 0;
    }

    if (typeof visibleTotal === "string") {
      visibleTotal = parseFloat(visibleTotal);
    }

    return (
      r.test(this.state.refundAmount) &&
      amt <= visibleTotal.toFixed(2) &&
      amt > 0
    );
  }

  async refundClick() {
    if (this.valid()) {
      let { tab, tabActions } = this.props;
      let visibleTotal = tab.visible_total;

      if (visibleTotal === undefined || visibleTotal === null) {
        visibleTotal = 0;
      }

      if (typeof visibleTotal === "string") {
        visibleTotal = parseFloat(visibleTotal).toFixed(2);
      }

      this.setState({ refundError: "" });
      const status = await tabActions.transactionStatus(tab);
      if (
        status === "submitted_for_settlement" &&
        parseFloat(this.state.refundAmount).toFixed(2) ===
          visibleTotal.toFixed(2)
      ) {
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className="confirm-close-tabs">
                <h2>Do you wish to perform a void or a refund?</h2>
                <button
                  onClick={() => {
                    tabActions.refundTab(
                      tab,
                      this.state.refundAmount,
                      "refund"
                    );
                    onClose();
                  }}
                >
                  REFUND
                </button>
                <button
                  onClick={() => {
                    tabActions.refundTab(tab, this.state.refundAmount, "void");
                    onClose();
                  }}
                >
                  VOID
                </button>
              </div>
            );
          },
        });
      } else {
        tabActions.refundTab(tab, this.state.refundAmount, "refund");
      }
    } else {
      this.setState({
        refundError:
          "Please select a dollar amount less than or equal to the tab total",
      });
    }
  }

  handleClick(e) {
    let { tab, tabActions } = this.props;
    if (e.target.id && e.target.id === "closeButton") {
      tabActions.markTabComplete(tab.id);
    } else {
      tabActions.setOpen(tab.id);
    }
  }

  render() {
    let { tab, tabActions, isOpen, enableTabComplete, disablePrinter } = this.props;
    let visibleTotal = tab.visible_total;

    if (visibleTotal === undefined || visibleTotal === null) {
      visibleTotal = 0;
    }

    if (typeof visibleTotal === "string") {
      visibleTotal = parseFloat(visibleTotal).toFixed(2);
    }

    return (
      <div className="panel panel-default">
        <table
          className="table cursor"
          onClick={(e) => this.handleClick(e)}
        >
          <tbody>
            <tr>
              <td>
                <h1 className="tabs-name">
                  {tab.customer ? tab.customer.full_name : tab.customer_name}
                </h1>
              </td>
              <td>
                <h1 className="tabs-name">
                  {tab.table_number ? `TABLE #${tab.table_number === 'qrcode' ? 'Pickup' : tab.table_number }` : ""}
                </h1>
              </td>
              <td>
                <h1 className="tabs-name refunded">
                  {tab.refund_status
                    ? tab.refund_amount === 0 || tab.refund_amount === "0"
                      ? `VOIDED`
                      : !tab.refund_needed
                      ? `REFUNDED ${tab.refund_amount}`
                      : `PENDING REFUND $${tab.refund_amount.toFixed(2)}`
                    : ""}
                </h1>
              </td>
              { enableTabComplete  && (
                  <td>
                    <button
                      id="closeButton"
                      className={`tabs-close-button ${tab["mark_tab_complete"] ? "tabs-complete-button-reverse" : "tabs-complete-button"}`}
                      onClick={(e) => this.handleClick(e)}
                    >
                      {`${tab["mark_tab_complete"] ? "COMPLETE" : "MARK COMPLETE"}`}
                    </button>
                  </td>
              )}
              <td>
                <h1 className="tabs-smaller-total total">
                  ${visibleTotal.toFixed(2)}
                </h1>
              </td>
              <td>
                <a
                  className="tabs-arrow"
                  href={"#" + tab.tab_number}
                  data-toggle="collapse"
                >
                  {isOpen ? (
                    <i className="fa fa-chevron-left"></i>
                  ) : (
                    <i className="fa fa-chevron-down"></i>
                  )}
                </a>
              </td>
            </tr>
          </tbody>
        </table>

        <Collapse in={isOpen}>
          <div>
            <table className="table tab-table">
              {tab.placed_orders.map((order, index) => {
                return (
                  <Order
                    {...this.props}
                    order={order}
                    key={index}
                    closed={tab.closed_at != null}
                    disablePrinter={disablePrinter}
                  />
                );
              })}
              <tbody>
                <tr>
                  <td>
                    <h1 className="tabs-time">
                      {moment(tab.created_at).from()}
                    </h1>
                  </td>
                  <td>
                    <h1 className="tabs-order">TAB OPENED</h1>
                  </td>
                </tr>
              </tbody>
              {!tab.closed_at ? (
                <tbody>
                  <tr>
                    <td colSpan={10}>
                      <button
                        className="tabs-close-button"
                        onClick={() => tabActions.closeTab(tab)}
                      >
                        CLOSE TAB
                      </button>
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    {!tab.refund_status ? (
                      <td colSpan={5}>
                        <div className="col-lg-8 refund left-inner-addon">
                          <i className="fa fa-usd"></i>
                          <form className="customer_search">
                            <input
                              type="text"
                              onChange={this.refundChanged.bind(this)}
                              value={this.state.refundAmount}
                              className="search-input"
                            />
                          </form>
                        </div>
                        <button
                          className="tabs-close-button"
                          onClick={this.refundClick.bind(this)}
                        >
                          REFUND TAB
                        </button>
                      </td>
                    ) : (
                      ""
                    )}
                  </tr>
                  <tr>
                    <td colSpan={5}>
                      <div className={"refund-error"}>
                        {this.state.refundError}
                      </div>
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        </Collapse>
      </div>
    );
  }
}
export default Tab;
