import { Component } from "react";
import MenuToggleButton from "./MenuToggleButton";

class MenusToggle extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <MenuToggleButton title={"Drinks"} search={"Drinks"} />
        <MenuToggleButton title={"Food"} search={"Food"} />
        <MenuToggleButton title={"Mods/Extras"} search={"Extras"} />
      </div>
    );
  }
}
export default MenusToggle;
