import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import actions from "../actions";
import ProductSelection from "./ProductSelection";

class UpdateProductSelectContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkoutSuccess: false,
      product: 1
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  render() {
    let { digitalMenus, clearOpen } = this.props;
    let { stripeInfo } = digitalMenus;
    let currentProduct = 1
    let monthly = true

    if (stripeInfo && stripeInfo.plan) {
      currentProduct = stripeInfo.plan.product
      monthly = stripeInfo.plan.monthly
    }

    return (
      <div className="schedule-modal item-modal select-update-product">
        <div className="row">
          <div className="column align-center">
            <ProductSelection 
              initialProduct={currentProduct}
              initialFrequency={monthly}
              checkoutSuccess={clearOpen}
              update={true}
              addVenue={false}
            />
            <p style={{margin: "25px 0", fontSize: "12px"}}>note: If upgrading your plan from standard to premium, or monthly to annual the final price will be prorated to reflect what you've already paid in your current billing period.</p>
          </div>
        </div>
      </div>
    )
  }
};


export default connect (
  function (state) {
    return {
      digitalMenus: state.digitalMenus,
    };
  },
  function (dispatch) {
    return {
      digitalMenuActions: bindActionCreators(actions.digitalMenus, dispatch)
    };
  }
)(UpdateProductSelectContainer);