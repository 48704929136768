import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import actions from "../actions";
import DigitalMenuPanel from "./DigitalMenuPanel";
import EditDigitalMenuModal from "./EditDigitalMenuModal";
import PromptUpgradeModal from "./PromptUpgradeModal";

class DigitalMenusContainer extends Component {
  constructor(props) {
    super(props);
    this.setMenuOpen = this.setMenuOpen.bind(this);
    this.clearMenuOpen = this.clearMenuOpen.bind(this);
    this.addMenu = this.addMenu.bind(this);
    this.resetOrder = this.resetOrder.bind(this);
    this.sortMenus = this.sortMenus.bind(this);
    this.promptModalToggle = this.promptModalToggle.bind(this);
    this.state = {
      menuViewId: null,
    }
  }

  promptModalToggle () {
    let { digitalMenuActions } = this.props;
    digitalMenuActions.togglePromptUpgradeModal("menus")
  }
  
  addMenu() {
    let { digitalMenuActions } = this.props;
    digitalMenuActions.toggleDigitalMenuModal();
  }

  resetOrder() {
    let { digitalMenuActions, digitalMenus } = this.props;
    const venueData = digitalMenus.data
    const venue = venueData["venue_data"]
    digitalMenuActions.resetMenuOrder(venue.id);
  }

  setMenuOpen(id) {
    let menuViewId = this.state.menuViewId;
    menuViewId = id;
    this.setState({ ...this.state, menuViewId });
  }

  clearMenuOpen() {
    let menuViewId = this.state.menuViewId;
    menuViewId = null;
    this.setState({ ...this.state, menuViewId });
  }

  sortMenus() {
    let { digitalMenus }= this.props;
    return digitalMenus.data.menus.sort((menuA, menuB) => {
      return menuA.position - menuB.position
    })
  }

  render() {
    let { digitalMenus, product }= this.props;
    const venueData = this.props.digitalMenus.data
    const orderAndPay  = venueData["order_and_pay"]

    let menus = [];
    const menuCheck = venueData !== undefined && digitalMenus.data.menus
    
    if (menuCheck) {
      menus = this.sortMenus()
    }

    const upgradeCheck = !orderAndPay && (menus.length > 0 && product === 1)

    return (
      menuCheck && (
      <div>
        <button 
        className="btn btn-primary add-menu-button" 
        onClick={upgradeCheck ? this.promptModalToggle : this.addMenu}
        >
          Add Menu
        </button>
        { menus.length > 0 && (
          <button 
            className="btn btn-primary add-menu-button" 
            onClick={this.resetOrder}
          >
            RESET ORDER
          </button>
        )}
        { menus.length > 0 && (
          <div className="panel panel-default dm-menu-container">
            { menus.map((menu, index) => {
              return <DigitalMenuPanel 
                        key={index} 
                        menu={menu} 
                        menuOpen={this.state.menuViewId} 
                        setMenuOpen={(id) => this.setMenuOpen(id)} 
                        clearMenuOpen={this.clearMenuOpen}
                        isPreviewOpen={this.state.menuViewId === menu.id}
                      />
            })}
          </div>
        )}
        { upgradeCheck ? (
          <PromptUpgradeModal />
          ) : (
          <EditDigitalMenuModal />
          )
        }
      </div>
      )
    ) 
  }

}

export default connect (
  function (state) {
    return {
      digitalMenus: state.digitalMenus
    };
  },
  function (dispatch) {
    return {
      digitalMenuActions: bindActionCreators(actions.digitalMenus, dispatch)
    };
  }
)(DigitalMenusContainer);