import {Component} from 'react'
import NumberFormat from 'react-number-format';
import {makePayment} from '../actions/promotion_codes.js'
import {connect} from 'react-redux'

class PromotionCodeReportRow extends Component {
  constructor(props) {
    super(props);
    if (this.refs.payment){
      this.setState({payment: 0});
    }
  }

  makePayment() {
    const amountToPay = this.state.payment;
    const venue = this.props.venue.name;
    this.props.dispatch(makePayment(venue, amountToPay));
  }

  render() {
    return <tr className="promotion-code-report-row">
      <td>{this.props.venue.name}</td>
      <td>{this.props.venue.redeemed}</td>
      <td>{'$' + parseFloat(this.props.venue.discounted).toFixed(2)}</td>
      <td>{'$' + parseFloat(this.props.venue.totalDue).toFixed(2)}</td>
      <td>
        <NumberFormat className="form-control"
          id="payment"
          ref="payment"
          value={this.state ? this.state.payment : ''}
          prefix={'$'} 
          decimalPrecision={2}
          onChange={(e, values) => {
            const {formattedValue, value} = values;
            this.setState({payment: value})
          }}
          />
      </td>
      <td><button disabled={true} type="submit" onClick={this.makePayment.bind(this)} className="btn btn-default payment-btn">Make Payment</button></td>
    </tr>
  }
}

function mapStateToProps(state){  
  return {};
}

const PromotionCodeReportRowContainer = connect(
  mapStateToProps
)(PromotionCodeReportRow)

export default PromotionCodeReportRowContainer
