/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require('jquery');
window.jQuery = $;
window.$ = $;

require.context('../images', true);
require('../stylesheets/application.scss');

require("turbolinks").start();
var ReactRailsUJS = require("react_ujs");
require("@rails/ujs").start();
require("moment");
require("eonasdan-bootstrap-datetimepicker");
require("chosen-js");
require("bootstrap");
require("bootstrap-editable");

require("../javascript/settings");
require("../javascript/menus");
require("../javascript/master");
require("../javascript/end-of-night");
require("../javascript/reporting");
require("../javascript/components");
require("../javascript/fast_pass");

var componentRequireContext = require.context("../javascript/components", true);
ReactRailsUJS.useContext(componentRequireContext);

