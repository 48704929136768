import {Component} from 'react';
import {Provider} from 'react-redux';
import {createStore, applyMiddleware, compose} from 'redux';
import rootReducer from '../reducers';
import thunk from 'redux-thunk';
import {loadVenues} from '../actions/promotion_codes.js'
import PromotionCodeReportContainer from './PromotionCodeReport.js';

const store = createStore(
  rootReducer,
  compose(applyMiddleware(thunk))
);

store.dispatch(loadVenues());

class PromotionCodeReportRoot extends Component {
  render() {
    return <Provider store={store}>
      <PromotionCodeReportContainer />
    </Provider>
  }
}

export default PromotionCodeReportRoot;
