import { Component } from "react";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "../reducers";
import actions from "../actions/digital_menus";
import thunk from "redux-thunk";
import VenueDigitalMenusContainer from "./VenueDigitalMenusContainer.js";
import NoVenuesContainer from "./NoVenuesContainer";

const store = createStore(rootReducer, compose(applyMiddleware(thunk)));

class DigitalMenusRoot extends Component {
  constructor(props) {
    super(props);
    store.dispatch(actions.loadDigitalMenus());
  }
  
  render() {
    return (
      <Provider store={store}>
        { this.props.hasVenue ? (
            <VenueDigitalMenusContainer />
        ) : (
            <NoVenuesContainer />
        )}
      </Provider>
    );
  }
}

export default DigitalMenusRoot;