import React, { Component } from "react";
import { Card } from "react-bootstrap";

class StandardCard extends Component {
  constructor(props) {
    super(props);
    this.onButtonClick = this.onButtonClick.bind(this);
  }

  onButtonClick() {
    this.props.selectProduct(2)
  }

  render() {
    let { currentProduct, isMonthly } = this.props;
    let active = currentProduct === 2

    return (
        <Card 
          onClick={this.onButtonClick}
          className={ active ? "selection-card-active" : "selection-card" }
        >
          <Card.Header className="card-header">
            <Card.Text className="bold">Standard Plan</Card.Text>
            <h3 className="price">${ isMonthly ? "24" : "264"}</h3>
            <Card.Text>{ isMonthly ? "per month" : "per year"}</Card.Text>
          </Card.Header>
          <Card.Body className="card-body">
            <Card.Text className="bold">- Everything in Basic</Card.Text>
            <Card.Text>- Unlimited Menus</Card.Text>
            <Card.Text>- Social Media Follow Links</Card.Text>
            <Card.Text>- No Menu Sponsors</Card.Text>
          </Card.Body>
        </Card>
    )
  }
};


export default StandardCard;