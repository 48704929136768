require("whatwg-fetch");
window.React = require("react");
window.ReactDOM = require("react-dom");
window.Root = require("./components/tabs/Root.js").default;
window.QrOrdersRoot = require("./components/qrorders/QrOrdersRoot.js").default;
window.DigitalMenusRoot =require("./components/digital_menus/DigitalMenusRoot").default;
window.toastr = require("toastr");

toastr.options.positionClass = "toast-top-center";

var originalFetch = window.fetch;
window.fetch = function (url, options = {}) {
  if (!options.headers) options.headers = {};
  options.credentials = "same-origin";
  options.headers["Accept"] = "application/json";
  options.headers["Content-Type"] = "application/json";
  return originalFetch(url, options);
};
