import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import actions from "../actions";
import BasicCard from "./BasicCard";
import PremiumCard from "./PremiumCard";
import StandardCard from "./StandardCard";
import ProductModal from "./ProductModal";
import BasicProductModal from "./BasicProductModal";
import UpdateProductModal from "./UpdateProductModal";

class ProductSelection extends Component {
  constructor(props) {
    super(props);
    this.selectProduct = this.selectProduct.bind(this);
    this.confirmProduct = this.confirmProduct.bind(this);
    this.modalToggle = this.modalToggle.bind(this);
    this.basicModalToggle = this.basicModalToggle.bind(this);
    this.updateModalToggle = this.updateModalToggle.bind(this);
    this.state = {
      currentProduct: 1,
      isMonthly: true,
      checkoutModal: false,
      basicModal: false,
      updateModal: false
    };
  }

  selectProduct(product) {
    this.setState({...this.state, currentProduct: product});
  }

  toggleMonthly() {
    let isMonthly = this.state.isMonthly;
    this.setState({...this.state, isMonthly: !isMonthly});
  }

  modalToggle () {
    let check = this.state.checkoutModal
    this.setState({ checkoutModal: !check })
  }

  basicModalToggle () {
    let check = this.state.basicModal
    this.setState({ basicModal: !check })
  }

  updateModalToggle () {
    let check = this.state.updateModal
    this.setState({ updateModal: !check })
  }

  confirmProduct() {
    if (this.props.update) {
      if (this.state.currentProduct === 1) {
        this.basicModalToggle()
      } else {
        this.updateModalToggle()
      }
    } else {
      if (this.state.currentProduct === 1) {
        this.basicModalToggle()
      } else {
        this.modalToggle()
      }
    }
  }

  componentDidMount() {
    if (this.props.initialProduct && this.props.initialFrequency) {
      this.setState({ ...this.state, currentProduct: this.props.initialProduct, isMonthly: this.props.initialFrequency});
    }
  }

  render() {
    let { currentProduct, isMonthly } = this.state;
    let { initialProduct, initialFrequency, update, addVenue, venueName } = this.props;
    let disableButton = !addVenue && update && (initialProduct === currentProduct && initialFrequency === isMonthly)
    let updateAction = ""

    if (addVenue) {
      updateAction = "add_venue"
    } else if (initialProduct < currentProduct) {
      updateAction = "upgrade_plan"
    } else if (initialProduct > currentProduct) {
      updateAction = "downgrade_plan"
    } else if (initialFrequency && !isMonthly) {
      updateAction = "upgrade_freq"
    } else if (!initialFrequency && isMonthly) {
      updateAction = "downgrade_freq"
    }

    return (
      <div className="product-selection">
        <div className="checkbox-container">
          <label>See annual plans: </label>
          <input
            type="checkbox"
            id="monthly"
            name="monthly"
            className="dm-checkbox-input"
            value={!isMonthly || "false"}
            checked={!isMonthly}
            onChange={() => this.toggleMonthly()}
          />
        </div>
        <div className="row">
          <div className="product-container">
            <BasicCard selectProduct={(product) => this.selectProduct(product)} currentProduct={currentProduct} isMonthly={isMonthly} />
            <StandardCard selectProduct={(product) => this.selectProduct(product)} currentProduct={currentProduct} isMonthly={isMonthly} />
            <PremiumCard selectProduct={(product) => this.selectProduct(product)} currentProduct={currentProduct} isMonthly={isMonthly} />
          </div>
        </div>
        <div className="schedule-modal-save" style={{margin: "20px 0 0 0"}}>
          <button 
            onClick={this.confirmProduct}
            disabled={disableButton}
          >Select Product</button>
        </div>
        <ProductModal 
          isModalOpen={this.state.checkoutModal} 
          modalToggle={this.modalToggle} 
          product={currentProduct}
          isMonthly={isMonthly}
        />
        <BasicProductModal 
          isModalOpen={this.state.basicModal} 
          modalToggle={this.basicModalToggle} 
          product={currentProduct}
          isMonthly={isMonthly}
          update={update}
          updateAction={updateAction}
          venueName={venueName}
        />
        <UpdateProductModal 
          isModalOpen={this.state.updateModal} 
          modalToggle={this.updateModalToggle} 
          product={currentProduct}
          isMonthly={isMonthly}
          updateAction={updateAction}
          venueName={venueName}
        />
      </div>
    )
  }
};

export default connect (
  function (state) {
    return {
      digitalMenus: state.digitalMenus,
    };
  },
  function (dispatch) {
    return {
      digitalMenuActions: bindActionCreators(actions.digitalMenus, dispatch)
    };
  }
)(ProductSelection);